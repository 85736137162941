import * as CSV from 'comma-separated-values'
import download from 'downloadjs'
import React from 'react'

import { useApp } from '../modules/api'
import { Export } from '../modules/icons/v5/export'
import { buttonStyles } from '../styles'
import Button from './Button'

type LocalExportToCsvButtonProps = {
  linkText?: string
  options: {
    header: string[]
    name: string
    values: CSV.Value[][]
  }
  type: 'local'
}

type RemoteExportToCsvButtonProps = {
  linkText?: string
  options: {
    filename: string
    query: string
    url: string
  }
  type: 'remote'
}

export default function ExportToCsvButton(
  props: LocalExportToCsvButtonProps | RemoteExportToCsvButtonProps
) {
  const { filename, query, url } =
    props.type === 'remote' ? props.options : { filename: name, query: '', url: '' }

  const [state, { refetch }] = useApp<void, string>(['csv', query], `${url}${query}`, {
    enabled: false,
    transform: (data) => {
      download(data, `${filename}.csv`, 'text/csv')
    },
  })

  return (
    <Button
      disabled={state.matches('loading')}
      handleClick={() => {
        switch (props.type) {
          case 'local': {
            const { header, name, values } = props.options

            const csv = CSV.encode(values, {
              header,
            })

            return download(csv, `${name}.csv`, 'text/csv')
          }
          case 'remote':
            return refetch()
        }
      }}
      tw={props.linkText ? '' : `${buttonStyles.tailWindBlackOutlineWithIcon} mx-4`}
    >
      {props.linkText ? (
        props.linkText
      ) : (
        <>
          <Export />
          {state.matches('loading') ? 'Exporting...' : 'Export to CSV'}
        </>
      )}
    </Button>
  )
}
