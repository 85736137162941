import * as React from 'react'

import SVG from './SVG'
import type { IconProps } from './types'

export const X = (props: IconProps) => (
  <SVG height="20" width="20" title="close" viewBox="0 0 20 20" {...props}>
    <path
      d="M15 5L5 15M5 5L15 15"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)
