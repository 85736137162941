import { TopXForYTableGridItem } from '@juristat/common/types'
import { css, cx } from 'emotion'
import * as fuzzysort from 'fuzzysort'
import { equals, isNil } from 'ramda'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import Button from '../../../../components/Button'
import ConditionalWrapper from '../../../../components/ConditionalWrapper'
import ExportToCsvButton from '../../../../components/ExportToCsvButton'
import PaginationBar from '../../../../components/PaginationBar'
import SearchTextInput from '../../../../components/SearchTextInput'
import { Select } from '../../../../components/Select'
import SortableTable from '../../../../components/SortableTable'
import { useEllipsis, useSortState } from '../../../../hooks'
import { colors, textStyles } from '../../../../styles'
import makeSortByDirection from '../../../../utils/makeSortByDirection'
import { useIsChartsLibrary } from '../../../dashboards/hooks'
import { Draggable, DraggableGridItem, GridItemProps } from '../../../grid-items'
import { useGridItems } from '../../../grid-items/hooks'
import { HttpContent, HttpStatus } from '../../../http/types'
import { DragHandle, Plus, Spinner } from '../../../icons'
import { useHasAccess } from '../../../session/hooks'
import { Access } from '../../../session/types'
import columns from '../../columns'
import { useEntityName } from '../../hooks'
import { Entity, EntityKeyMetrics, IntelligenceEntityType, KeyMetricsColumn } from '../../types'
import { getDefaultKeyMetricsBy } from '../../utils'
import getEntityLabel from '../../utils/getEntityLabel'
import { useComparison, useEntityTypeAndId } from '../ComparisonContext'
import TableSettingsMenu from './TableSettingsMenu'
import { useKeyMetrics } from './hooks'

type TopXForYTableProps = GridItemProps &
  Draggable & {
    config: TopXForYTableGridItem
  }

const styles = {
  actions: css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    position: 'relative',
  }),
  button: css({
    '&:hover': {
      backgroundColor: colors.paleGray,
    },
    borderRadius: 15,
    height: 30,
    marginLeft: -6,
    transition: 'background-color 300ms ease-in-out',
    width: 30,
  }),
  draggable: css({
    '&:hover': {
      '& h2 > svg': {
        fill: colors.charcoalGray6,
        opacity: 1,
      },
      '& table': {
        border: '1px solid transparent',
        boxShadow: '0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15)',
      },
    },
    '& table': {
      transition: 'border-color 300ms ease-in-out, box-shadow 300ms ease-in-out',
    },
  }),
  error: css(textStyles.torchRedNormal13, {
    '& + *': {
      marginLeft: 10,
    },
    textAlign: 'center',
  }),
  for: css({
    margin: '0 6px',
  }),
  handle: css({
    cursor: 'grab',
    fill: colors.charcoalGray2,
    marginLeft: 'auto',
    opacity: 0.3,
    transition: 'fill 300ms ease-in-out, opacity 300ms ease-in-out',
  }),
  heading: css(textStyles.darkBold16, {
    alignItems: 'center',
    display: 'flex',
  }),
  icon: css({
    fill: colors.silver2,
    height: 12,
    width: 12,
  }),
  info: css(textStyles.placeholderNormal13, {
    fontStyle: 'italic',
  }),
  link: css(textStyles.linkBlueBold13Underlined),
  paginationContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 0 0 0',
  }),
  printing: css({
    paddingTop: 10,
    width: '1095px !important',
  }),
  rowContainer: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }),
  select: css({
    margin: '0 10px',
    width: 185,
  }),
  spinner: css({
    stroke: colors.silver2,
    height: 12,
    marginLeft: 10,
    width: 12,
  }),
  subText: css(textStyles.silver3Normal13, {
    margin: '4px 0 0 0',
  }),
  table: 'mt-5',
}

const allEntities = [
  IntelligenceEntityType.ArtUnit,
  IntelligenceEntityType.AssigneeAtDisposition,
  IntelligenceEntityType.AttorneyAtDisposition,
  IntelligenceEntityType.CurrentAssignee,
  IntelligenceEntityType.FirmAtDisposition,
  IntelligenceEntityType.CurrentAttorney,
  IntelligenceEntityType.CurrentFirm,
  IntelligenceEntityType.Cpc,
  IntelligenceEntityType.TechCenter,
  IntelligenceEntityType.Uspc,
]

const pageSize = 5

const header = [
  'Name',
  'Pending',
  'Filed',
  'Disposed',
  'Allowance Rate',
  'Months to Disposition',
  'Average Office Actions',
]

const pluralize = (name: string) => {
  if (/(Assignee|Attorney|Firm)/.test(name)) {
    return name.replace(/(Assignee|Attorney|Firm)(.)?/, '$1s$2')
  }

  return `${name}s`
}

const maybeMapOrNa = <T extends string | number>(
  value: T | null,
  mapFunction: (val: T) => string | number
) => (isNil(value) ? 'N/A' : mapFunction(value!))

const formatLargeNumber = (value: number) => value.toLocaleString()

const formatSmallNumber = (value: number) => value.toFixed(1)

const formatNumber = (digits: number) => (value: number) => Number(value.toFixed(digits))

const getEntityId = ({ filterKey, id }: Entity) =>
  filterKey ? `${filterKey.id}-${id}` : String(id)

const TopXForYTable = ({ config, draggable = true, style, ...props }: TopXForYTableProps) => {
  const { entity, id } = useEntityTypeAndId()
  const canExportAnalytics = useHasAccess(Access.ExportAnalytics)
  const [comparisons, addComparison] = useComparison()
  const [current, key, machine, update] = useGridItems()
  const [text, setText] = useState('')
  const entityOptions = allEntities.map((entityType) => ({
    label: pluralize(getEntityLabel(entityType)),
    value: entityType,
  }))
  const { page } = config
  const by =
    entityOptions.find((option) => option.value === config.by)?.value ??
    getDefaultKeyMetricsBy(entity)
  const selected =
    config.selected?.entity === IntelligenceEntityType.SearchSet
      ? ({ ...config.selected, id } as Entity)
      : config.selected
  const setBy = useCallback(
    (payload: TopXForYTableGridItem['by']) => {
      update(
        current.map((item) => (item.id === config.id ? { ...config, by: payload, page: 1 } : item))
      )
    },
    [config.id, key, machine.value]
  )
  const setPage = useCallback(
    (payload: TopXForYTableGridItem['page']) => {
      update(current.map((item) => (item.id === config.id ? { ...config, page: payload } : item)))
    },
    [config.id, key, machine.value]
  )
  const setSelected = useCallback(
    (payload: TopXForYTableGridItem['selected']) => {
      update(
        current.map((item) =>
          item.id === config.id ? { ...config, page: 1, selected: payload } : item
        )
      )
    },
    [config.id, key, machine.value]
  )
  const [keyMetrics, retryKeyMetrics] = useKeyMetrics({
    by,
    entity: selected === null ? entity : selected.entity,
    id: selected === null ? id : selected.id,
    selected,
  })
  const [name] = useEntityName({ entity, id })
  const ellipsisRef = useEllipsis<HTMLDivElement>(name.context.data ?? '', { lines: 1 })
  const isChartsLibrary = useIsChartsLibrary()

  useEffect(() => {
    if (name.matches('success') && selected === null) {
      setSelected({ entity, id, name: name.context.data })
    }
  }, [name])

  useEffect(() => {
    if (name.matches('success') && comparisons.length === 0) {
      setSelected({ entity, id, name: name.context.data })
    }
  }, [comparisons, name.value])

  const options = [
    { entity, id, name: name.context.data ?? 'Unknown Entity' },
    ...comparisons.map(({ id, name, filterKey, ...data }) => ({
      entity: data.entity,
      filterKey,
      id,
      name: filterKey ? `${name} for ${filterKey.name}` : name,
    })),
  ]

  const { sortState, toggleSort } = useSortState<KeyMetricsColumn>()

  const { exportResults, ...tableProps } = useMemo(() => {
    if (keyMetrics.matches('failure')) {
      return {
        cellsByRow: [[]],
        rowRenderer: () => (
          <tr>
            <td colSpan={8}>
              <div className={styles.rowContainer}>
                <div className={styles.error}>An error has occurred!</div>
                {keyMetrics.matches({ failure: 'retrying' }) ? (
                  <div>
                    Retrying... <Spinner className={styles.spinner} />
                  </div>
                ) : (
                  <Button className={styles.link} onClick={retryKeyMetrics}>
                    Retry
                  </Button>
                )}
              </div>
            </td>
          </tr>
        ),
      }
    }

    if (!keyMetrics.matches('success')) {
      return { cellsByRow: [], fetching: 5 }
    }

    if (keyMetrics.context.data.length === 0) {
      return {
        cellsByRow: [[]],
        rowRenderer: () => (
          <tr>
            <td colSpan={8}>
              <div className={styles.rowContainer}>
                <div className={styles.info}>No results found!</div>
              </div>
            </td>
          </tr>
        ),
      }
    }

    const sort = makeSortByDirection<EntityKeyMetrics>(sortState.direction, [sortState.by ?? ''])
    const { data } = keyMetrics.context
    const results = text
      ? fuzzysort.go(text, data, { key: 'name' }).map((result) => result.obj)
      : data
    const exportResults = data.map(
      ({ allowanceRate, avgOas, disposed, filed, monthsToDisposition, name, pending }) => [
        name.toUpperCase(),
        maybeMapOrNa(pending, formatNumber(0)),
        maybeMapOrNa(filed, formatNumber(0)),
        maybeMapOrNa(disposed, formatNumber(0)),
        maybeMapOrNa(allowanceRate, (rate) => `${(rate * 100).toFixed(0)}%`),
        maybeMapOrNa(monthsToDisposition, formatNumber(1)),
        maybeMapOrNa(avgOas, formatNumber(1)),
      ]
    )

    return {
      cellsByRow: sort(results)
        .slice((page - 1) * pageSize, pageSize * page)
        .map((item) => {
          const { id, name, ...data } = item
          const { allowanceRate, avgOas, disposed, filed, monthsToDisposition, pending } = data
          const found = selected?.filterKey
            ? true
            : comparisons.find((item) =>
                String(item.id) === String(id) && item.entity === by && item.filterKey && selected
                  ? (item.filterKey.id === selected.id &&
                      item.filterKey.entity === selected.entity) ||
                    selected.entity === IntelligenceEntityType.SearchSet
                  : false
              )

          return [
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            found || id === null ? null : (
              <Button
                className={styles.button}
                handleClick={() => {
                  const filterKey = selected ?? undefined

                  addComparison({
                    entity: by,
                    filterKey,
                    id,
                    metrics: { data, type: HttpStatus.Success } as HttpContent<
                      Omit<EntityKeyMetrics, 'id' | 'name'>
                    >,
                    name,
                  })
                }}
                title="Add to charts"
              >
                <Plus className={styles.icon} title="" />
              </Button>
            ),
            <Link className={styles.link} title={name.toUpperCase()} to={`/${by}/${id}`}>
              {name.toUpperCase()}
            </Link>,
            maybeMapOrNa(pending, formatLargeNumber),
            maybeMapOrNa(filed, formatLargeNumber),
            maybeMapOrNa(disposed, formatLargeNumber),
            maybeMapOrNa(allowanceRate, (rate) => `${(rate * 100).toFixed(0)}%`),
            maybeMapOrNa(monthsToDisposition, formatSmallNumber),
            maybeMapOrNa(avgOas, formatSmallNumber),
          ]
        }),
      exportResults,
    }
  }, [by, comparisons, keyMetrics, page, selected, sortState, text])

  return (
    <ConditionalWrapper
      condition={draggable}
      wrapper={(children) => (
        <DraggableGridItem
          {...{ ...props, children, style: { columns: 1, height: 412, ...style } }}
        />
      )}
    >
      <div className={cx({ [styles.draggable]: draggable })}>
        <h2 className={styles.heading}>
          Top
          <Select
            className={styles.select}
            disabled={isChartsLibrary}
            handleSelectionChange={setBy}
            options={entityOptions}
            value={by}
          />
          for{' '}
          {comparisons.length > 0 ? (
            <Select
              className={styles.select}
              handleSelectionChange={(selection) => {
                const found = options.find((option) => getEntityId(option) === selection)

                if (found) {
                  setSelected(found)
                }
              }}
              options={options.map((option) => ({
                label: option.name,
                value: getEntityId(option),
              }))}
              value={selected ? getEntityId(selected) : ''}
            />
          ) : name.matches('success') ? (
            <div className={styles.for} ref={ellipsisRef} title={name.context.data} />
          ) : (
            'UNKNOWN'
          )}
          {!isChartsLibrary && (
            <div className={styles.actions}>
              <SearchTextInput
                handleOnTextChange={setText}
                placeholder={`Search ${getEntityLabel(by, { simple: true })}s`}
                text={text}
              />
              <TableSettingsMenu config={config} />
              {draggable && (
                <DragHandle className={cx(styles.handle, 'handle')} height={36} width={36} />
              )}
            </div>
          )}
        </h2>
        <p className={styles.subText}>
          Select {getEntityLabel(by, { simple: true, titleCase: false })}s associated with this{' '}
          {getEntityLabel(entity, { simple: true, titleCase: false })} to see their comparative
          performance.
        </p>
        <SortableTable
          {...tableProps}
          className={styles.table}
          columns={columns}
          handleHeaderClick={toggleSort}
          sortState={sortState}
        />
        {keyMetrics.matches('success') ? (
          <div className={styles.paginationContainer}>
            {canExportAnalytics && exportResults && (
              <ExportToCsvButton
                options={{
                  header,
                  name: [
                    'top',
                    ...pluralize(getEntityLabel(by)).split(' '),
                    'for',
                    ...(name.context.data ?? '').split(' '),
                  ]
                    .join('_')
                    .toLowerCase(),
                  values: exportResults,
                }}
                type="local"
              />
            )}
            <PaginationBar
              current={page}
              go={setPage}
              pageCount={Math.ceil(keyMetrics.context.data.length / pageSize)}
            />
          </div>
        ) : null}
      </div>
    </ConditionalWrapper>
  )
}

export default memo(TopXForYTable, equals)
