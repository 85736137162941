import { CSSObject } from 'create-emotion'
import { css } from 'emotion'

import { rebeccapurpleAlpha8 } from './colorsOld'
import makeTransition from './makeTransition'

/**
 * Makes the link styles.
 *
 * @param {number} fontSize Specify font-size in ems.
 *
 * @return {string}
 */
const makeLink = (styles: CSSObject = {}) =>
  css(
    {
      '&:hover': { color: '#1570EF' },
      '&:visited': { color: rebeccapurpleAlpha8 },
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 500,
      color: '#101828',
      textDecoration: 'underline',
      transition: makeTransition('color', 0.1),
    },
    styles
  )

export default makeLink
