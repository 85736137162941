import config from '@juristat/config'
import * as auth0 from 'auth0-js'

const auth0Config = {
  audience: config.auth0Audience,
  clientID: config.auth0ClientId,
  domain: config.auth0Domain,
  redirectUri: `${location.protocol}//${location.host}/return`,
  responseType: 'token',
  scope: [
    'CAN_ACCESS_CHARTS',
    'CAN_ACCESS_EXPERT_SEARCH',
    'CAN_ACCESS_PREMIUM_ANALYTICS',
    'CAN_ACCESS_TABLE',
    'CAN_ADD_GROUP_OWNERS(groupId)',
    'CAN_ADMINISTER_PPAIR_CUST_NO_WHITELIST(groupId)',
    'CAN_BUY_EXAMINER_REPORT(0)',
    'CAN_CREATE_GROUP_TOKENS',
    'CAN_DO_HUMAN_TASKS',
    'CAN_EDIT_TIMES',
    'CAN_EXPORT_EXPERT_SEARCH_RESULTS',
    'CAN_EXPORT_ANALYTICS',
    'CAN_HAVE_TURBOPATENT_REJECTION_LINK',
    'CAN_OAR_BUILD',
    'CAN_OAR_REVIEW',
    'CAN_OAR_TECH',
    'CAN_OVERRIDE_PRIMARY_ENTITY',
    'CAN_SEND_OARS',
    'CAN_SKIP_PLAN_SELECT',
    'CAN_UPLOAD_IDS',
    'CAN_USE_ALPHA_JURISTAT',
    'CAN_USE_API_CONSOLE',
    'CAN_USE_EPIPHANY',
    'CAN_USE_EXAMINER_AU_TC_FILTERS',
    'CAN_USE_FIRM_ASSIGNEE_FILTERS',
    'CAN_USE_FREE_HEALTH_DASHBOARD',
    'CAN_USE_JURISTAT',
    'CAN_USE_OA_RCE_COUNTS_FILTERS',
    'CAN_USE_PERSONAL',
    'CAN_USE_PLATFORM_SAVED_SEARCH_AND_HISTORY',
    'CAN_USE_PLATFORM_PREMIUM_SORTS',
    'CAN_USE_PLATFORM_SEARCH_SCOPES',
    'CAN_USE_PLATFORM_THREE_PANE_VIEW',
    'CAN_USE_PPAIR_UI',
    'CAN_USE_PROJECT_Z',
    'CAN_USE_REG_CUST_NUM_FILTERS',
    'CAN_USE_SAVED_SEARCH_AND_HISTORY',
    'CAN_USE_TOOLS',
    'CAN_VIEW_CONFIDENTIAL_DATA',
    'CAN_USE_REJECTION_BASIS_FILTER',
    'CAN_VIEW_ANALYST_METRICS',
    'CAN_VIEW_OAR_ASSIGNMENT_SHEET',
    'CAN_VIEW_OAR_BUILD_QUALITY',
    'CAN_VIEW_OAR_CLIENT_CONFIGURATION',
    'CAN_VIEW_OAR_CLIENT_USAGE_REPORT',
    'CAN_VIEW_OAR_REVIEW_QUALITY',
    'CAN_VIEW_OARS',
    'CAN_VIEW_ONEOTWO_REPORTS',
    'CAN_VIEW_IDS',
    'CAN_VIEW_IDS_ASSIGNMENT_SHEET',
    'CAN_VIEW_PLATFORM_PATENT_FAMILY_COMPONENT',
    'CAN_VIEW_PREMIUM_WORK',
    'CAN_VIEW_PRIVATE_OARS',
    'CAN_VIEW_REJECTION_RESPONSE',
    'CAN_VIEW_SELF_ANALYST_METRICS',
    'CAN_VIEW_STAFF_SETTINGS',
  ]
    .map((permission) => `legacy:${permission}`)
    .concat('use:juristat')
    .join(' '),
}

const auth0Client = new auth0.WebAuth(auth0Config)
const auth0Authorize = auth0Client.authorize.bind(auth0Client)
const auth0ChangePassword = auth0Client.changePassword.bind(auth0Client)
const auth0CheckSession = auth0Client.checkSession.bind(auth0Client)
const auth0Login = auth0Client.login.bind(auth0Client)
const auth0Logout = auth0Client.logout.bind(auth0Client)
const auth0PasswordlessStart = auth0Client.passwordlessStart.bind(auth0Client)

export {
  auth0Authorize,
  auth0ChangePassword,
  auth0CheckSession,
  auth0Client,
  auth0Config,
  auth0Login,
  auth0Logout,
  auth0PasswordlessStart,
}
