enum AfterOrBefore {
  After = 'after',
  Before = 'before',
}

export type ApiAccess =
  | 'oar-builder'
  | 'oar-manager'
  | 'oar-reviewer'
  | 'oar-sender'
  | 'oar-tech'
  | 'view-confidential-data'
  | 'view-private-oars'
  | 'view-self-analyst-metrics'

enum Feature {
  Charts = 'charts',
  Compare = 'compare',
  OfficeActions = 'officeActions',
  Other = 'other',
  PatentFamily = 'patentFamily',
  SavedSearches = 'savedSearches',
  Table = 'table',
  ThreePaneView = 'threePaneView',
}

enum Charts {
  AllowanceRatesByTechCenter = 'allowanceRatesByTechCenter',
  AllowanceRatesOverTime = 'allowanceRatesOverTime',
  ApplicationStatus = 'applicationStatus',
  AverageOfficeActionsOverTime = 'averageOfficeActionsOverTime',
  DaysToDispositionOverTime = 'daysToDispositionOverTime',
  DaysToFirstResponseOverTime = 'daysToFirstResponseOverTime',
  DependentClaimsOverTime = 'dependentClaimsOverTime',
  DispositionsByRejectionCount = 'dispositionsByRejectionCount',
  DivisionOfWork = 'divisionOfWork',
  Extensions = 'extensions',
  ExtensionsOverTime = 'extensionsOverTime',
  ExtensionsPercentage = 'extensionsPercentage',
  FilingsByTechCenter = 'filingsByTechCenter',
  FilingsByTechCenterOverTime = 'filingsByTechCenterOverTime',
  FilingsByTechCenterPercentage = 'filingsByTechCenterPercentage',
  FilingsOverTime = 'filingsOverTime',
  IndependentClaimsOverTime = 'independentClaimsOverTime',
  IssuedPatentsByTechCenterOverTime = 'issuedPatentsByTechCenterOverTime',
  IssuedPatentsOverTime = 'issuedPatentsOverTime',
  OfficeActionResponseWinRates = 'officeActionResponseWinRates',
  OfficeActionsByDispositionYear = 'officeActionsByDispositionYear',
  RejectionBasesOverTime = 'rejectionBasesOverTime',
  WordsPerDependentClaimOverTime = 'wordsPerDependentClaimsOverTime',
  WordsPerIndependentClaimOverTime = 'wordsPerIndependentClaimsOverTime',
}

enum Column {
  AlertToggle = 'ALERT_TOGGLE', // internal; not returned by API
  AllAttorneyIds = 'ALL_ATTORNEY_IDS',
  AllAttorneys = 'ALL_ATTORNEYS',
  Appeals = 'APPEALS',
  ApplicationNumber = 'APPNO',
  ArtUnit = 'ART_UNIT',
  ArtUnitAllowanceRate = 'ART_UNIT_ALLOWANCE_RATE',
  Assignee = 'CURRENT_ASSIGNEE',
  AssigneeAtDisposition = 'ASSIGNEE_AT_DISPOSITION',
  AssigneeAtPublishing = 'ASSIGNEE_AT_PUBLISHING',
  AssigneeId = 'CURRENT_ASSIGNEE_ID',
  AssistantExaminer = 'ASSISTANT_EXAMINER',
  Attorney = 'CURRENT_ATTORNEY',
  AttorneyAtDisposition = 'ATTORNEY_AT_DISPOSITION',
  AttorneyAtDispositionId = 'ATTORNEY_AT_DISPOSITION_ID',
  AttorneyDocketNumber = 'ATTORNEY_DOCKET_NUMBER',
  AttorneyId = 'CURRENT_ATTORNEY_ID',
  BackwardCitations = 'BACKWARD_CITATIONS',
  ClaimWordsAtIssuance = 'CLAIM_WORDS_AT_ISSUANCE',
  ClaimWordsLost = 'CLAIM_WORDS_LOST',
  ClaimWordsAtPublishing = 'CLAIM_WORDS_AT_PUBLISHING',
  ClaimsAtIssuance = 'CLAIMS_AT_ISSUANCE',
  ClaimsAtPublishing = 'CLAIMS_AT_PUBLISHING',
  ClaimsLost = 'CLAIMS_LOST',
  CurrentFirm = 'CURRENT_FIRM',
  IndependentClaimWordsAtIssuance = 'INDEPENDENT_CLAIM_WORDS_AT_ISSUANCE',
  IndependentClaimWordsAtPublishing = 'INDEPENDENT_CLAIM_WORDS_AT_PUBLISHING',
  IndependentClaimWordsLost = 'INDEPENDENT_CLAIM_WORDS_LOST',
  IndependentClaimsAtIssuance = 'INDEPENDENT_CLAIMS_AT_ISSUANCE',
  IndependentClaimsAtPublishing = 'INDEPENDENT_CLAIMS_AT_PUBLISHING',
  IndependentClaimsLost = 'INDEPENDENT_CLAIMS_LOST',
  CpcClass = 'CPC_CLASS',
  DispositionDate = 'DISPOSITION_DATE',
  Examiner = 'EXAMINER',
  ExaminerId = 'EXAMINER_ID',
  ExaminerAllowanceRate = 'EXAMINER_ALLOWANCE_RATE',
  ExaminerAllowanceRateAfterFourFinalRejections = 'EXAMINER_ALLOWANCE_RATE_AFTER_FOUR_FINAL_REJECTIONS',
  ExaminerAllowanceRateAfterFourNonFinalRejections = 'EXAMINER_ALLOWANCE_RATE_AFTER_FOUR_NON_FINAL_REJECTIONS',
  ExaminerAllowanceRateAfterFourOfficeActions = 'EXAMINER_ALLOWANCE_RATE_AFTER_FOUR_OFFICE_ACTIONS',
  ExaminerAllowanceRateAfterOneFinalRejection = 'EXAMINER_ALLOWANCE_RATE_AFTER_ONE_FINAL_REJECTION',
  ExaminerAllowanceRateAfterOneNonFinalRejection = 'EXAMINER_ALLOWANCE_RATE_AFTER_ONE_NON_FINAL_REJECTION',
  ExaminerAllowanceRateAfterOneOfficeAction = 'EXAMINER_ALLOWANCE_RATE_AFTER_ONE_OFFICE_ACTION',
  ExaminerAllowanceRateAfterThreeFinalRejections = 'EXAMINER_ALLOWANCE_RATE_AFTER_THREE_FINAL_REJECTIONS',
  ExaminerAllowanceRateAfterThreeNonFinalRejections = 'EXAMINER_ALLOWANCE_RATE_AFTER_THREE_NON_FINAL_REJECTIONS',
  ExaminerAllowanceRateAfterThreeOfficeActions = 'EXAMINER_ALLOWANCE_RATE_AFTER_THREE_OFFICE_ACTIONS',
  ExaminerAllowanceRateAfterTwoFinalRejections = 'EXAMINER_ALLOWANCE_RATE_AFTER_TWO_FINAL_REJECTIONS',
  ExaminerAllowanceRateAfterTwoNonFinalRejections = 'EXAMINER_ALLOWANCE_RATE_AFTER_TWO_NON_FINAL_REJECTIONS',
  ExaminerAllowanceRateAfterTwoOfficeActions = 'EXAMINER_ALLOWANCE_RATE_AFTER_TWO_OFFICE_ACTIONS',
  ExaminerAllowanceRateWithInterview = 'EXAMINER_ALLOWANCE_RATE_WITH_INTERVIEW',
  ExaminerAllowanceRateWithoutInterview = 'EXAMINER_ALLOWANCE_RATE_WITHOUT_INTERVIEW',
  ExaminerAmendmentWinRate = 'EXAMINER_AMENDMENT_WIN_RATE',
  ExaminerAppealNoticeOfAllowanceRate = 'EXAMINER_APPEAL_WIN_NOA_RATE',
  ExaminerAppealWinRate = 'EXAMINER_APPEAL_WIN_RATE',
  ExaminerAverageOfficeActionsToAllowance = 'EXAMINER_AVG_OAS_TO_ALLOWANCE',
  ExaminerCipAllowanceRate = 'EXAMINER_CIP_ALLOWANCE_RATE',
  ExaminerConAllowanceRate = 'EXAMINER_CON_ALLOWANCE_RATE',
  ExaminerInterviewWinRate = 'EXAMINER_INTERVIEW_WIN_RATE',
  ExaminerRceWinRate = 'EXAMINER_RCE_WIN_RATE',
  FilingDate = 'FILING_DATE',
  FinalRejections = 'FINAL_REJECTIONS',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Firm = 'CURRENT_FIRM',
  FirmAtDisposition = 'FIRM_AT_DISPOSITION',
  FirmAtPublishing = 'FIRM_AT_PUBLISHING',
  FirmId = 'CURRENT_FIRM_ID',
  FirstAssignee = 'FIRST_ASSIGNEE',
  FirstAssigneeId = 'FIRST_ASSIGNEE_ID',
  FirstAttorney = 'FIRST_ATTORNEY',
  FirstAttorneyId = 'FIRST_ATTORNEY_ID',
  FirstFirm = 'FIRST_FIRM',
  FirstFirmId = 'FIRST_FIRM_ID',
  FirstNamedInventor = 'FIRST_NAMED_INVENTOR',
  ForwardCitationCountsByApplicant = 'FORWARD_CITATION_COUNTS_BY_APPLICANT',
  GroupMetadata = 'GROUP_METADATA_COLUMN',
  MissedInterview = 'MISSED_INTERVIEW',
  MissedContinuation = 'MISSED_CONTINUATION',
  NonFinalRejections = 'NON_FINAL_REJECTIONS',
  OfficeActions = 'OFFICE_ACTIONS',
  PairStatus = 'PAIR_STATUS',
  PairStatusDate = 'PAIR_STATUS_DATE',
  PatentIssueDate = 'PATENT_ISSUE_DATE',
  PatentNumber = 'PATENT_NUMBER',
  Pendency = 'PENDENCY',
  PrimaryExaminer = 'PRIMARY_EXAMINER',
  PublicationDate = 'PUBLICATION_DATE',
  PublicationNumber = 'PUBLICATION_NUMBER',
  Rces = 'RCES',
  Status = 'STATUS',
  Title = 'TITLE',
  UnnecessaryAppeals = 'UNNECESSARY_APPEALS',
  UnnecessaryOfficeActions = 'UNNECESSARY_OFFICE_ACTIONS',
  UserMetadata = 'USER_METADATA_COLUMN',
  UspcSubclass = 'USPC_SUBCLASS',
  UspcClass = 'USPC_CLASS',
}

type ColumnGraphQLVariable = { column: string }

enum DataSource {
  PublicPair = 'publicPair',
  PrivatePair = 'privatePair',
}

enum Filter {
  AmendmentCount = 'amendmentCount',
  AppealCount = 'appealCount',
  ApplicationStatus = 'advancedApplicationStatus',
  ApplicationNumber = 'appno',
  ApplicationType = 'advancedAppType',
  ArtUnit = 'artUnit',
  AssigneeAtDisposition = 'assigneeAtDisposition',
  AssigneeAtDispositionName = 'assigneeAtDispositionName',
  AttorneyAtDisposition = 'attorneyAtDisposition',
  AttorneyDocketNumber = 'attorneyDocketNumber',
  CpcClass = 'cpcClass',
  CsvImport = 'userTag',
  CurrentAssignee = 'currentAssignee',
  CurrentAssigneeName = 'currentAssigneeName',
  CurrentAttorney = 'currentAttorney',
  CurrentFirm = 'currentFirm',
  CurrentFirmName = 'currentFirmName',
  CustomerNumber = 'customerNumber',
  DispositionDate = 'dispositionDate',
  DocCode = 'docCodeFilter',
  EntityStatus = 'entityStatus',
  Examiner = 'examiner',
  ExaminerAllowanceRate = 'examinerAllowanceRate',
  ExaminerInterviewWinRate = 'examinerInterviewWinRate',
  FiledUnpublished = 'filedUnpublished',
  FilingDate = 'filingDate',
  FinalRejectionsCount = 'finalRejectionsCount',
  FirmAtDisposition = 'firmAtDisposition',
  FirmAtDispositionName = 'firmAtDispositionName',
  InterviewCount = 'interviewCount',
  IssuanceDate = 'issuanceDate',
  MissedContinuation = 'missedContinuation',
  MissedInterview = 'missedInterview',
  NonFinalRejectionsCount = 'nonFinalRejectionsCount',
  OfficeActionsCount = 'officeActionCount',
  PatentNumber = 'patentNumber',
  PublicationDate = 'publicationDate',
  PublicationNumber = 'publicationNumber',
  RejectionBasis = 'hasRejectionType',
  RceCount = 'rceCount',
  RegistrationNumber = 'attorney',
  TechCenter = 'techCenter',
  UnnecessaryAppeals = 'unnecessaryAppeals',
  UnnecessaryOfficeActions = 'unnecessaryOfficeActions',
  UspcClass = 'uspcClass',
}

export enum IdsAssignmentColumn {
  Action = 'action',
  Appno = 'appno',
  AttorneyDocketNum = 'attorneyDocketNumber',
  Checkbox = 'checkbox',
  CitationTable = 'citationTable',
  Client = 'groupName',
  ClientMatterNum = 'clientMatterNumber',
  CompletedDate = 'completedDate',
  CustomerNum = 'customerNumber',
  DocCode = 'docCode',
  DocsParsed = 'docsParsed',
  DownloadDate = 'downloadDate',
  DownloadLink = 'downloadLink',
  DueDate = 'dueDate',
  FamilyTree = 'familyTree',
  FirstSentDate = 'firstSentDate',
  ForeignRefs = 'foreignRefs',
  IncludedIdss = 'includedIdss',
  LastSentDate = 'lastSentDate',
  Notes = 'notes',
  NplRefs = 'nplRefs',
  PrepTime = 'prepTime',
  Preparer = 'preparer',
  Progress = 'progress',
  ReceiptDate = 'receiptDate',
  ReferenceCount = 'referenceCount',
  ReferenceCutOffDate = 'referenceCutOffDate',
  ReferenceTable = 'referenceTable',
  Requester = 'requester',
  ReviewTime = 'reviewTime',
  ReviewedDate = 'reviewedDate',
  Reviewer = 'reviewer',
  SignAndFinish = 'signAndFinish',
  Source = 'source',
  Status = 'status',
  StartDate = 'startDate',
  SubmitDate = 'submitDate',
  TotalTime = 'totalTime',
  Trainee = 'trainee',
  Trigger = 'trigger',
  TotalPrepared = 'totalPrepared',
  TotalRefs = 'totalRefs',
}

export enum IdsAssignmentSource {
  ClientRequest = 'client-request',
  ManualUpload = 'manual-upload',
  UsOfficeActionDetected = 'us-office-action-detected',
}

enum IdsRequestType {
  UsPriorArtRejection = 'us-prior-art-rejection',
  NewUsFiling = 'new-us-filing',
  NewUsContinuationCipDivisional = 'new-us-continuation-cip-divisional',
  ForeignOfficeAction = 'foreign-office-action',
  ForeignPctPriorArtSearchReport = 'foreign-pct-prior-art-search-report',
  Other = 'other',
}

export enum IdsTriggerType {
  Audit = 'audit',
  ForeignOfficeAction = 'foreign-office-action',
  New = 'new',
  Other = 'other',
  SearchReport = 'search-report',
  UsOfficeAction = 'us-office-action',
}

enum IdsSource {
  Analyst = 'analyst',
  Etl = 'etl',
}

enum IdsStatus {
  Approved = 'approved',
  BuildingCitationSheet = 'workflow-building-citation-sheet',
  DocParsing = 'workflow-doc-parsing',
  DocsParsed = 'docs-parsed',
  Hidden = 'hidden',
  InProgress = 'in-progress',
  InReview = 'in-review',
  InWorkflow = 'in-workflow',
  NoIdsNeeded = 'no-ids-needed',
  NotStarted = 'not-started',
  NplClustering = 'workflow-npl-clustering',
  OnHold = 'on-hold',
  Pending = 'pending',
  PendingEtl = 'pending-etl',
  PendingWorkflow = 'pending-workflow',
  Sent = 'sent',
  Submitted = 'submitted',
  Triage = 'workflow-triage',
}

enum IdsPacketWorkflowStatusTypes {
  /** The packet is doing initialization */
  INITIALIZATION,
  /** The packet is waiting for triage */
  TRIAGE,
  /** The packet is waiting for doc parsing */
  DOC_PARSING,
  /** The packet is waiting for NPL clustering */
  NPL_CLUSTERING,
  /** The packet is building the citation sheet */
  BUILDING_CITATION_SHEET,
  /** The workflow is finished */
  DONE,
}

enum IdsTriggerEvent {
  ForeignOfficeAction = 'Foreign Office Action',
  IdsAudit = 'IDS Audit',
  NewApplication = 'New Application',
  SearchReport = 'Search Report',
  UsOfficeAction = 'US Office Action',
}

enum IdsCitationTableColumn {
  appNo = 'App No.',
  section = 'Section',
  countryCode = 'Country Code',
  hash = '#',
  kindCode = 'Kind Code',
  patPubNum = 'Pat./Pub.#/ NPL Description',
  patPubDate = 'Pat./Pub. Date',
  patenteeApplicant = 'Patentee/Applicant',
  pagesColumnsLines = 'Pages/Columns/Lines',
  status = 'Status',
  t5 = 'T5',
  appNo1 = 'AppNo 1',
  appNo2 = 'AppNo 2',
  appNo3 = 'AppNo 3',
  appNo4 = 'AppNo 4',
  appNo5 = 'AppNo 5',
  appNo6 = 'AppNo 6',
  appNo7 = 'AppNo 7',
  appNo8 = 'AppNo 8',
  appNo9 = 'AppNo 9',
  appNo10 = 'AppNo 10',
}

enum IdsReferenceTableColumn {
  appNo = 'App No.',
  section = 'Section',
  countryCode = 'Country Code',
  exclusionBasis = 'Exclusion Basis',
  hash = '#',
  kindCode = 'Kind Code',
  notes = 'Notes',
  patPubNum = 'Pat./Pub.#/ NPL Description',
  patPubDate = 'Pat./Pub. Date',
  patenteeApplicant = 'Patentee/Applicant',
  pagesColumnsLines = 'Pages/Columns/Lines',
  source = 'Source',
  status = 'Status',
  t5 = 'T5',
  appNo1 = 'AppNo 1',
  appNo2 = 'AppNo 2',
  appNo3 = 'AppNo 3',
  appNo4 = 'AppNo 4',
  appNo5 = 'AppNo 5',
  appNo6 = 'AppNo 6',
  appNo7 = 'AppNo 7',
  appNo8 = 'AppNo 8',
  appNo9 = 'AppNo 9',
  appNo10 = 'AppNo 10',
}

enum ExpertColumn {
  AiAReviewNo = 'trialNumber',
  ApplicationNumber = 'applicationNumber',
  ArtUnit = 'artUnit',
  CpcClass = 'cpcClass',
  ExpertSide = 'side',
  EstHourlyRate = 'rate',
  FilingDate = 'filingDate',
  InstitutionDate = 'decisionDate',
  Owner = 'owner',
  PatentNumber = 'patentNumber',
  Petitioner = 'petitioner',
  TechCenter = 'techCenter',
  Declaration = 'declaration',
  ExpertCv = 'cv',
  PetitionerFirm = 'petitionerFirm',
  PetitionerAttorney = 'petitionerAttorney',
  OwnerFirm = 'ownerFirm',
  OwnerAttorney = 'ownerAttorney',
}

enum ExpertSearchColumn {
  Document = 'document',
  DocumentDate = 'documentDate',
  EstHourlyRate = 'rate',
  ExpertName = 'name',
  PtabTrialNumber = 'ptabTrialNumber',
  Relevance = 'relevance',
}

enum ExpertSearchExpertFilterColumn {
  EstHourlyRate = 'rate',
  ExpertName = 'name',
  NumHits = 'num_hits',
  PetitionerAppearances = 'petitionerApperances',
  RespondentAppearances = 'respondentAppearances',
}

enum PatentColumn {
  ExpertCv = 'expert-cv',
  ExpertCost = 'expert-cost',
  ExpertDeclaration = 'expert-declaration',
  ExpertName = 'expert-name',
  Experience = 'experience',
  PetitionerAppearances = 'petitionerAppearances',
  RespondentAppearances = 'respondentAppearances',
}

export enum OarAnalystAnalyticsColumn {
  AnalystName = 'analystName',
  AvgBuildRating = 'avgBuildRating',
  AvgHoursBilledPerDaysWorked = 'avgHoursBilledPerDaysWorked',
  AvgOarsBuiltPerDaysWorked = 'avgOarsBuiltPerDaysWorked',
  AvgOarsBuiltPerHoursWorked = 'avgOarsBuiltPerHoursWorked',
  AvgOarsReviewedPerDaysWorked = 'avgOarsReviewedPerDaysWorked',
  AvgOarsReviewedPerHoursWorked = 'avgOarsReviewedPerHoursWorked',
  AvgSecondReviewsPerDaysWorked = 'avgSecondReviewsPerDaysWorked',
  AvgReviewRating = 'avgReviewRating',
  AvgTimePerOarsBuilt = 'avgTimePerOarsBuilt',
  AvgTimePerOarsReviewed = 'avgTimePerOarsReviewed',
  AvgTraineeRating = 'avgTraineeRating',
  BuildPercentage = 'buildPercentage',
  DaysWorked = 'daysWorked',
  ExpectedOarsBuiltPerDaysWorked = 'expectedBuiltPerDaysWorked',
  ExpectedReviewedPerDaysWorked = 'expectedReviewedPerDaysWorked',
  HoursBilled = 'hoursBilled',
  HoursWorkedPerWeek = 'hoursWorkedPerWeek',
  ReviewPercentage = 'reviewPercentage',
  TotalOarsBuilt = 'totalOarsBuilt',
  TotalOarsReviewed = 'totalOarsReviewed',
  TotalPercentage = 'totalPercentage',
  TotalSecondReviews = 'totalSecondReviews',
}

enum OarAssignmentColumn {
  Action = 'action',
  Appno = 'appno',
  AttorneyDocketNum = 'attorneyDocketNumber',
  Analyst = 'analyst',
  BuildRating = 'buildRating',
  Checkbox = 'checkbox',
  Client = 'groupName',
  ClientStatus = 'clientStatus',
  CompletedDate = 'completedDate',
  CustomerNum = 'customerNumber',
  DocCode = 'docCode',
  DownloadDate = 'downloadDate',
  DownloadLink = 'downloadLink',
  DueDate = 'dueDate',
  FirstSentDate = 'firstSentDate',
  LastSentDate = 'lastSentDate',
  MailroomDate = 'mailroomDate',
  NotificationDate = 'notificationDate',
  Notes = 'notes',
  PrepTime = 'prepTime',
  PublicationStatus = 'published',
  ReportIssue = 'reportIssue',
  ReviewerQuestion = 'reviewerQuestion',
  ReviewRating = 'reviewRating',
  ReviewTime = 'reviewTime',
  ReviewedDate = 'reviewedDate',
  Reviewer = 'reviewer',
  SecondReviewTime = 'secondReviewTime',
  SecondReviewedDate = 'secondReviewedDate',
  SecondReviewer = 'secondReviewer',
  Source = 'source',
  Status = 'status',
  StartDate = 'startDate',
  SubmitDate = 'submitDate',
  TotalTime = 'totalTime',
  Trainee = 'trainee',
  TraineeRating = 'traineeRating',
}

enum OarDocumentType {
  ClientLetter = 'client-letter',
  ExParteQuayle = 'cteq',
  FinalRejection = 'ctfr',
  FirstActionInterview = 'oa.fai',
  NonFinalRejection = 'ctnf',
  PreinterviewFirstOfficeAction = 'oa.fai.prelm',
  RequirementForRestriction = 'ctrs',
}

enum OarEmailType {
  DownloadLink = 'download-link',
  Imanage = 'imanage',
  ImanageIntegration = 'imanage-integration',
  ImanageNotification = 'imanage-notification',
  KtsImanage = 'kts-imanage',
}

enum OarStatus {
  Approved = 'approved',
  Hidden = 'hidden', // NB: this is more than a display preference, it means the OAR is excluded from the contract
  InProgress = 'in-progress',
  InReview = 'in-review',
  InSecondReview = 'in-second-review',
  NotStarted = 'not-started',
  PendingEtl = 'pending-etl',
  Sampling = 'sampling',
  SecondReview = 'second-review',
  Sent = 'sent',
  Submitted = 'submitted',
  Training = 'training',
}

enum OarReportType {
  ArtUnit = 'art-unit',
  Examiner = 'examiner',
}

enum IntercomAttribute {
  AddEntityClicks = 'v4_add-entity',
  AlertsConfigurationRules = 'v4_num-alerts-rules',
  AlertsConfigurationActiveRules = 'v4_num-alerts-active-rules',
  AlertsConfigurationInactiveRules = 'v4_num-alerts-inactive-rules',
  AlertsConfigurationViews = 'v4_num-alerts-config-views',
  ApplicationViews = 'v4_application-view',
  CreateDashboardClicks = 'v4_num-custom-dashboards',
  DashboardViews = 'v4_num-dashboard-views',
  EditChartsClicks = 'v4_num-edit-charts-clicks',
  EditColumnsClicks = 'v4_num-edit-columns-clicks',
  ExaminerViews = 'v4_num-examiner-views',
  FilterAppliedClicks = 'v4_num-filters-applied',
  IntelligenceViews = 'v4_num-intelligence-views',
  LastAlertsConfigurationView = 'v4_last-alerts-config-view',
  LastApplicationView = 'v4_last-application-view',
  LastDashboardView = 'v4_last-dashboard-view',
  LastExaminerView = 'v4_last-examiner-view',
  LastIntelligenceView = 'v4_last-intelligence-view',
  LastPpairView = 'v4_last-private-PAIR-view',
  LastTableView = 'v4_last-table-view',
  PpairApplicationViews = 'v4_num-private-PAIR-application-views',
  PpairViews = 'v4_num-private-PAIR-views',
  TableViewExportClicks = 'v4_num-table-view-exports',
  TableViews = 'v4_num-table-views',
  SaveSearchClicks = 'v4_save-search',
}

enum Key {
  Backspace = 8,
  Comma = 188,
  Enter = 13,
  Escape = 27,
  Period = 190,
  Semicolon = 186,
  Space = 32,
  Tab = 9,
}

enum LocalStorageKey {
  ChartExtensionsOverTimeShowPercentage = 'juristat-chartExtensionsOverTimeShowPercentage',
  ChartFilingsByTechCenterShowPercentage = 'juristat-chartFilingsByTechCenterShowPercentage',
  ChartSelectedItemsState = 'juristat-chartsSelectedItemsState',
  Destination = 'juristat-destination',
  ExampleSavedSearches = 'juristat-exampleSavedSearches',
  NavDrawerState = 'juristat-navDrawerState',
  PaginationPageSizeState = 'juristat-paginationPageSizeState',
  Username = 'juristat-userEmailAddress',
  TableSelectedColumnsState = 'juristat-tableSelectedColumnsState',
}

enum OrderDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

enum GridItemType {
  Chart = 'chart',
  ExaminerMetric = 'examiner-metric',
  ProsecutionMetric = 'prosecution-metric',
  RoiMetric = 'roi-metric',
  TopXForYTable = 'top-x-for-y-table',
}

type IdAndName = {
  id: string | number
  name: string
}

type Entity = IdAndName & {
  entity: IntelligenceEntityType
  filterKey?: Entity
}

enum IntelligenceEntityType {
  ArtUnit = 'artunit',
  AssigneeAtDisposition = 'assignee-at-disp',
  AttorneyAtDisposition = 'attorney-at-disp',
  Cpc = 'cpc',
  CurrentAssignee = 'current-assignee',
  CurrentAttorney = 'current-attorney',
  CurrentFirm = 'current-firm',
  Examiner = 'examiner',
  FirmAtDisposition = 'firm-at-disp',
  SearchSet = 'search-set',
  TechCenter = 'techcenter',
  Uspc = 'uspc',
  Uspto = 'uspto',
}

enum ExaminerMetric {
  AllowanceRate = 'allowanceRate',
  AllowanceRateByDispositionYear = 'allowanceRateByDispositionYear',
  AppealBrief = 'appealBrief',
  AppealsAndOutcomes = 'appealsAndOutcomes',
  AppealsDonuts = 'appealsDonuts',
  AppealsOverview = 'appealsOverview',
  AverageOfficeActions = 'averageOfficeActions',
  Continuation = 'continuation',
  ContinuationInPart = 'continuationInPart',
  Details = 'details',
  DisposedApps = 'disposedApps',
  DispositionsByRejectionCount = 'dispositionsByRejectionCount',
  EndLoadingRates = 'endLoadingRates',
  InterviewAllowanceRate = 'interviewAllowanceRate',
  KeyMetrics = 'keyMetrics',
  OfficeActionResponseWinRates = 'officeActionResponseWinRates',
  OfficeActionsAndResponses = 'officeActionsAndResponse',
  PreAppealBrief = 'preAppealBrief',
  Ptab = 'ptab',
}

enum RoiMetric {
  MissedContinuation = 'missedContinuation',
  MissedInterview = 'missedInterview',
  UnnecessaryAppeals = 'unnecessaryAppeals',
  UnnecessaryOfficeActions = 'unnecessaryOfficeActions',
}

enum ProsecutionMetric {
  BackwardCitations = 'backwardCitations',
  ClientCategorization = 'clientCategorization',
  ClientOpportunities = 'clientOpportunities',
  ExaminerAllowanceRates = 'examinerAllowanceRates',
  ForwardCitations = 'forwardCitations',
  FourOasAndZeroInterviews = 'fourOasAndZeroInterviews',
  FourOfficeActions = 'fourOfficeActions',
  KeyMetrics = 'keyMetrics',
  NextSteps = 'nextSteps',
  NormalizedAllowanceRate = 'normalizedAllowanceRate',
  NormalizedOfficeActions = 'normalizedOfficeActions',
  PendencyProfile = 'pendencyProfile',
  PendingApplicationDistribution = 'pendingApplicationDistribution',
  PendingWithThreeRces = 'pendingWithThreeRces',
  ProsecutionEffectiveness = 'prosecutionEffectiveness',
  ProsecutionHealthInfo1 = 'prosecutionHealthInfo1',
  ProsecutionHealthInfo2 = 'prosecutionHealthInfo2',
  ProsecutionHealthInfo3 = 'prosecutionHealthInfo3',
  ProsecutionHealthOverall = 'prosecutionHealthOverall',
  ProsecutionVolume = 'prosecutionVolume',
  ProsecutionVolumeAndDistribution = 'prosecutionVolumeAndDistribution',
}

type ChartGridItem = {
  chart: Charts
  id?: string
  type: GridItemType.Chart
}

type ExaminerMetricGridItem = {
  id?: string
  metric: ExaminerMetric
  type: GridItemType.ExaminerMetric
}

type ProsecutionMetricGridItem = {
  id?: string
  metric: ProsecutionMetric
  type: GridItemType.ProsecutionMetric
}

type RoiMetricGridItem = {
  id?: string
  metric: RoiMetric
  type: GridItemType.RoiMetric
}

type TopXForYTableGridItem = {
  by: IntelligenceEntityType
  id: string
  page: number
  selected: Entity | null
  type: GridItemType.TopXForYTable
}

type GridItem =
  | ChartGridItem
  | ExaminerMetricGridItem
  | ProsecutionMetricGridItem
  | RoiMetricGridItem
  | TopXForYTableGridItem

type RadioOption<T> = {
  disabled?: boolean
  label: string
  value: T
}

type FromApplication<T> = { data: T; type: SearchStatus.FromApplication }
type MissingPermission = {
  missingPermission: Feature[]
  type: SearchStatus.MissingPermission
}

enum SearchStatus {
  FromApplication = 'fromApplication',
  MissingPermission = 'missingPermission',
}

enum SearchScope {
  Abstract = 'abstract',
  AllFields = 'allFields',
  Appeals = 'appeals',
  Claims = 'claims',
  Description = 'description',
  FullText = 'fullText',
  Petitions = 'petitions',
  Rejections = 'rejections',
  Title = 'title',
}

enum SearchView {
  AboveExaminerAverage = 'dashboards/above-examiner-average',
  BackwardCitations = 'dashboards/backward-citations',
  Card = 'card',
  ExaminerAllowanceRate = 'dashboards/examiner-allowance-rate',
  ForwardCitations = 'dashboards/forward-citations',
  Intelligence = 'intelligence',
  IntelligenceApplications = 'intelligence-applications',
  MyAlerts = 'my-alerts',
  OfficeActions = 'dashboards/office-actions',
  OfficeActionsNoInterviews = 'dashboards/office-actions-no-interviews',
  PendingFor = 'dashboards/pending-for',
  PendingWithRces = 'dashboards/pending-with-rces',
  Private = 'private',
  Table = 'table',
}

type SortOrder = {
  direction: OrderDirection | null
  field: string | null
}

type TableColumn<TKey = undefined> = {
  key?: TKey
  label: string
  sortable?: boolean
}

enum TaskColumn {
  Action = 'action',
  Analyst = 'assignee',
  AppNumber = 'appNum',
  AttorneyDocketNumber = 'attorneyDocketNum',
  Client = 'client',
  DataCategory = 'dataCategory',
  DateCompleted = 'finishTime',
  DateStarted = 'startTime',
  DueDate = 'dateDue',
  Id = 'id',
  Priority = 'priority',
  Status = 'status',
  TaskType = 'taskType',
}

enum OmnisearchType {
  Application = 'APPLICATION',
  ArtUnit = 'ART_UNIT',
  Attorney = 'ATTORNEY',
  Company = 'COMPANY',
  Cpc = 'CPC',
  Examiner = 'EXAMINER',
  Firm = 'FIRM',
  Patent = 'PATENT',
  PatentExpert = 'PATENT_EXPERT',
  Expert = 'EXPERT',
  TechCenter = 'TECH_CENTER',
  Uspc = 'USPC',
}

enum ScraperFilterKey {
  AttorneyDocketNumber = 'attorney-docket-number',
  ApplicationNumber = 'application-number',
  DesignApplications = 'design-applications',
  DocumentCode = 'document-code',
}

enum ScraperFilterList {
  Blacklist = 'blacklist',
  Whitelist = 'whitelist',
}

enum ScraperFilterType {
  Ids = 'ids',
  Noa = 'noa',
  Oar = 'oar',
  Platform = 'platform',
}

export type DateTransform<T, K extends keyof T> = Omit<T, K> & {
  [Key in K]: Date | Exclude<T[K], string>
}

export {
  AfterOrBefore,
  Charts,
  Column,
  Column as SearchColumn,
  ColumnGraphQLVariable,
  DataSource,
  Entity,
  ExaminerMetric,
  ExaminerMetricGridItem,
  ExpertColumn,
  ExpertSearchColumn,
  ExpertSearchExpertFilterColumn,
  Feature,
  Filter,
  FromApplication,
  GridItem,
  GridItemType,
  IdsCitationTableColumn,
  IdsPacketWorkflowStatusTypes,
  IdsReferenceTableColumn,
  IdsRequestType,
  IdsSource,
  IdsStatus,
  IdsTriggerEvent,
  IntelligenceEntityType,
  IntercomAttribute,
  Key,
  LocalStorageKey,
  MissingPermission,
  OarAssignmentColumn,
  OarDocumentType,
  OarEmailType,
  OarReportType,
  OarStatus,
  OmnisearchType,
  OrderDirection,
  PatentColumn,
  ProsecutionMetric,
  ProsecutionMetricGridItem,
  RadioOption,
  RoiMetric,
  RoiMetricGridItem,
  ScraperFilterKey,
  ScraperFilterList,
  ScraperFilterType,
  SearchScope,
  SearchStatus,
  SearchView,
  SortOrder,
  TableColumn,
  TaskColumn,
  TopXForYTableGridItem,
}
