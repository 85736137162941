import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const FileGroup = (props: IconProps) => (
  <SVG version="1.1" title="file-group" fill="currentColor" {...props}>
    <path d="M31,34H13a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H31a1,1,0,0,1,1,1V33A1,1,0,0,1,31,34ZM14,32H30V12H14Z" />
    <rect x="16" y="16" width="12" height="2" />
    <rect x="16" y="20" width="12" height="2" />
    <rect x="16" y="24" width="12" height="2" />
    <path d="M6,24V4H24V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V25a1,1,0,0,0,1,1H6Z" />
    <path d="M10,28V8H28V7a1,1,0,0,0-1-1H9A1,1,0,0,0,8,7V29a1,1,0,0,0,1,1h1Z" />
  </SVG>
)

export default FileGroup
