import * as React from 'react'

import { cn } from '../../../utils/cn'
import { Access } from '../../session/types'
import AttributeValue from './AttributeValue'

export type AttributeProps = {
  className?: string
  canAccess?: Access
  label: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  to?: string
  value: number | string | null
}

const Attribute = ({
  canAccess,
  className,
  label,
  onMouseEnter,
  onMouseLeave,
  to,
  value,
}: AttributeProps) => (
  <div
    className={cn('flex pr-3', className)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <span className="flex w-full justify-between whitespace-nowrap">
      <span className="uppercase">{label}</span>
      <AttributeValue {...{ canAccess, to, value }} />
    </span>
  </div>
)

export default Attribute
