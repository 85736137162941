import React from 'react'

import { useEllipsis } from '../hooks'

type DataContainerTitleProps = {
  children?: React.ReactNode
  className?: string
  title: React.ReactNode
}

const styles = {
  container: 'items-center flex justify-between',
  header: 'font-inter text-gray-900 text-[18px] font-semibold',
}

export default function DataContainerTitle({
  children,
  className,
  title,
}: DataContainerTitleProps) {
  const titleIsString = typeof title === 'string'
  const ellipsisRef = useEllipsis<HTMLHeadingElement>(titleIsString ? (title as string) : '')

  return (
    <div className={`${styles.container} ${className ?? ''}`}>
      {titleIsString ? (
        <h2 className={styles.header} ref={ellipsisRef} />
      ) : (
        <h2 className={styles.header}>{title}</h2>
      )}
      {children}
    </div>
  )
}
