import React from 'react'
import { animated, useSpring } from 'react-spring'

import { useResizeObserver } from '../hooks'
import { Lock } from '../modules/icons'
import { twTextStyles } from '../styles'
import { cn } from '../utils/cn'
import Chevron, { Direction } from './Chevron'

type AccordionProps = {
  children?: React.ReactNode
  className?: string
  contentClassName?: string
  handleClick: () => void
  headerClassName?: string
  icon?: React.ReactNode
  label: React.ReactNode
  locked?: boolean
  maxHeight?: number
  onlyRenderWhenOpen?: boolean
  open?: boolean
  openClassName?: string
  renderContent?: () => JSX.Element | null
  renderStatus?: () => JSX.Element
}

const styles = {
  header: `${twTextStyles.paleGray2Bold12} items-center cursor-pointer flex justify-between uppercase`,
  rightContainer: '[&_svg]:fill-pale-gray2 items-center flex',
  slideOutContainer: 'overflow-y-hidden relative',
}

const Accordion = ({
  children,
  className,
  contentClassName,
  handleClick,
  headerClassName,
  icon,
  label,
  locked = false,
  maxHeight,
  onlyRenderWhenOpen = false,
  open = false,
  renderContent = () => null,
  renderStatus,
}: AccordionProps) => {
  const {
    ref,
    dimensions: { height },
  } = useResizeObserver<HTMLDivElement>()
  const props = useSpring({
    maxHeight: open ? maxHeight ?? height : 0,
  })

  return (
    <div className={className}>
      <h2 className={cn(styles.header, headerClassName)} onClick={handleClick}>
        {label}
        <div className={styles.rightContainer}>
          {renderStatus?.()}
          {!locked ? (
            icon ?? (
              <Chevron
                direction={open ? Direction.Up : Direction.Down}
                title={`${open ? 'Close' : 'Open'} filter`}
              />
            )
          ) : (
            <Lock height={12} width={12} />
          )}
        </div>
      </h2>
      <animated.div className={styles.slideOutContainer} style={props}>
        {(!onlyRenderWhenOpen || open) && (
          <div ref={ref}>
            <div className={contentClassName}>{children ?? renderContent()}</div>
          </div>
        )}
      </animated.div>
    </div>
  )
}

export default Accordion
