import { DataSource, SearchView } from '@juristat/common/types'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

import Accordion from '../../../components/Accordion'
import Button from '../../../components/Button'
import ConditionalWrapper from '../../../components/ConditionalWrapper'
import Menu from '../../../components/Menu'
import { cn } from '../../../utils/cn'
import { Angle, JuristatLogo, Spy } from '../../icons'
import { Bell } from '../../icons/v5/bell'
import { CollapseMenu } from '../../icons/v5/collapse-menu'
import { ExpandMenu } from '../../icons/v5/expand-menu'
import { FileCheck } from '../../icons/v5/file-check'
import { Home } from '../../icons/v5/home'
import { JuristatLogoSmall } from '../../icons/v5/juristat-logo-small'
import { LifeBuoy } from '../../icons/v5/life-buoy'
import { LineChart } from '../../icons/v5/line-chart'
import { Search } from '../../icons/v5/search'
import { Settings } from '../../icons/v5/settings'
import { IconProps } from '../../icons/v5/types'
import { QuickSearch } from '../../omnisearch/components/QuickSearch'
import { useSearchDataSource, useSetReport } from '../../search/hooks'
import { useHasAccess, useIsPpairUser } from '../../session/hooks'
import { makeGetAccesses } from '../../session/selectors'
import { Access } from '../../session/types'
import Tooltip from './Tooltip'

type AccordionState = 'analytics' | 'wfa' | null
type Common = {
  children: React.ReactNode
  expanded: boolean
  icon: (props: IconProps) => JSX.Element
  tooltip?: string
}

function Icon({
  className,
  icon,
  tooltip,
}: Pick<Common, 'icon' | 'tooltip'> & { className?: string }) {
  return (
    <ConditionalWrapper
      condition={Boolean(tooltip)}
      wrapper={(children) => (
        <Tooltip
          direction="right"
          mode="dark"
          tooltip={tooltip}
          width={(tooltip?.length ?? 0) > 20 ? 300 : undefined}
        >
          {children}
        </Tooltip>
      )}
    >
      <div className={cn('p-2.5 transition-all duration-200 ease-in-out', className)}>
        {icon({
          'data-testid': `tooltip-${tooltip}`,
          height: 24,
          title: '',
          width: 24,
        })}
      </div>
    </ConditionalWrapper>
  )
}

function Link({ children, expanded, icon, match, tooltip }: Common & { match: string }) {
  return (
    <NavLink
      className={cn(
        'hover:bg-brand-900 flex items-center overflow-hidden transition-colors duration-200',
        expanded ? 'rounded-md' : 'rounded-lg'
      )}
      activeClassName="bg-brand-800 text-white"
      to={match}
    >
      <Icon icon={icon} tooltip={expanded ? undefined : tooltip} />
      <div
        className={cn(
          'whitespace-nowrap text-sm font-semibold transition-all duration-200',
          expanded ? 'opacity-100' : 'invisible opacity-0'
        )}
      >
        {children}
      </div>
    </NavLink>
  )
}

function Item({
  active,
  children,
  expanded,
  icon,
  onClick,
  tooltip,
}: Common & { active?: boolean; onClick: () => void }) {
  return (
    <Button
      tw={cn(
        'hover:bg-brand-900 w-full flex items-center overflow-hidden transition-colors duration-200',
        expanded ? 'rounded-md' : 'rounded-lg',
        active ? 'bg-brand-800 text-white' : ''
      )}
      onClick={onClick}
    >
      <Icon
        className="[&>svg]:text-brand-100"
        icon={icon}
        tooltip={expanded ? undefined : tooltip}
      />
      <span
        className={cn(
          'whitespace-nowrap text-sm font-semibold transition-all duration-200',
          expanded ? 'opacity-100' : 'invisible opacity-0'
        )}
      >
        {children}
      </span>
    </Button>
  )
}

function Analytics({
  accordion,
  expanded,
  setAccordion,
}: {
  accordion: AccordionState
  expanded: boolean
  setAccordion: React.Dispatch<React.SetStateAction<AccordionState>>
}) {
  const open = accordion === 'analytics'
  const { pathname } = useLocation()
  const options = [
    {
      label: 'Quick Search',
      value: 'quick-search',
    },
    {
      label: 'Explore',
      value: '/explore',
    },
    {
      label: 'Drafting',
      value: '/drafting',
    },
    {
      label: '102 Reports',
      value: '/102-forward-cite',
    },
    {
      label: 'Alerts',
      value: '/search/alerts',
    },
  ]

  const active = options.map((item) => item.value).includes(pathname)

  const title = (
    <div
      className={cn(
        'text-brand-100 flex w-full items-center text-base capitalize transition-colors duration-200',
        expanded ? 'rounded-md font-semibold' : 'hover:bg-brand-900 rounded-lg',
        active ? 'bg-brand-800 text-white' : ''
      )}
    >
      <Icon
        className={expanded ? 'p-0 pr-2.5' : undefined}
        icon={LineChart}
        tooltip={expanded ? undefined : 'Analytics'}
      />
      <div
        className={cn(
          'tracking-0 whitespace-nowrap text-sm font-semibold transition-all duration-200',
          expanded ? 'opacity-100' : 'hidden opacity-0'
        )}
      >
        Analytics
      </div>
    </div>
  )

  if (expanded) {
    return (
      <Accordion
        className={cn(
          'rounded-md px-3 py-[0.5625rem] transition-colors duration-200',
          active ? 'bg-brand-800' : 'hover:bg-brand-900'
        )}
        headerClassName={cn('transition-[padding] duration-200', open ? 'pb-3' : 'py-px')}
        contentClassName="space-y-2 rounded-md"
        handleClick={() => setAccordion(open ? null : 'analytics')}
        icon={
          <Angle
            className={cn('size-4 transition-transform duration-200', open ? 'rotate-180' : '')}
          />
        }
        label={title}
        open={open}
      >
        <div className="pb-1.5">
          <QuickSearch />
        </div>
        {options.slice(1).map(({ label, value }) => (
          <NavLink className={cn('text-brand-100 flex items-center gap-2')} to={value}>
            <div
              className={cn(
                'h-2 w-2 rounded-full transition-colors duration-200',
                pathname === value ? 'bg-brand-100' : 'bg-transparent'
              )}
            />
            <div>{label}</div>
          </NavLink>
        ))}
      </Accordion>
    )
  }

  return (
    <Menu
      align="left"
      buttonCss="w-full"
      dropdownCss="overflow-visible [&_.section]:text-gray-700 [&_.section]:text-sm [&_.section]:font-semibold"
      handleClick={() => {}}
      heightAdjustment={70}
      nested
      optionCss="py-2 px-3 text-gray-900 text-sm font-semibold"
      options={{ Analytics: options }}
      title={title}
    >
      {({ className, label, value }) => {
        if (value === 'quick-search') {
          return (
            <div className={cn(className, 'hover:!bg-white')}>
              <QuickSearch />
            </div>
          )
        }

        return (
          <NavLink className={cn(className, 'flex items-center gap-2')} to={value}>
            <div
              className={cn(
                'h-2 w-2 rounded-full transition-colors duration-200',
                pathname === value ? 'bg-brand-600' : 'bg-transparent'
              )}
            />
            <div>{label}</div>
          </NavLink>
        )
      }}
    </Menu>
  )
}

function TogglePrivateData({ expanded }: Pick<Common, 'expanded'>) {
  const dataSource = useSearchDataSource()
  const setReport = useSetReport()
  const access = useIsPpairUser()

  const active = dataSource === DataSource.PrivatePair

  if (!access) {
    return null
  }

  return (
    <Item
      active={active}
      expanded={expanded}
      icon={Spy}
      onClick={() =>
        setReport(
          {
            dataSource: active ? DataSource.PublicPair : DataSource.PrivatePair,
            view: SearchView.Private,
          },
          { clearQueryString: true }
        )
      }
      tooltip="Toggle Private Data"
    >
      Toggle Private Data
    </Item>
  )
}

function Wfa({
  accordion,
  expanded,
  setAccordion,
}: {
  accordion: AccordionState
  expanded: boolean
  setAccordion: React.Dispatch<React.SetStateAction<AccordionState>>
}) {
  const open = accordion === 'wfa'
  const [
    canViewClientConfiguration,
    canViewClientUsageReports,
    canViewIdsManager,
    canViewOarManager,
    canViewOarAssignmentSheet,
    canViewIdsAssignmentSheet,
    canViewAnalystMetrics,
    canViewSelfAnalystMetrics,
    canViewHumanTasks,
  ] = useSelector(
    makeGetAccesses([
      Access.ClientConfig,
      Access.ClientUsageReport,
      Access.IdsViewer,
      Access.OarViewer,
      Access.AssignmentSheet,
      Access.IdsAssignmentSheet,
      Access.AnalystMetrics,
      Access.SelfAnalystMetrics,
      Access.HumanTasks,
    ])
  )
  const { pathname } = useLocation()

  const canViewAnalystAnalytics =
    canViewAnalystMetrics || canViewSelfAnalystMetrics || canViewOarAssignmentSheet

  const views = {
    '/analyst-tasks': canViewHumanTasks,
    '/ids': canViewIdsAssignmentSheet,
    '/ids/download': canViewIdsAssignmentSheet,
    '/ids/reports': canViewIdsAssignmentSheet,
    '/ids/triggering-events': canViewIdsAssignmentSheet,
    '/ids/upload': canViewIdsAssignmentSheet,
    '/ids/citation-table': canViewIdsAssignmentSheet || canViewIdsManager,
    '/ids/reference-sheet': canViewIdsAssignmentSheet || canViewIdsManager,
    '/ids/manager': canViewIdsManager,
    '/ids/recipients': canViewClientConfiguration,
    '/ids/trials': canViewClientConfiguration,
    '/oars': canViewOarAssignmentSheet,
    '/oars/client-usage': canViewClientUsageReports,
    '/oars/client-configuration': canViewClientConfiguration,
    '/oars/dashboard': canViewOarAssignmentSheet,
    '/oars/manager': canViewOarManager,
    '/oars/analyst-analytics': canViewAnalystAnalytics,
    '/oars/attorney-assignments': canViewClientConfiguration,
    '/oars/priorities': canViewClientConfiguration,
    '/oars/recipients': canViewClientConfiguration,
    '/oars/templates': canViewClientConfiguration,
    '/oars/trials': canViewClientConfiguration,
  }

  const options = {
    Tasks: [{ label: 'All Tasks', value: '/analyst-tasks' }].filter((item) => views[item.value]),
    OARs: [
      {
        label: 'Manager',
        value: '/oars/manager',
      },
      {
        label: 'Assignment Sheet',
        value: '/oars',
      },
      {
        label: 'Analyst Analytics',
        value: '/oars/analyst-analytics',
      },
      {
        label: 'Management Dashboard',
        value: '/oars/dashboard',
      },
      {
        label: 'Client Usage Reports',
        value: '/oars/client-usage',
      },
    ].filter((item) => views[item.value]),
    'OAR Client Configuration': [
      {
        label: 'Attorney Assignments',
        value: '/oars/attorney-assignments',
      },
      {
        label: 'Priorities',
        value: '/oars/priorities',
      },
      {
        label: 'Recipients',
        value: '/oars/recipients',
      },
      {
        label: 'Templates',
        value: '/oars/templates',
      },
      {
        label: 'Trials',
        value: '/oars/trials',
      },
    ].filter((item) => views[item.value]),
    IDSs: [
      {
        label: 'Manager',
        value: '/ids/manager',
      },
      {
        label: 'Assignment Sheet',
        value: '/ids',
      },
      {
        label: 'Download',
        value: '/ids/download',
      },
      {
        label: 'Upload',
        value: '/ids/upload',
      },
      {
        label: 'Triggering events',
        value: '/ids/triggering-events',
      },
      {
        disabled: true,
        label: 'Reports and Analytics',
        value: '/ids/reports',
      },
    ].filter((item) => views[item.value]),
    'IDS Client Configuration': [
      {
        label: 'Recipients',
        value: '/ids/recipients',
      },
      {
        label: 'Trials',
        value: '/ids/trials',
      },
    ].filter((item) => views[item.value]),
  }

  const active = Object.values(options)
    .flat()
    .map((item) => item.value)
    .includes(pathname)

  const title = (
    <div
      className={cn(
        'text-brand-100 flex w-full items-center text-base capitalize transition-colors duration-200',
        expanded ? 'rounded-md font-semibold' : 'hover:bg-brand-900 rounded-lg',
        active ? 'bg-brand-800 text-white' : ''
      )}
    >
      <Icon
        className={expanded ? 'p-0 pr-2.5' : undefined}
        icon={FileCheck}
        tooltip={expanded ? undefined : 'Workflow Automation'}
      />
      <div
        className={cn(
          'tracking-0 whitespace-nowrap text-sm font-semibold transition-all duration-200',
          expanded ? 'opacity-100' : 'hidden opacity-0'
        )}
      >
        Workflow Automation
      </div>
    </div>
  )

  const access = useHasAccess([Access.HumanTasks, Access.IdsViewer, Access.WorkflowAutomation])

  if (!access) {
    return null
  }

  if (expanded) {
    return (
      <Accordion
        className={cn(
          'rounded-md p-3 transition-colors duration-200',
          active ? 'bg-brand-800' : 'hover:bg-brand-900'
        )}
        headerClassName={cn('transition-[padding] duration-200', open ? 'pb-3' : 'py-px')}
        contentClassName="space-y-2 rounded-md"
        handleClick={() => setAccordion(open ? null : 'wfa')}
        icon={
          <Angle
            className={cn('size-4 transition-transform duration-200', open ? 'rotate-180' : '')}
          />
        }
        label={title}
        open={open}
      >
        {Object.entries(options).map(([section, items]) => (
          <React.Fragment key={section}>
            <div className="pt-2 font-semibold text-white">{section}</div>
            {items.map(({ label, value }) => (
              <NavLink
                key={value}
                className={cn('text-brand-100 flex items-center gap-2')}
                to={value}
              >
                <div
                  className={cn(
                    'h-2 w-2 rounded-full transition-colors duration-200',
                    pathname === value ? 'bg-brand-100' : 'bg-transparent'
                  )}
                />
                <div>{label}</div>
              </NavLink>
            ))}
          </React.Fragment>
        ))}
      </Accordion>
    )
  }

  return (
    <Menu
      align="left"
      buttonCss="w-full"
      dropdownCss="overflow-visible [&_.section]:text-gray-700 [&_.section]:text-sm [&_.section]:font-semibold"
      handleClick={() => {}}
      heightAdjustment={70}
      nested
      optionCss="py-2 px-3 text-gray-900 text-sm font-semibold"
      options={options}
      title={title}
    >
      {({ className, label, value }) => (
        <NavLink className={cn(className, 'flex items-center gap-2')} to={value}>
          <div
            className={cn(
              'h-2 w-2 rounded-full transition-colors duration-200',
              pathname === value ? 'bg-brand-600' : 'bg-transparent'
            )}
          />
          <div>{label}</div>
        </NavLink>
      )}
    </Menu>
  )
}

export function AppSidebar() {
  const [expanded, setExpanded] = React.useState(false)
  const [accordion, setAccordion] = React.useState<AccordionState>(null)

  return (
    <div
      className={cn(
        'bg-brand-950 text-brand-100 z-[100] flex h-svh flex-col space-y-6 pb-4 pt-5 transition-[width] duration-200',
        expanded ? 'w-[280px] overflow-y-scroll' : 'w-20'
      )}
    >
      <NavLink
        className={cn(
          'flex items-center gap-3 transition-[padding] duration-200',
          expanded ? 'px-6' : 'px-[1.125rem]'
        )}
        to="/"
      >
        <JuristatLogoSmall className="shrink-0 rounded-full" title="Home" />
        <JuristatLogo
          className={cn('transition-opacity duration-200', expanded ? 'opacity-100' : 'opacity-0')}
          color="#d1e9ff"
          color2="#98A2B3"
          height={40}
          width={150}
        />
      </NavLink>
      <div
        className={cn(
          'flex-1 space-y-2 transition-[padding] duration-200',
          expanded ? 'px-4' : 'px-4 pr-[1.25rem]'
        )}
      >
        <Link expanded={expanded} icon={Home} match="/home" tooltip="Home">
          Home
        </Link>
        <Analytics {...{ accordion, expanded, setAccordion }} />
        <Link
          expanded={expanded}
          icon={Search}
          match="/keyword-search"
          tooltip="The Keyword Search feature allows users to quickly find relevant information across multiple datasets or documents. This tool is designed to save time by filtering through large volumes of content to locate specific keywords or phrases."
        >
          Keyword Search
        </Link>
        <TogglePrivateData expanded={expanded} />
        <Wfa {...{ accordion, expanded, setAccordion }} />
      </div>
      <div
        className={cn(
          'space-y-2 transition-[padding] duration-200',
          expanded ? 'px-4' : 'px-4 pr-[1.25rem]'
        )}
      >
        <Link expanded={expanded} icon={Bell} match="/alerts-configuration" tooltip="Notifications">
          Notifications
        </Link>
        <Item
          expanded={expanded}
          icon={LifeBuoy}
          onClick={() => window.open('https://help.juristat.com')}
          tooltip="Help"
        >
          Support
        </Item>
        <Link expanded={expanded} icon={Settings} match="/settings" tooltip="Settings">
          Settings
        </Link>
      </div>
      <div className={cn('border-t-brand-900 border-t px-4 pt-4', { 'pr-[1.25rem]': !expanded })}>
        <Item
          expanded={expanded}
          icon={expanded ? CollapseMenu : ExpandMenu}
          onClick={() => setExpanded(!expanded)}
          tooltip="Expand Menu"
        >
          Collapse Menu
        </Item>
      </div>
    </div>
  )
}
