import { Theme } from '@nivo/core'

import { colors } from '../../../styles'

const chartTheme: Theme = {
  axis: {
    legend: {
      text: {
        fill: colors.charcoalGray2,
        fontSize: 10,
      },
    },
  },
  grid: {
    line: {
      stroke: colors.cloudyBlueAlpha50,
      strokeDasharray: '4',
      strokeWidth: 1,
    },
  },
  labels: {
    text: {
      fill: colors.charcoalGray2,
    },
  },
  legends: {
    text: {
      fill: colors.charcoalGray2,
      fontFamily: 'Inter',
      fontSize: 12,
    },
  },
}

export default chartTheme
