import React from 'react'

import DataContainerTitle from './DataContainerTitle'

type DataContainerProps = {
  children?: React.ReactNode
  className?: string
  headerContent?: React.ReactNode
  title: React.ReactNode
  tw?: boolean
}

const DataContainer: React.FC<DataContainerProps> = ({
  children,
  className,
  headerContent,
  title,
}) => {
  return (
    <div
      className={`[&_*]:!font-inter flex flex-col rounded-xl border border-gray-200 px-[30px] py-5 ${
        className ?? ''
      }`}
    >
      <DataContainerTitle children={headerContent} title={title} />
      {children}
    </div>
  )
}

export { DataContainerProps }
export default DataContainer
