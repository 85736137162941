import React from 'react'

import { useTitle } from '../hooks'
import LayoutContainer from './LayoutContainer'
import PageContainer from './PageContainer'

export default function TermsOfSale() {
  const lastUpdated = 'January 30, 2024'

  useTitle('Terms of Sale')

  return (
    <PageContainer>
      <LayoutContainer>
        <div className="text-gray-600">
          <h1 className="font-inter mb-6 mt-10 h-11 text-4xl font-semibold">Terms of Sale</h1>
          <p>Last Updated: {lastUpdated}</p>
          <p className="uppercase">
            THESE TERMS OF SALE BETWEEN YOU AND Juristat, Inc. GOVERN THE SALE AND PURCHASE OF all
            subscriptions to JURISTAT'S PRODUCTS, SOFTWARE, SERVICES, AND WEBSITE (COLLECTIVELY, THE
            "SERVICES"), including any renewals. These Terms constitute a binding and enforceable
            legal agreement between you and Juristat. IT IS IMPORTANT THAT YOU READ AND UNDERSTAND
            THESE TERMS. BY CLICKING THE "PURCHASE" BUTTON (OR A SIMILAR BUTTON) PRESENTED TO YOU AT
            THE TIME OF PURCHASE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE
            BOUND BY THESE TERMS.
          </p>
          <p>
            If you have entered into a separate written agreement with Juristat for the sale and
            purchase of a subscription to the Services, different terms may apply. Refer to your
            separate written agreement or contact a Juristat sales representative to discuss your
            agreement.
          </p>
          <p className="text-2xl">1. Authority.</p>
          <p>
            You represent and warrant that you are at least 18 years of age and have the legal
            capacity and authority to accept this Agreement. If you are accepting this Agreement on
            behalf of an organization or other entity, you represent and warrant that you have the
            legal capacity and authority to bind that organization or entity to this Agreement. All
            terms in this Agreement will apply to the organization or entity on whose behalf you are
            accepting this Agreement.
          </p>
          <p className="text-2xl">2. Services.</p>
          <p>
            Subject to the terms of this Agreement, you may access and use the Services to the
            extent permitted herein and in the subscription package you select at the time of
            purchase. Non-unlimited users may spend one report credit to access one report within
            Juristat's Report Services. Non-unlimited users will have unlimited access including
            continual data updates to reports they have purchased for the duration of their Juristat
            subscription term, including renewals of the subscription if renewed for an equal or
            higher tier subscription package. If the subscription is renewed for an equal or higher
            subscription tier, unused report credits will roll over into subsequent subscription
            term, otherwise they will be forfeit. Notwithstanding the foregoing, your access and use
            of the Services, including but not limited to all Report Services, will immediately
            cease upon termination of your subscription if your subscription is not renewed for an
            equal or higher tier subscription package or upon termination of this Agreement.
            Services may also be temporarily suspended or discontinued at any time for
            modifications, updates, improvements, or to address security concerns. If Juristat
            permanently discontinues a significant part of the Services in its entirety, you will
            have the option to cancel your subscription, but you will not receive a refund of any
            amounts already paid. Your subscription package may not include access to all available
            Services or new Services that become available during your subscription.
          </p>
          <p className="text-2xl">3. Modifications.</p>
          <p>
            Juristat may modify these terms or any additional terms that apply to the the Services
            at any time to, for example, reflect changes to the law or the Services. You should
            check these terms regularly. Juristat will post modifications to these terms on this
            page. By continuing to use or access the Services after the revisions come into effect,
            you agree to be bound by the revised terms.
          </p>
          <p className="text-2xl">4. Fees.</p>
          <p>
            By purchasing a subscription package from Juristat, you commit to paying for a full year
            of service. You will be charged the amount stated at the time of purchase and then you
            will be charged that amount again every month for the duration of your annual
            subscription. Fees are non-refundable except as required by law. You are responsible for
            providing complete and accurate billing and contact information to Juristat. A credit
            card must be kept on file at all times, and maybe be charged if your account becomes
            delinquent. Juristat may suspend or terminate your account or your access to the
            Services if fees become past due.
          </p>
          <p className="text-2xl">5. Taxes.</p>
          <p>
            You are responsible for all applicable taxes, if any. Juristat will charge tax when
            required to do so. If you are required by law to withhold any taxes, you must provide
            Juristat with an official tax receipt or other appropriate documentation.
          </p>
          <p className="text-2xl">6. Term.</p>
          <p>
            This Agreement and your annual subscription begin as soon as your initial payment is
            processed and will continue for one year unless renewed or terminated as set forth
            herein.
          </p>
          <p className="text-2xl">7. Automatic Renewal.</p>
          <p className="uppercase">
            YOUR SUBSCRIPTION WILL RENEW AUTOMATICALLY AT THE END OF YOUR ANNUAL TERM. RENEWAL FEES,
            TERMS, AND RATES ARE SUBJECT TO CHANGE, BUT JURISTAT WILL ALWAYS NOTIFY YOU OF ANY SUCH
            CHANGES BEFOREHAND.
          </p>
          <p className="text-2xl">8. Termination.</p>
          <p>
            You may stop using the Services at any time, but doing so does not relieve you of any
            obligation to pay any outstanding fees. You are still responsible for the full amount of
            your annual subscription. Juristat may terminate this Agreement at any time if: (i) you
            materially breach this Agreement, (ii) you or your account administrator fails to pay
            applicable fees owed to Juristat, (iii) you or your account administrator requests
            termination, or (iv) either party ceases its business operations or becomes subject to
            insolvency proceedings.
          </p>
          <p />
          <p className="text-2xl">9. DISCLAIMER OF WARRANTIES.</p>
          <p className="uppercase">
            JURISTAT PROVIDES THE SERVICES "AS IS" AND “AS AVAILABLE” WITHOUT ANY WARRANTY OF ANY
            KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO: WARRANTIES OF PERFORMANCE,
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, OMISSIONS, AND/OR
            COMPLETENESS; WARRANTIES THAT ANY PRODUCT, OR ACCESS THERETO, WILL BE UNINTERRUPTED,
            SECURE, COMPLETE, OR ERROR FREE; WARRANTIES AS TO THE LIFE OF ANY URL OR THIRD-PARTY WEB
            SERVICE; AND/OR WARRANTIES WITH REGARD TO ANY CONTENT OR SOFTWARE THAT HAS BEEN MODIFIED
            IN ANY WAY BY ANYONE OTHER THAN JURISTAT WITHOUT THE EXPRESS APPROVAL OF JURISTAT. YOU
            ACKNOWLEDGE THAT PROVISION OF CONTENT AND SOFTWARE ENTAILS THE LIKELIHOOD OF SOME HUMAN
            AND MACHINE ERRORS, DELAYS, INTERRUPTIONS, AND LOSSES, INCLUDING THE INADVERTENT LOSS OF
            DATA OR DAMAGE TO MEDIA; AND THE SERVICES REFERENCED HEREIN MAY NOT SATISFY REQUIREMENTS
            NOT EXPRESSLY STATED IN THIS AGREEMENT.
          </p>
          <p className="text-2xl">10. LIMITATION OF LIABILITY.</p>
          <p className="uppercase">
            {' '}
            IN NO EVENT WILL JURISTAT or its officers, directors, employees, affiliates, agents,
            licensors, or suppliers BE LIABLE, IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE,
            FOR ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, INDIRECT, INCIDENTAL,
            SPECIAL, PUNITIVE, OR CONSEQUENTIAL, DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION
            WITH USE OF THE SERVICES, WHETHER OR NOT JURISTAT HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGE; ANY CLAIM(S) RELATING IN ANY WAY TO YOUR INABILITY OR FAILURE TO PERFORM
            RESEARCH OR OTHER WORK, WHETHER PARTIALLY OR COMPLETELY, EVEN IF ASSISTED BY JURISTAT;
            OR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE UPON THE AVAILABILITY OF OR CONTENT
            MADE AVAILABLE THROUGH THE SERVICES.
          </p>
          <p className="text-2xl">11. INDEMNIFICATION.</p>
          <p className="uppercase">
            You agree to indemnify, defend, and hold harmless juristat and its officers, directors,
            employees, affiliates, agents, licensors, and suppliers from and against all claims,
            losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting
            from any violation of thIS AGREEMENT OR YOUR USE OF THE SERVICES.
          </p>
          <p className="text-2xl">12. No Legal Advice.</p>
          <p>
            You understand and agree that Juristat is not providing legal services, legal advice, or
            law-related services. No attorney-client relationship is formed by these Terms of Use or
            the Services. The accuracy, completeness, adequacy, or currency of the Services is not
            warranted or guaranteed. You use the Services at your own risk and based on your own
            judgment.
          </p>
          <p className="text-2xl">13. Publicity.</p>
          <p>
            Juristat may use your name and/or logo(s) to identify you as a customer of Juristat on
            its website and in promotional materials. Juristat may also verbally reference you as a
            Juristat customer.{' '}
          </p>
          <p className="text-2xl">14. Registration and Account Access.</p>
          <p>
            Certain sections of the Services may require you to register as a user or create an
            account. You agree to provide accurate and complete information upon registration and to
            inform Juristat of any changes to that information. You agree that the Services may be
            used and accessed only by the registered user for the registered user's own intents and
            purposes. The registered user may not use or access the Services for, or on behalf of,
            any other person or entity. You are responsible for all use of your account and the
            Services accessed via your account, including but not limited to: (i) maintaining the
            confidentiality of your password and account; (ii) managing access to, and use of, your
            account; and (iii) ensuring that all use of your account and the Services complies with
            this Agreement. You will immediately notify Juristat if you become aware of any
            unauthorized use of your account.
          </p>
          <p className="text-2xl">15. Account Administrators.</p>
          <p>
            Your business or organization may designate certain account "administrators" to manage
            your account and your use and access to the Services. If an administrator is designated
            for your account, the administrator may have the ability to access, monitor, review,
            change, restrict, or terminate your account or your access to the Services. Juristat is
            not responsible for the actions of your designated account administrator.
          </p>
          <p className="text-2xl">16. Terms of Service and Privacy Policy.</p>
          <p>
            By accepting this Agreement, you acknowledge that you have also read, understand, and
            agree to, Juristat's Terms of Use and Privacy Policy.{' '}
          </p>
          <p className="text-2xl">17. Intellectual Property.</p>
          <p>
            All technology, content, patents, trademarks, trade dress, copyrights, trade secrets,
            and other intellectual property is owned by or licensed to Juristat and protected by
            United States and international intellectual property laws and treaties. Except as
            expressly otherwise provided in this Agreement, Juristat and its licensors retain all
            proprietary rights, title, and interest in all such technology, content, and
            intellectual property.
          </p>
          <p className="text-2xl">18. Governing Law and Jurisdiction.</p>
          <p>
            This Agreement and the terms herein are governed by, and construed in accordance with,
            the laws of Delaware without regard to choice of law principles.
          </p>
          <p className="text-2xl">19. Entire Agreement.</p>
          <p>
            This Agreement represents the entire understanding and agreement of the parties
            regarding the subject matter of this Agreement and supersedes all prior agreements and
            understandings relating to the subject matter of this Agreement.
          </p>
          <p className="text-2xl">20. Assignment.</p>
          <p>You may not assign this Agreement without the prior written consent of Juristat.</p>
          <p className="text-2xl">21. Severability.</p>
          <p>
            If any provision of this Agreement is determined to be invalid or unenforceable, it will
            not affect the validity or enforceability of the other provisions of this Agreement,
            which shall remain in full force and effect.
          </p>
          <p className="text-2xl">22. No Waiver.</p>
          <p>
            If a party fails or is delayed in exercising any right, power, or privilege under this
            Agreement, it shall not be considered a waiver.
          </p>
        </div>
      </LayoutContainer>
    </PageContainer>
  )
}
