import { css } from 'emotion'
import React from 'react'

import { WarningStandard } from '../modules/icons'
import { colors, textStyles } from '../styles'

type InfoTextProps = {
  children: React.ReactNode
  className?: string
  icon?: React.ReactNode
  tw?: string
}

const styles = {
  icon: css({
    '& svg': {
      fill: colors.silver2,
      height: 40,
      width: 40,
    },
    marginBottom: 20,
  }),
  main: css(textStyles.charcoalGrayNormal16, {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  }),
  text: css({
    textAlign: 'center',
  }),
}

export default function InfoText({
  children,
  className,
  icon = <WarningStandard />,
  tw = '',
}: InfoTextProps) {
  return (
    <div className={tw ? `flex flex-col items-center ${tw}` : css(styles.main, className)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.text}>{children}</div>
    </div>
  )
}
