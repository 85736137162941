import * as React from 'react'

import SVG from './SVG'
import type { IconProps } from './types'

export const Export = (props: IconProps) => (
  <SVG height="20" fill="none" width="20" title="export" viewBox="0 0 20 20" {...props}>
    <path
      d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)
