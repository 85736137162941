import { RadioOption } from '@juristat/common/types'
import * as React from 'react'

import { twTextStyles } from '../styles'

type RadioProps<T> = RadioOption<T> & {
  classNameSelected?: string
  select: (value: T) => void
  selected: boolean
}

const styles = {
  disabled: 'cursor-not-allowed opacity-60',
  dot: 'w-[6px] h-[6px] bg-white rounded-[9999px] shrink-0',
  nativeRadio:
    'before:border before:border-gray-300 before:rounded-[9999px] before:content-[""] before:block before:h-5 before:w-5 border-0 bg-clip-[rect(0_0_0_0) h-[1px] -m-[1px] overflow-hidden p-0 absolute w-0',
  radioAndLabel: `${twTextStyles.charcoalGrayNormal12} items-center cursor-pointer flex`,
  styledRadioContainer:
    'flex justify-center items-center [&_>_span]:invisible items-center bg-gray-50 p-2 border border-gray-300 hover:shadow-[0px_0px_0px_4px_rgba(152,_162,_179,_0.14)] rounded-[9999px] cursor-pointer flex shrink-0 h-4 justify-center mr-[9px] w-4',
  styledRadioContainerChecked:
    '[&_>_span]:!visible !bg-brand-600 border-link-blue hover:!shadow-[0px_0px_0px_4px_rgba(46,_144,_250,_0.24)]',
}

const Radio = <T,>({
  classNameSelected,
  disabled = false,
  label,
  select,
  selected,
  value,
}: RadioProps<T>) => (
  <label
    className={`${styles.radioAndLabel} ${selected ? classNameSelected : ''} ${
      disabled ? styles.disabled : ''
    }`}
    onClick={(evt) => {
      evt.preventDefault()

      if (disabled) {
        return
      }

      select(value)
    }}
  >
    <input className={styles.nativeRadio} readOnly type="radio" value={String(value)} />
    <div
      className={`${styles.styledRadioContainer} ${
        selected ? styles.styledRadioContainerChecked : ''
      } ${disabled ? styles.disabled : ''}`}
    >
      <span className={styles.dot}></span>
    </div>
    <span>{label}</span>
  </label>
)

export default Radio
