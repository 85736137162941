import React, { Fragment } from 'react'

import { IconProps } from '../../icons'
import { OmnisearchResult } from '../types'

type SectionProps = {
  children: React.ReactNode
  data: OmnisearchResult[]
  icon: (props: IconProps) => JSX.Element
  label: string
}

const Section: React.FC<SectionProps> = ({ children, data, icon: Icon, label }) => (
  <Fragment>
    <div
      className={`mb-2 flex h-6 items-center text-base font-bold transition-[color] duration-300 ease-in-out [&:not(:first-of-type)]:mt-5 [&_>_svg]:mr-[10px] ${
        data.length === 0 ? 'text-gray-900' : ''
      }`}
    >
      <Icon height={16} width={16} /> {label} ({data.length})
    </div>
    {children}
  </Fragment>
)

export default Section
