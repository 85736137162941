export function getTextWidth(value: string, font = "'Inter'") {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return 0
  }

  ctx.font = `700 13px ${font}`

  const { width } = ctx.measureText(value)

  return width
}
