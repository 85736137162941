import { css, cx } from 'emotion'
import React, { forwardRef } from 'react'

import { Close, Search, Spinner } from '../modules/icons'
import { colors } from '../styles'
import { InputHandle } from './Input'
import TextInput, { TextInputProps } from './TextInput'

type SearchTextInputProps = TextInputProps &
  Partial<{
    fetching: boolean
    onClear: () => void
  }>

const styles = {
  container: css({
    height: 36,
    position: 'relative',
  }),
  icon: css({
    position: 'absolute',
    transition: 'fill 300ms ease-out',
  }),
  iconClose: css({
    '&:hover': {
      cursor: 'pointer',
      fill: colors.pastelRed,
    },
    fill: colors.silver,
    right: 12,
    top: 12,
  }),
  iconSearch: css({
    fill: colors.silver,
    left: 12,
    top: 12,
  }),
  iconSpin: (accent: 'blue' | 'green') =>
    css({
      right: 12,
      stopColor: 'blue',
      stroke: accent === 'blue' ? '#1570EF' : colors.greenyBlue,
      top: 10,
    }),
  input: (accent: 'blue' | 'green') =>
    css({
      '&::-ms-clear': {
        display: 'none',
      },
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '&::-webkit-search-cancel-button': {
        display: 'none',
      },
      '&:focus': {
        '& + svg': {
          fill: accent === 'blue' ? '#1570EF' : colors.greenyBlue,
        },
      },
      '&[disabled]': {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      fontSize: 12,
      height: 36,
      paddingLeft: 33,
      transition: 'background-color 300ms ease-out, border 300ms ease-out',
      width: '100%',
    }),
  inputAndClose: css({
    paddingRight: 33,
  }),
  inputValid: css({
    backgroundColor: colors.white,
  }),
}

const SearchTextInput = forwardRef<InputHandle, SearchTextInputProps>(
  ({ className, fetching = false, onClear, tw = '', ...props }, ref) => (
    <div
      className={
        tw ? `relative h-9 [&_>_input]:rounded-lg ${tw}` : css(styles.container, className)
      }
    >
      <TextInput
        className={cx(styles.input(props.accent ?? 'green'), {
          [styles.inputValid]: props.text !== '',
          [styles.inputAndClose]: onClear !== undefined,
        })}
        ref={ref}
        type="search"
        {...props}
      />
      <Search className={css(styles.icon, styles.iconSearch)} height={13} width={13} />
      {fetching && (
        <Spinner
          className={css(styles.icon, styles.iconSpin(props.accent ?? 'green'))}
          height={18}
          title="Fetching data..."
          width={18}
        />
      )}
      {!fetching && onClear && (
        <Close
          className={css(styles.icon, styles.iconClose)}
          height={13}
          onClick={onClear}
          title="Clear Search Term"
          width={13}
        />
      )}
    </div>
  )
)

export default SearchTextInput
