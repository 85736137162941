import * as React from 'react'

import SVG from './SVG'
import type { IconProps } from './types'

export const ExpandMenu = (props: IconProps) => (
  <SVG height="24" width="24" title="expand-menu" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M18.9231 14.0765L21 11.9995L18.9231 9.92263M3 11.9995H15.4615M3 7.8457H15.4615M3 16.1534H15.4615"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)
