import { ActionTypes, assign, createMachine, send, sendParent } from 'xstate'

type ValidationContext = { text: string }
type UpdateEvent = ValidationContext & { type: 'UPDATE' }
type ResetEvent = { type: 'RESET' }
type RestoreEvent = ValidationContext & { type: 'RESTORE' }

type ValidationEvent = ResetEvent | RestoreEvent | UpdateEvent

type MultipleEvent =
  | { type: 'ADD' }
  | (ValidationContext & { type: 'PASTE' })
  | ResetEvent
  | UpdateEvent

type IsValid = (context: ValidationContext) => boolean

const parse = (text: string) =>
  text
    .split(/(,| )/)
    .map((text) => text.replace(/(,| )/g, '').trim())
    .filter(Boolean)

const createMultipleMachine = (id: string) =>
  createMachine<ValidationContext, MultipleEvent>({
    id,
    initial: 'empty',
    context: {
      text: '',
    },
    on: {
      PASTE: [
        { target: 'empty', cond: (_, { text }) => text === '' },
        {
          actions: sendParent((_, { text }) => ({
            value: parse(text),
            type: `ADD_${id.toUpperCase()}`,
          })),
          target: 'empty',
          cond: (_, { text }) => Boolean(parse(text).length),
        },
        { actions: assign((_, { text }) => ({ text })), target: 'invalid' },
      ],
      RESET: 'empty',
      UPDATE: {
        actions: assign({ text: (_, { text }) => text }),
        target: 'validating',
      },
    },
    states: {
      empty: {
        entry: assign({ text: '' }),
      },
      invalid: {},
      valid: {
        on: {
          ADD: {
            actions: [
              sendParent((context) => ({
                value: parse(context.text),
                type: `ADD_${id.toUpperCase()}`,
              })),
              send('RESET'),
            ],
          },
        },
      },
      validating: {
        after: {
          0: [
            { target: 'empty', cond: (context) => context.text === '' },
            {
              target: 'valid',
              cond: (context) => Boolean(parse(context.text).length),
            },
          ],
          300: 'invalid',
        },
      },
    },
  })

const createValidationMachine = (id: string, isValid: IsValid) =>
  createMachine<ValidationContext, ValidationEvent>({
    id,
    initial: 'empty',
    context: {
      text: '',
    },
    on: {
      RESET: 'empty',
      RESTORE: {
        actions: assign({ text: (_, { text }) => text }),
        target: 'valid',
      },
      UPDATE: {
        actions: [
          assign({ text: (_, { text }) => text }),
          sendParent(ActionTypes.Update, { delay: 300 }),
        ],
        target: 'validating',
      },
    },
    states: {
      empty: {
        entry: assign({ text: '' }),
      },
      invalid: {},
      valid: {},
      validating: {
        after: {
          0: [
            { target: 'empty', cond: (context) => context.text === '' },
            { target: 'valid', cond: isValid },
          ],
          300: 'invalid',
        },
      },
    },
  })

export function toColumnOptions<T>(
  columns: Array<Partial<{ Header: T; accessor: unknown; id: string }>>
) {
  return columns
    .map((column) => ({
      label: `${column.Header}`,
      value: typeof column.accessor === 'string' ? column.accessor : column.id,
    }))
    .filter((column): column is Record<'label' | 'value', string> => Boolean(column.label))
}

export function getPortalStatusColors(status: string) {
  switch (status) {
    case 'sent':
      return 'bg-green-100 border border-green-300 text-green-700 px-2 py-1/2 rounded-xl font-inter'
    case 'in-progress':
      return 'bg-orange-100 border border-orange-300 text-orange-500 px-2 py-1/2 rounded-xl font-inter'
    case 'in-review':
      return 'bg-violet-100 border border-violet-300 text-violet-500 px-2 py-1/2 rounded-xl font-inter'
    case 'pending':
      return 'bg-blue-100 border border-blue-300 text-brand-700 px-2 py-1/2 rounded-xl font-inter'
    default:
      return 'bg-gray-100 border border-gray-300 text-gray-500 px-2 py-1/2 rounded-xl font-inter'
  }
}

export { createMultipleMachine, createValidationMachine }
