import * as React from 'react'

import { Code } from '../../modules/icons'

const ProductInfo = () => (
  <div className="ml-[1.8em] text-[#838383]">
    <Code className="-mb-1 h-4 w-4 fill-[#63a1d1]" title={process.env.PRODUCT_INFO} />
  </div>
)

export default ProductInfo
