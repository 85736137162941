import React, { useMemo } from 'react'

import getAvailablePageRange from '../utils/getAvailablePageRange'
import Button from './Button'

type PaginationBarProps = {
  className?: string
  current: number
  go: (page: number) => void
  pageCount: number
  theme?: 'green' | 'blue'
}

const styles = {
  container: '[&_>_:not(:last-child]:mr-1 bg-white inline-flex justify-end p-0.5 w-full',
  page: 'text-gray-600 text-s h-8 w-8',
  pageActive: 'text-[#182230] font-bold',
  prevNext:
    'disabled:text-[#98A2B] disabled:font-normal text-gray-600 bg-white border border-gray-200 rounded-lg text-s font-bold py-[7px] px-[11px]',
}

const PaginationBar = ({ className, current, go, pageCount }: PaginationBarProps) => {
  const pageList = useMemo(
    () => getAvailablePageRange(current, pageCount, 10),
    [current, pageCount]
  )
  const hasNext = current < pageCount
  const hasPrevious = current > 1

  if (pageCount <= 1) {
    return null
  }

  return (
    <div className={`${styles.container} ${className ?? ''}`}>
      <Button active={hasPrevious} handleClick={() => go(current - 1)} tw={styles.prevNext}>
        Previous
      </Button>
      {pageList.map((num) => (
        <Button
          aria-pressed={num === current}
          data-pressed={num === current}
          handleClick={() => go(num)}
          key={String(num)}
          tw={`${styles.page} ${num === current ? styles.pageActive : ''}`}
        >
          {num}
        </Button>
      ))}
      <Button active={hasNext} handleClick={() => go(current + 1)} tw={styles.prevNext}>
        Next
      </Button>
    </div>
  )
}

export default PaginationBar
