import config from '@juristat/config'
import { isNil, reject } from 'ramda'
import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api'
import {
  NotificationTypes,
  makeNotification,
  actions as notificationActions,
} from '../../../../notification'
import actions from '../actions'
import { CreateUserAction, CreateUserPayload } from '../types'

const makeBody = ({ groupId, roles = ['ROLE_ACTIVE_USER'], username }: CreateUserPayload) =>
  reject(isNil, {
    groupId,
    noverify: true,
    roles,
    username,
  })

const makeErrorMessage = ({ username }: CreateUserPayload) =>
  `Failed to create new user with username ${username}`

const makeOptions = (body: WeakObject) => ({
  body: JSON.stringify(body),
  headers: { 'Content-Type': 'application/json' },
  method: 'POST',
})

const url = `${config.accountsUrl}/users`

function* createUser(action: CreateUserAction) {
  try {
    const body = makeBody(action.payload!)
    const options = makeOptions(body)
    const response = yield call(api, url, options)
    if (response.ok) {
      const { uuid } = yield call([response, 'json'])
      yield put(
        notificationActions.push(
          makeNotification({
            link: {
              text: action.payload!.username,
              to: `/settings/account-tools/users/${uuid}`,
            },
            message: 'User {{link}} created successfully',
            type: NotificationTypes.Success,
          })
        )
      )
    } else {
      throw new Error(makeErrorMessage(action.payload!))
    }
  } catch (unknownErr) {
    const e = unknownErr as Error
    const message = e.message || makeErrorMessage(action.payload!)
    yield put(
      notificationActions.push(
        makeNotification({
          message,
          type: NotificationTypes.Error,
        })
      )
    )
  }
}

export { createUser, makeBody, makeErrorMessage, makeOptions, url }
export default function* watchCreateUser() {
  yield takeLatest(actions.create().type, createUser)
}
