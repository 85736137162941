export function getPillClassesByColor(color: string) {
  switch (color) {
    case 'green':
      return 'bg-green-100 border border-green-300 text-green-700 px-2 py-1/2 rounded-xl font-inter w-20 text-center'
    case 'orange':
      return 'bg-orange-100 border border-orange-300 text-orange-500 px-2 py-1/2 rounded-xl font-inter w-20 text-center'
    case 'purple':
      return 'bg-violet-100 border border-violet-300 text-violet-500 px-2 py-1/2 rounded-xl font-inter w-20 text-center'
    case 'blue':
      return 'bg-blue-100 border border-blue-300 text-brand-700 px-2 py-1/2 rounded-xl font-inter w-20 text-center'
    case 'red':
      return 'bg-red-100 border border-red-300 text-red-500 px-2 py-1/2 rounded-xl font-inter w-20 text-center'
    default:
      return 'bg-gray-50 border border-gray-300 text-gray-700 px-2 py-1/2 rounded-xl font-inter w-20 text-center'
  }
}
