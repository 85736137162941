import * as React from 'react'

import { X } from '../../modules/icons/v5/close'
import Button from '../Button'
import { ModalProps } from './Modal'

type ModalHeaderProps = Pick<ModalProps, 'closeModal' | 'title' | 'classNameHeaderContainer'>

const styles = {
  headerContainer:
    'items-center rounded-tl rounded-tr rounded-br-none rounded-bl-none flex justify-between p-[10px]',
}

const ModalHeader = ({ closeModal, title, classNameHeaderContainer }: ModalHeaderProps) => (
  <div className={`${styles.headerContainer} ${classNameHeaderContainer}`}>
    <h1
      className={`font-inter font-stretch-normal text-brand-950 m-2 pb-[10px] text-xl font-semibold not-italic tracking-normal`}
    >
      {title}
    </h1>
    {closeModal ? (
      <Button
        handleClick={closeModal}
        tw="items-center flex fill-dark h-[30px] -mt-6 w-[30px] justify-center"
      >
        <X className="stroke-gray-600" />
      </Button>
    ) : (
      <div />
    )}
  </div>
)

export default ModalHeader
