import { omit, values } from 'ramda'
import React, { useEffect, useState } from 'react'
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../../components/Button'
import { Direction } from '../../../../components/Chevron'
import { ModalBackground } from '../../../../components/Modal'
import { usePrevious } from '../../../../hooks'
import analyticsActions from '../../../analytics/actions'
import { IntercomActions } from '../../../analytics/types'
import sessionActions from '../../../session/actions'
import { getFeatureModal } from '../../../session/selectors'
import { Feature } from '../../../session/types'
import GalleryNav from './GalleryNav'
import allFeatureInfo, { FeatureInfo } from './featureInfo'
import featureIntercomEvents from './featureIntercomEvents'

const galleryStyles =
  '[&_.image-gallery]:items-center [&_.image-gallery]:flex [&_.image-gallery]:flex-col [&_.image-gallery]:justify-center [&_.image-gallery-content]:relative [&_.image-gallery-slide.center]:relative [&_.image-gallery-slide]:left-0 [&_.image-gallery-slide]:overflow-hidden [&_.image-gallery-slide]:absolute [&_.image-gallery-slide]:top-0 [&_.image-gallery-slide]:w-full [&_.image-gallery-slides]:overflow-hidden [&_.image-gallery-slides]:relative [&_.image-gallery-slides]:whitespace-nowrap'

const styles = {
  galleryItem: 'max-h-full max-w-full',
  galleryItemContainer: 'items-center flex h-[190px] justify-center w-[300px]',
  galleryNav: (direction: Direction) =>
    `absolute ${
      direction === Direction.Left ? '-left-12' : direction === Direction.Right ? '-right-12' : ''
    } top-[calc(50%_-_11px)]`,
  leftContainer:
    ' [&_a]:text-pale-gray2 [&_a]:font-inter [&_a]:text-s [&_a]:font-normal [&_button:hover]:shadow-[0px_4px_20px_0px_rgba(48,_55,_65,_0.3)] [&_button]:text-charcoal-gray2 [&_button]:font-inter [&_button]:text-s [&_button]:font-bold [&_button]:bg-white [&_button]:rounded [&_button]:h-8 [&_button]:mb-10 [&_button]:transition-[box-shadow] [&_button]:duration-300 [&_button]:ease-in-out [&_button]:w-[190px] [&_h1]:text-pale-gray2 [&_h1]:font-inter [&_h1]:text-base [&_h1]:font-bold [&_h1]:uppercase [&_h1]:mt-[15px] [&_h1]:mb-[25px] [&_h1]:mx-0 [&_p]:text-pale-gray2 [&_p]:font-inter [&_p]:text-s [&_p]:font-normal [&_p]:leading-[18px] [&_p]:mt-0 [&_p]:mx-[10px] [&_p]:mb-[25px] items-center bg-greeny-blue rounded-tl rounded-tr-none rounded-br-none rounded-bl flex flex-col h-[400px] p-5 text-center w-[250px]',
  main: 'flex',
  rightContainer:
    '[&_h2]:text-charcoal-gray2 [&_h2]:font-inter [&_h2]:text-xs [&_h2]:font-bold [&_h2]:uppercase [&_h2]:mb-[15px] [&_p]:text-charcoal-gray2 [&_p]:font-inter [&_p]:text-s [&_p]:font-normal [&_p]:items-end [&_p]:flex [&_p]:h-[88px] [&_p]:justify-center [&_p]:leading-[22px] [&_p]:-mb-[7px] bg-white rounded-tl-none rounded-tr rounded-br rounded-bl-none flex flex-col h-[400px] justify-between py-[38px] px-[29px] text-center w-[450px]',
}

const renderItem = ({ original }: ReactImageGalleryItem) => (
  <div className={styles.galleryItemContainer}>
    <img className={styles.galleryItem} src={original} />
  </div>
)

const FeatureModal: React.FC = () => {
  const dispatch = useDispatch()
  const { focusFeature, open } = useSelector(getFeatureModal)
  const [currentIndex, setCurrentIndex] = useState(0)
  const previousFocusFeature = usePrevious(focusFeature, true)
  const previousOpen = usePrevious(open, true)

  const featureArray: FeatureInfo[] = focusFeature
    ? [allFeatureInfo[focusFeature], ...values(omit([focusFeature], allFeatureInfo))]
    : values(allFeatureInfo)

  const itemsArray = featureArray.map((featureInfo) => ({
    ...featureInfo,
    original: featureInfo.img,
  }))

  useEffect(() => {
    if ((previousOpen === false || previousFocusFeature !== focusFeature) && open) {
      dispatch(analyticsActions.intercom.log(featureIntercomEvents[focusFeature || Feature.Other]))
    }
  }, [focusFeature, open, previousFocusFeature, previousOpen])

  const closeModal = () => {
    dispatch(
      sessionActions.featureModalSet({
        open: false,
      })
    )
  }

  const chatWithSales = () => {
    closeModal()
    dispatch(analyticsActions.intercom.log(IntercomActions.FeatureChatWithSales))
    dispatch(
      analyticsActions.intercom.showNewMessage(
        "Hi, I'm interested in hearing about Juristat Platform"
      )
    )
  }

  return open ? (
    <ModalBackground closeModal={closeModal}>
      <div className={styles.main}>
        <div className={styles.leftContainer}>
          <h1>Patent prosecution reinvented.</h1>
          <p>
            Platform is the most comprehensive patent analytics tool available, with nearly
            limitless ways to customize data sets and run analytics, keep tabs on applications, and
            generate competitive insights.
          </p>
          <Button handleClick={chatWithSales}>Chat With Sales</Button>
          <a
            target="_blank"
            href="https://blog.juristat.com/introducing-juristat-platform"
            rel="noreferrer"
          >
            Learn More
          </a>
        </div>
        <div className={`${galleryStyles} ${styles.rightContainer}`}>
          <h2>{itemsArray[currentIndex].title}</h2>
          <ReactImageGallery
            items={itemsArray}
            onSlide={setCurrentIndex}
            renderItem={renderItem}
            renderLeftNav={(onClick, isDisabled) => (
              <GalleryNav
                className={styles.galleryNav(Direction.Left)}
                onClick={onClick}
                disabled={isDisabled}
                direction={Direction.Left}
              />
            )}
            renderRightNav={(onClick, isDisabled) => (
              <GalleryNav
                className={styles.galleryNav(Direction.Right)}
                onClick={onClick}
                disabled={isDisabled}
                direction={Direction.Right}
              />
            )}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
          />
          <p>{itemsArray[currentIndex].description}</p>
        </div>
      </div>
    </ModalBackground>
  ) : null
}

export default FeatureModal
