import React from 'react'

import { useApp } from '../modules/api'

type HexResponse = {
  error: string
  url: string
}

export default function Demo() {
  const [embedUrl] = useApp<HexResponse>(
    'hex-url',
    '/oar/hex/get-embed-url/38e33291-1138-4d31-af20-2f7009a3c905'
  )
  const url = embedUrl?.context?.data?.url
    ? `${embedUrl?.context?.data?.url}&noEmbedFooter=true&noEmbedOutline=true`
    : null
  const error = embedUrl?.context?.data?.error ?? null
  const backupUrl =
    'https://app.hex.tech/33e23625-7921-4b15-8c22-569bd307b003/app/38e33291-1138-4d31-af20-2f7009a3c905/latest?embedded=true&noEmbedFooter=true&noEmbedOutline=true'

  return (
    <div className="no-scrollbar container w-full overflow-y-scroll">
      {url ? (
        <iframe className="no-scrollbar w-full" src={url} style={{ height: 3400 }} />
      ) : error ? (
        <div className="text-red-400">{error}</div>
      ) : (
        <iframe className="no-scrollbar w-full" src={backupUrl} style={{ height: 3400 }} />
      )}
    </div>
  )
}
