import React from 'react'

import InformationPopup from '../../../components/InformationPopup'

type SingleOrArray<T> = T | T[]

type WorkflowAutomationFieldProps = {
  children: SingleOrArray<JSX.Element>
  label: string
  necessity?: 'optional' | 'required'
  optional?: true
  tooltip?: string
}

const WorkflowAutomationField = ({
  children,
  label,
  necessity,
  optional,
  tooltip,
}: WorkflowAutomationFieldProps) => {
  return (
    <div className="[&:not(:first-of-type)]:mt-5">
      <div className="flex items-center justify-between">
        <label className="font-inter font-stretch-normal text-s block font-bold not-italic tracking-normal text-gray-600 [&_>_span]:font-normal">
          {label}{' '}
          {optional ?? necessity === 'optional' ? (
            <span>(optional)</span>
          ) : necessity === 'required' ? (
            <span className="bold text-red-600">*</span>
          ) : null}{' '}
        </label>
        {tooltip ? (
          <InformationPopup className="[&_>_button]:h-6 [&_>_button]:w-6" children={tooltip} />
        ) : null}
      </div>
      {children}
    </div>
  )
}

export default WorkflowAutomationField
