import * as React from 'react'

import { Close } from '../modules/icons'
import { twTextStyles } from '../styles'

type TagProps = {
  className?: string
  handleOnRemoveClick: () => void
  key?: string
  label: string
}

const styles = {
  icon: 'cursor-pointer fill-dark',
  main: 'items-center bg-cloudy-blue rounded flex h-[26px] justify-between pr-2',
  text: `${twTextStyles.darkNormal14} overflow-hidden py-[3.5px] px-2 text-ellipsis`,
}

const Tag = ({ className, handleOnRemoveClick, label }: TagProps) => (
  <div className={`${styles.main} ${className ?? ''}`}>
    <div className={styles.text}>{label}</div>
    <Close className={styles.icon} onClick={handleOnRemoveClick} height={10} width={10} />
  </div>
)

export default Tag
