import * as React from 'react'

import SVG from './SVG'
import { DirectionalProps, IconProps } from './types'

const getRotateAngle = ({ direction }: DirectionalProps) => {
  switch (direction) {
    case 'right':
      return 0
    case 'down':
      return 90
    case 'left':
      return 180
    case 'up':
      return -90
  }
}

export const Caret = ({ direction = 'down', ...props }: DirectionalProps & IconProps) => {
  return (
    <SVG
      fill="none"
      title={`caret-${direction}`}
      transform={`rotate(${getRotateAngle({ direction })})`}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M7.5 15L12.5 10L7.5 5"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  )
}
