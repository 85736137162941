import { DataSource, Filter, Key } from '@juristat/common/types'
import Downshift from 'downshift'
import { css } from 'emotion'
import React, { useMemo, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import InfoText from '../../../components/InfoText'
import Input from '../../../components/Input'
import { TableFetchingLoader } from '../../../components/StaticTable'
import noop from '../../../utils/noop'
import RawNamesModal from '../../filter/components/RawNamesModal'
import { IconProps, Search } from '../../icons'
import { BookOpen } from '../../icons/v5/book-open'
import { Briefcase } from '../../icons/v5/briefcase'
import { Building } from '../../icons/v5/building'
import { IntersectSquare } from '../../icons/v5/intersect-square'
import { User } from '../../icons/v5/user'
import { Users } from '../../icons/v5/users'
import { useSearchVariables } from '../../search/hooks'
import { useOmnisearchResults } from '../hooks'
import { OmnisearchResult, OmnisearchType } from '../types'
import Section from './Section'

type RenderProps = {
  displayType?: 'modal' | 'page'
  input: OmnisearchProps['input']
  searchTerm: string
  setSearchOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

type SectionItem = {
  key: OmnisearchType
  icon: (props: IconProps) => JSX.Element
  label: string
}

type AdditionalSections = {
  type: string
  sections: SectionItem[][]
}

type OmnisearchProps = {
  additionalSections?: AdditionalSections
  children?: (props: RenderProps) => React.ReactNode
  className?: string
  displayType?: 'modal' | 'page'
  forceOpen?: boolean
  searchType?: 'category' | 'keyword'
  initialValue?: string
  input?: (payload?: string) => void
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  onClose?: () => void
  onOuterClick?: () => void
  renderFooter?: (props: RenderProps) => React.ReactNode
  renderResult: (props: OmnisearchResult) => JSX.Element
  renderTop?: (props: RenderProps) => React.ReactNode
  showRawNamesPrompt?: boolean
  tw?: boolean
}

// The types downshift exports are not accurate
interface GetInputPropsOptions
  extends Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    | 'aria-autocomplete'
    | 'aria-activedescendant'
    | 'aria-controls'
    | 'aria-labelledby'
    | 'autoComplete'
    | 'value'
    | 'id'
    | 'onChange'
    | 'onKeyDown'
    | 'onBlur'
  > {
  disabled?: boolean
}

const styles = {
  close:
    'absolute [&_>_svg]:fill-santas-gray [&_>_svg]:h-3 [&_>_svg]:w-3 h-[30px] right-3 top-3 w-[30px]',
  column:
    '[&_+_&]:pl-[10px] [&:not(:empty)]:flex-[0_0_50%] [&:not(:empty)]:pb-[30px] overflow-hidden',
  fetching: (width: number) =>
    css({
      marginBottom: 10,
      width: `${width}%`,
    }),
  info: 'text-red-500 pt-5',
  input:
    '[&:focus_+_svg]:fill-gray-500 focus:bg-shite focus:border focus:border-brand-600 ml-12 w-[600px] text-base text-gray-900 bg-white border border-gray-200 rounded-lg h-9 outline-none py-[10px] pr-[10px] pl-[38px] transition-[background-color,border] duration-300 ease-out',
  error: '!text-red-500',
  main: css({
    position: 'relative',
  }),
  modal: (displayType: 'modal' | 'page', isOpen: boolean) =>
    `bg-white ${isOpen ? 'opacity-100' : 'opacity-0'} ${isOpen ? 'visible' : 'invisible'} ${
      displayType === 'modal'
        ? 'border border-gray-300 shadow-lg rounded-xl w-[700px] absolute left-[calc((100%_-_700px)_/_2)] '
        : 'pt-[60px] w-full'
    }`,
}

const rawNamesModalStyles = {
  modal: 'left-[66px]',
  trigger:
    '[&_svg]:hidden border-0 text-[#109fff] font-bold text-xs ml-7 p-0 whitespace-nowrap w-auto',
}

const defaultSections: AdditionalSections = {
  type: 'category',
  sections: [
    [
      { icon: Building, key: OmnisearchType.Company, label: 'Companies' },
      { icon: Briefcase, key: OmnisearchType.Firm, label: 'Firms' },
      { icon: User, key: OmnisearchType.Examiner, label: 'Examiners' },
    ],
    [
      { icon: IntersectSquare, key: OmnisearchType.TechCenter, label: 'Tech Centers' },
      { icon: Users, key: OmnisearchType.ArtUnit, label: 'Art Units' },
      { icon: BookOpen, key: OmnisearchType.Cpc, label: 'CPC Classes' },
      { icon: BookOpen, key: OmnisearchType.Uspc, label: 'USPC Classes' },
    ],
  ],
}

const attorneySections: SectionItem[][] = [
  [{ icon: User, key: OmnisearchType.Attorney, label: 'Attorneys' }],
]

const RawNamesPrompt = () => (
  <>
    <div className="text-xs">Don't see your company?</div>
    <RawNamesModal
      classNames={rawNamesModalStyles}
      filter={Filter.AssigneeAtDisposition}
      type="assignee"
      triggerButtonLabel="Search Raw Names"
    />
  </>
)

const Omnisearch: React.FC<OmnisearchProps> = ({
  additionalSections,
  children,
  className,
  displayType = 'page',
  forceOpen,
  searchType,
  initialValue = null,
  input = noop,
  inputProps,
  onOuterClick,
  renderFooter = () => null,
  renderResult,
  renderTop = () => null,
  showRawNamesPrompt = true,
  tw = false,
}) => {
  const { dataSource, searches, similarTo } = useSearchVariables()
  const isSearchPath = useRouteMatch('/search') !== null
  const [searchTerm, setSearchTerm] = useState<string | null>(() => {
    const [value] = Object.values({ ...searches, ...similarTo })

    return isSearchPath ? value || null : forceOpen && initialValue ? initialValue : null
  })
  const isPrivatePair = dataSource === DataSource.PrivatePair

  const { key } = useLocation()

  const sections = useMemo(() => {
    if (isPrivatePair) {
      return attorneySections
    }

    const [additionalLeft, additionalRight] = additionalSections?.sections ?? [[], []]

    const [left, right] =
      additionalSections && additionalSections.type === 'expert'
        ? [[], []]
        : [[...defaultSections.sections[0], ...attorneySections[0]], defaultSections.sections[1]]

    return [
      [...left, ...additionalLeft],
      [...right, ...additionalRight],
    ]
  }, [additionalSections, searchType, isPrivatePair])

  const [machines] = useOmnisearchResults({
    searchTerm,
    isPrivatePair,
  })

  return (
    <Downshift
      key={key}
      isOpen={true}
      onOuterClick={() => {
        onOuterClick?.()
      }}
      stateReducer={({ isOpen }, changes) => {
        switch (changes.type) {
          case Downshift.stateChangeTypes.blurInput:
            return { ...changes, isOpen: isOpen ?? true }
          default:
            return changes
        }
      }}
    >
      {({ getInputProps, isOpen, openMenu }) => (
        <div className={tw ? `relative w-full ${className}` : css(styles.main, className)}>
          <div className={styles.modal(displayType, isOpen)}>
            <div className="flex flex-col">
              {renderTop({ input, searchTerm: searchTerm ?? '' })}
              <div className="[&_>_svg]:fill-placeholder relative z-[1] mx-auto flex w-[600px] justify-center [&_>_svg]:absolute [&_>_svg]:left-[10px] [&_>_svg]:top-[11px] [&_>_svg]:h-[13px] [&_>_svg]:w-[13px] [&_>_svg]:transition-[fill] [&_>_svg]:duration-300 [&_>_svg]:ease-out">
                <Input
                  {...(getInputProps({
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchTerm(event.target.value),
                    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (event.keyCode === Key.Enter) {
                        input(searchTerm ?? undefined)
                      }
                    },
                    value: searchTerm ?? initialValue ?? '',
                  }) as GetInputPropsOptions)}
                  {...inputProps}
                  className="focus:border-brand-600 border-bray-300 h-9 w-[600px] rounded-lg border bg-white py-[10px] pl-[38px] pr-[10px] text-base text-gray-900 outline-none transition-[background-color,border] duration-300 ease-out focus:border focus:bg-white [&:focus_+_svg]:fill-gray-500"
                  handleOnBlur={openMenu}
                  handleOnFocus={() => {
                    if (searchTerm === null && initialValue) {
                      setSearchTerm(initialValue)
                    }

                    openMenu()
                  }}
                  onClick={() => openMenu()}
                  placeholder="Search for applications and reports by examiner, company name, firm, attorney, tech center, art unit, or class."
                />
                <Search />
              </div>
            </div>
            <div className="mx-auto -mb-[30px] flex w-[660px] flex-wrap px-[30px] pl-[30px] pt-5">
              {children?.({ input, searchTerm: searchTerm ?? '' }) ??
                sections.map((section, index) => (
                  <div key={String(index)} className={styles.column}>
                    {section.map(({ key, ...props }) => {
                      const machine = machines[key]

                      return (
                        <React.Fragment key={key}>
                          {machine.matches('loading') ? (
                            <Section data={[]} {...props}>
                              {Array(Math.floor(Math.random() * 3) + 3)
                                .fill(0)
                                .map((_, idx) => (
                                  <TableFetchingLoader
                                    className={styles.fetching(Math.floor(Math.random() * 40) + 31)}
                                    key={String(idx)}
                                  />
                                ))}
                            </Section>
                          ) : machine.matches('failure') ? (
                            <Section data={[]} {...props}>
                              <InfoText tw={styles.info}>{machine.context.error}</InfoText>
                            </Section>
                          ) : (
                            <Section data={machine.context.data ?? []} {...props}>
                              {machine.context.data?.map(renderResult)}
                              {showRawNamesPrompt &&
                              props.label === 'Companies' &&
                              searchTerm !== null &&
                              Array.isArray(machine.context.data) ? (
                                <RawNamesPrompt />
                              ) : null}
                            </Section>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                ))}
            </div>
            {renderFooter({ input, searchTerm: searchTerm ?? '' })}
          </div>
        </div>
      )}
    </Downshift>
  )
}

export { RenderProps, SectionItem, AdditionalSections }
export default Omnisearch
