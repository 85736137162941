import * as React from 'react'

import { Caret } from './caret'

export enum Direction {
  Down = 'down',
  Up = 'up',
  Left = 'left',
  Right = 'right',
}

type ChevronProps = {
  className?: string
  color?: string
  direction: Direction
  handleClick?: () => void
  title: string
  tw?: boolean
}

export const Chevron = ({
  className,
  direction,
  handleClick,
  title,
}: ChevronProps): JSX.Element => (
  <Caret
    className={`transition-[transform] duration-300 ease-in-out ${className ?? ''}`}
    direction={direction}
    onClick={handleClick}
    title={title}
  />
)
