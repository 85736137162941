function addSelectors(css: string, selector: string): string {
  const arrClassNames = css.split(' ')
  return arrClassNames.map((className) => `${selector}:${className}`).join(' ')
}

const azureNormal10: string =
  'text-azure font-inter text-xxs font-stretch-normal not-italic tracking-normal'

const azureNormal13: string =
  'text-azure font-inter text-s font-stretch-normal not-italic tracking-normal'

const azureBold13: string =
  'text-azure font-inter text-s font-stretch-normal not-italic font-bold tracking-normal'

const blueNormal10: string =
  'text-brand-700 font-inter text-xxs font-stretch-normal not-italic tracking-normal'

const blueNormal13: string =
  'text-brand-700 font-inter text-s font-stretch-normal not-italic tracking-normal'

const blueBold13: string =
  'text-brand-700 font-inter text-s font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGrayBold10: string =
  'text-charcoal font-inter text-xxs font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGrayBold12: string =
  'text-charcoal font-inter text-xs font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGrayBold16: string =
  'text-charcoal font-inter text-base font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGrayNormal10: string =
  'text-charcoal font-inter text-xxs font-stretch-normal not-italic font-normal tracking-normal'

const charcoalGrayNormal12: string =
  'text-charcoal font-inter text-xs font-stretch-normal not-italic font-normal tracking-normal'

const charcoalGrayNormal13: string =
  'text-charcoal font-inter text-s font-stretch-normal not-italic font-normal tracking-normal'

const charcoalGrayNormal14: string =
  'text-charcoal font-inter text-sm font-stretch-normal not-italic font-normal tracking-normal'

const charcoalGray2Normal10: string =
  'text-charcoal font-inter text-xxs font-stretch-normal not-italic font-normal tracking-normal'

const charcoalGray2Semibold12: string =
  'text-charcoal font-inter text-xs font-stretch-normal not-italic font-semibold tracking-normal'

const charcoalGray2Bold12Caps: string =
  'text-charcoal font-inter text-xs font-stretch-normal not-italic font-semibold tracking-one uppercase'

const charcoalGrayNormal16: string =
  'text-charcoal font-inter text-base font-stretch-normal not-italic font-normal tracking-normal'

const charcoalGrayBold24: string =
  'text-charcoal font-inter text-2xl font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGray2Alpha50Bold12: string =
  'text-charcoal-gray2-alpha50 font-inter text-xs font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGray2Alpha50Bold24: string =
  'text-charcoal-gray2-alpha50 font-inter text-2xl font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGray2Bold13: string =
  'text-charcoal-gray2 font-inter text-s font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGray6Bold12: string =
  'text-charcoal-gray6 font-inter text-xs font-stretch-normal not-italic font-bold tracking-normal'

const charcoalGray6Bold36: string =
  'text-charcoal-gray6 font-inter text-4xl font-stretch-normal not-italic font-bold tracking-normal'

const darkBold12: string =
  'text-gray-600 font-inter text-xs font-stretch-normal not-italic font-bold tracking-normal'

const darkBold13: string =
  'text-gray-600 font-inter text-s font-stretch-normal not-italic font-bold tracking-normal'

const darkBold16: string =
  'text-gray-600 font-inter text-base font-stretch-normal not-italic font-bold tracking-normal'

const darkBold24: string =
  'text-gray-600 font-inter text-2xl font-stretch-normal not-italic font-bold tracking-normal'

const darkBold61: string =
  'text-gray-600 font-inter text-6xl font-stretch-normal not-italic font-bold tracking-normal'

const darkNormal12: string =
  'text-gray-600 font-inter text-xs font-stretch-normal not-italic font-normal tracking-normal'

const darkNormal13: string =
  'text-gray-600 font-inter text-s font-stretch-normal not-italic font-normal tracking-normal'

const darkNormal14: string =
  'text-gray-600 font-inter text-sm font-stretch-normal not-italic font-normal tracking-normal'

const darkNormal16: string =
  'text-gray-600 font-inter text-base font-stretch-normal not-italic font-normal tracking-normal'

const darkNormal24: string =
  'text-gray-600 font-inter text-2xl font-stretch-normal not-italic font-normal tracking-normal'

const dark2Semibold12: string =
  'text-gray-600 font-inter text-xs font-stretch-normal not-italic font-semibold tracking-normal'

const greenyBlueBold12: string =
  'text-greeny-blue font-inter text-xs font-stretch-normal not-italic font-bold tracking-normal'

const paleGrayNormal12: string =
  'text-pale-gray font-inter text-xs font-stretch-normal not-italic font-normal tracking-normal'

const paleGrayBold12: string =
  'text-pale-gray font-inter text-xs font-stretch-normal not-italic font-bold tracking-one leading-normal max-w-[300px]'

const paleGray2Bold10: string =
  'text-pale-gray2 font-inter text-xxs font-stretch-normal not-italic font-bold tracking-normal'

const paleGray2Bold12: string =
  'text-pale-gray2 font-inter text-xs font-stretch-normal not-italic font-bold tracking-one'

const paleGray2Bold13: string =
  'text-pale-gray2 font-inter text-s font-stretch-normal not-italic font-bold tracking-normal'

const paleGray2Normal12: string =
  'text-pale-gray2 font-inter text-xs font-stretch-normal not-italic font-normal tracking-normal'

const paleGray2Normal12Caps: string =
  'text-pale-gray2 font-inter text-xs font-stretch-normal not-italic font-normal tracking-normal uppercase'

const paleGray2Normal13: string =
  'text-pale-gray2 font-inter text-s font-stretch-normal not-italic font-normal tracking-normal'

const paleGray2Normal14: string =
  'text-pale-gray2 font-inter text-sm font-stretch-normal not-italic font-normal tracking-normal'

const paleGray2Normal15: string =
  'text-pale-gray2 font-inter text-m font-stretch-normal not-italic font-normal tracking-normal'

const paleGray2Bold16Caps: string =
  'text-pale-gray2 font-inter text-base font-stretch-normal not-italic font-bold tracking-normal uppercase'

const paleGray2Semibold10: string =
  'text-pale-gray2 font-inter text-xxs font-stretch-normal not-italic font-semibold tracking-one'

const charcoalGraySemibold12: string =
  'text-charcoal font-inter text-xs font-stretch-normal not-italic font-semibold tracking-normal'

const greenyBlueBold13: string =
  'text-greeny-blue font-inter text-s font-stretch-normal not-italic font-bold tracking-normal'

const linkBlueBold12: string =
  'text-brand-700 font-inter text-xs font-stretch-normal not-italic font-bold tracking-normal'

const linkBlueBold13: string =
  'text-brand-700 font-inter text-s font-stretch-normal not-italic font-bold tracking-normal'

const linkBlueBold16: string =
  'text-brand-700 font-inter text-base font-stretch-normal not-italic font-bold tracking-normal'

const linkBlueBold61: string =
  'text-brand-700 font-inter text-6xl font-stretch-normal not-italic font-bold tracking-normal'

const linkBlueNormal12: string =
  'text-brand-700 font-inter text-xs font-stretch-normal not-italic font-normal tracking-normal'

const linkBlueNormal13: string =
  'text-brand-700 font-inter text-s font-stretch-normal not-italic font-normal tracking-normal'

const linkBlueBold13Underlined: string =
  'text-brand-700 font-inter text-s font-stretch-normal not-italic font-bold tracking-normal underline'

const paleGray2Semibold14: string =
  'text-pale-gray2 font-inter text-sm font-stretch-normal not-italic font-semibold tracking-normal'

const placeholderNormal12: string =
  'text-placeholder font-inter text-xs font-stretch-normal not-italic font-normal tracking-normal'

const placeholderNormal13: string =
  'text-placeholder font-inter text-s font-stretch-normal not-italic font-normal tracking-normal'

const placeholderNormal16: string =
  'text-placeholder font-inter text-base font-stretch-normal not-italic font-normal tracking-normal'

const santasGray2Semibold12: string =
  'text-santas-gray font-inter text-xs font-stretch-normal not-italic font-semibold tracking-normal'

const silver3Normal13: string =
  'text-silver3 font-inter text-s font-stretch-normal not-italic font-normal tracking-normal'

const silver3Normal16: string =
  'text-silver3 font-inter text-base font-stretch-normal not-italic font-normal tracking-normal'

const torchRedNormal13: string =
  'text-torch-red font-inter text-s font-stretch-normal not-italic font-normal tracking-normal'

const whiteBold10: string =
  'text-white font-inter text-xxs font-stretch-normal not-italic font-bold tracking-normal'

const whiteBold12: string =
  'text-white font-inter text-xs font-stretch-normal not-italic font-bold tracking-normal'

const whiteBold13: string =
  'text-white font-inter text-s font-stretch-normal not-italic font-bold tracking-normal'

const whiteBold24: string =
  'text-white font-inter text-2xl font-stretch-normal not-italic font-bold tracking-normal'

const montserratDarkBold16: string =
  'text-gray-600 font-inter text-base font-stretch-normal not-italic font-bold tracking-normal leading-[1]'

const montserratDarkBold24: string =
  'text-gray-600 font-inter text-2xl font-stretch-normal not-italic font-bold tracking-normal leading-[1]'

const montserratAzureBold48: string =
  'text-azure font-inter text-5xl font-stretch-normal not-italic font-bold tracking-normal leading-[1]'

const montserratDarkNormal13: string =
  'text-gray-600 font-inter text-s font-stretch-normal not-italic font-semibold tracking-normal leading-[1]'

const montserratDarkNormal16: string =
  'text-gray-600 font-inter text-base font-stretch-normal not-italic font-semibold tracking-normal leading-[1]'

const montserratDarkNormal36: string =
  'text-gray-600 font-inter text-4xl font-stretch-normal not-italic font-semibold tracking-normal leading-[1]'

const montserratDarkNormal48: string =
  'text-gray-600 font-inter text-5xl font-stretch-normal not-italic font-semibold tracking-normal leading-[1]'

const montserratDarkNormal72: string =
  'text-gray-600 font-inter text-7xl font-stretch-normal not-italic font-semibold tracking-normal leading-[1]'

const montserratMineshaftNormal48: string =
  'text-mineshaft font-inter text-5xl font-stretch-normal not-italic font-normal tracking-normal leading-[1]'

export {
  addSelectors,
  azureBold13,
  azureNormal10,
  azureNormal13,
  blueBold13,
  blueNormal10,
  blueNormal13,
  charcoalGrayBold10,
  charcoalGrayBold12,
  charcoalGrayBold16,
  charcoalGrayNormal10,
  charcoalGrayNormal12,
  charcoalGrayNormal13,
  charcoalGrayNormal14,
  charcoalGrayNormal16,
  charcoalGrayBold24,
  charcoalGraySemibold12,
  charcoalGray2Alpha50Bold12,
  charcoalGray2Alpha50Bold24,
  charcoalGray2Bold13,
  charcoalGray2Bold12Caps,
  charcoalGray2Normal10,
  charcoalGray2Semibold12,
  charcoalGray6Bold12,
  charcoalGray6Bold36,
  darkBold12,
  darkBold13,
  darkBold16,
  darkBold24,
  darkBold61,
  darkNormal12,
  darkNormal13,
  darkNormal14,
  darkNormal16,
  darkNormal24,
  dark2Semibold12,
  greenyBlueBold12,
  greenyBlueBold13,
  linkBlueBold12,
  linkBlueBold13,
  linkBlueBold16,
  linkBlueBold13Underlined,
  linkBlueBold61,
  linkBlueNormal12,
  linkBlueNormal13,
  montserratAzureBold48,
  montserratDarkBold16,
  montserratDarkBold24,
  montserratDarkNormal13,
  montserratDarkNormal16,
  montserratDarkNormal36,
  montserratDarkNormal48,
  montserratDarkNormal72,
  montserratMineshaftNormal48,
  paleGrayBold12,
  paleGrayNormal12,
  paleGray2Bold10,
  paleGray2Bold12,
  paleGray2Bold13,
  paleGray2Normal12,
  paleGray2Normal12Caps,
  paleGray2Normal13,
  paleGray2Normal14,
  paleGray2Normal15,
  paleGray2Semibold10,
  paleGray2Semibold14,
  paleGray2Bold16Caps,
  placeholderNormal12,
  placeholderNormal13,
  placeholderNormal16,
  santasGray2Semibold12,
  silver3Normal13,
  silver3Normal16,
  torchRedNormal13,
  whiteBold10,
  whiteBold12,
  whiteBold13,
  whiteBold24,
}
