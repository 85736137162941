import Downshift, { StateChangeOptions } from 'downshift'
import { isEmpty, isNil } from 'ramda'
import React, { Ref, forwardRef } from 'react'

import { usePortal } from '../hooks'
import { colors } from '../styles'
import { cn } from '../utils/cn'
import ConditionalWrapper from './ConditionalWrapper'
import { InputHandle } from './Input'
import SearchTextInput from './SearchTextInput'

type TypeaheadProps<T> = {
  accent?: 'green' | 'blue'
  className?: string
  disabled?: boolean
  dropdownCss?: string
  fetching?: boolean
  handleClear?: () => void
  handleSelect: (item: T) => void
  handleTextChange: (text: string) => void
  items: T[]
  otherSuggestions?: React.ReactNode
  placeholder?: string
  portal?: { id: string; x: number; y: number }
  renderItem: (item: T) => React.ReactNode
  text: string
}

type WithKey = WeakObject & {
  key: string
}

const styles = {
  dropdownContainer: (accent: 'green' | 'blue') =>
    `bg-white border ${
      accent === 'green' ? 'border-brand-600' : 'border-azure'
    } rounded-bl rounded-br !border-t-none shadow-[0_8px_20px_0_rgba(48,_55,_65,_0.3)] max-h-[20em] overflow-y-auto py-1 px-0 absolute top-8 w-full z-[100]`,
  input: (isOpen: boolean, accent: 'green' | 'blue') =>
    `[&_input]:border ${
      isOpen ? (accent === 'green' ? 'border-brand-600' : 'border-azure') : 'border-gray-200'
    } ${isOpen ? 'rounded-bl-none' : 'rounded-bl'} ${isOpen ? 'rounded-br-none' : 'rounded-br'}`,
  main: 'relative',
}

const makeOnStateChange =
  <T,>({
    handleSelect,
    handleTextChange,
  }: Pick<TypeaheadProps<T>, 'handleSelect' | 'handleTextChange'>) =>
  ({ selectedItem, inputValue }: StateChangeOptions<T>) => {
    if (!isNil(inputValue) && isNil(selectedItem)) {
      handleTextChange(inputValue!)
    }
    if (!isNil(selectedItem)) {
      handleSelect(selectedItem!)
    }
  }

const Typeahead = forwardRef(
  <T extends WithKey>(
    {
      accent = 'green',
      className,
      disabled = false,
      dropdownCss,
      fetching = false,
      handleClear,
      handleSelect,
      handleTextChange,
      items,
      otherSuggestions,
      placeholder,
      portal,
      renderItem,
      text,
    }: TypeaheadProps<T>,
    ref: Ref<InputHandle>
  ) => {
    const target = usePortal(portal?.id ?? 'no-typeahead-portal')

    return (
      <Downshift
        itemToString={({ key }) => key}
        selectedItem={text}
        onStateChange={makeOnStateChange({ handleSelect, handleTextChange })}
      >
        {({ getInputProps, getItemProps, highlightedIndex, isOpen, openMenu }) => (
          <div className={`${styles.main} ${className ?? ''}`}>
            <SearchTextInput
              {...getInputProps({
                disabled,
                placeholder,
                value: text,
              })}
              accent={accent}
              data-testid="typeahead-input"
              fetching={fetching}
              handleOnFocus={() => !isEmpty(text) && openMenu()}
              onClear={text ? handleClear : undefined}
              ref={ref}
              tw={styles.input(isOpen, accent)}
            />
            {isOpen && (items.length || otherSuggestions) && (
              <ConditionalWrapper condition={Boolean(portal)} portal={target}>
                <div
                  className={cn(styles.dropdownContainer(accent), dropdownCss)}
                  style={portal ? { left: portal.x, top: portal.y + 42 } : undefined}
                >
                  <div>
                    {items.map((item, index) => (
                      <div
                        {...getItemProps({
                          item,
                          key: item.key,
                          style: {
                            backgroundColor:
                              index === highlightedIndex ? colors.paleGray : 'transparent',
                          },
                        })}
                      >
                        {renderItem(item)}
                      </div>
                    ))}
                  </div>
                  {otherSuggestions}
                </div>
              </ConditionalWrapper>
            )}
          </div>
        )}
      </Downshift>
    )
  }
) as <T extends WithKey>(
  props: TypeaheadProps<T> & { ref?: Ref<InputHandle> }
) => React.ReactElement

export { WithKey }
export default Typeahead
