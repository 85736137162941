import { DataSource } from '@juristat/common/types'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import Typeahead from '../../../components/Typeahead'
import { cn } from '../../../utils/cn'
import { type VariantProps, cva } from '../../../utils/cva'
import { useSearchVariables } from '../../search/hooks'
import { usePortalAndDirection } from '../../workflow-automation'
import { useOmnisearchResults } from '../hooks'
import { getLink } from './NavigationOmnisearch'

type State = { searching: boolean; text: string }

type Action =
  | { type: 'clear' }
  | { type: 'searching'; data: State['searching'] }
  | { type: 'type'; data: State['text'] }

const initialState: State = { searching: false, text: '' }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'searching':
      return { ...state, searching: action.data }
    case 'clear':
      return initialState
    case 'type':
      return { ...state, searching: true, text: action.data }
  }
}

function capitalizeWords(value: string, separator: string | RegExp = ' ') {
  return value
    .split(separator)
    .map(([letter, ...word]: string) => letter.toUpperCase().concat(...word))
    .join(' ')
}

const colors: Record<string, 'brand' | 'green' | 'orange' | 'pink'> = {
  APPLICATION: 'brand',
  ART_UNIT: 'brand',
  COMPANY: 'green',
  ATTORNEY: 'pink',
  CPC: 'green',
  EXAMINER: 'orange',
  FIRM: 'brand',
  USPC: 'pink',
}

const formatters: Record<string, (value: string) => string> = {
  application: (value) =>
    value.replace(/^(\d{7})$/, '0$1').replace(/^(\d{2})(\d{3})(\d{3})$/, '$1/$2,$3'),
  patent: (value) => value.replace(/^(\d+)(\d{6})$/, '$1,$2').replace(/^(.*)(\d{3})$/, '$1,$2'),
}

export function QuickSearch() {
  const [{ text }, dispatch] = React.useReducer(reducer, initialState)
  const { dataSource } = useSearchVariables()
  const [searchTerm] = useDebounce(text, 350)
  const isPrivatePair = dataSource === DataSource.PrivatePair
  const [ref, portal] = usePortalAndDirection('quick-search', 100, 1)

  const results = useOmnisearchResults({
    searchTerm,
    isPrivatePair,
  })

  const machines = Array.from(Object.values(results[0]).values())
  const fetching = machines.every((machine) => machine.matches('loading'))
  const data = machines
    .flatMap((machine) => machine.context.data ?? [])
    .filter((item) => !item.type.endsWith('EXPERT'))

  return (
    <div ref={ref}>
      <Typeahead
        accent="blue"
        className="[&_input]:h-10 [&_input]:!bg-white [&_input]:text-base [&_svg]:mt-0.5 [&_svg]:size-3.5"
        dropdownCss="z-[1010] w-96 rounded"
        fetching={fetching}
        handleSelect={(item) => {
          console.log(item)
        }}
        handleTextChange={(data) => dispatch({ type: 'type', data })}
        items={data}
        placeholder="Quick Search"
        portal={portal}
        renderItem={(item) => {
          const link = getLink(item)
          return (
            <div className="relative flex cursor-default select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm font-normal outline-none">
              <Link
                className="inline-flex w-full items-center gap-2 !text-gray-700"
                to={`/${link.to}`}
              >
                <div className="max-w-56 overflow-hidden text-ellipsis">
                  <span className="whitespace-nowrap">
                    {formatters[item.type]?.(link.value) ?? link.value.toUpperCase()}
                  </span>
                </div>
                <Pill variant={colors[item.type]}>
                  {capitalizeWords(item.type.toLowerCase(), '_').replace(/(s?pc)/, (match) =>
                    match.toUpperCase()
                  )}
                </Pill>
              </Link>
            </div>
          )
        }}
        text={text}
      />
    </div>
  )
}

const badgeVariants = cva(
  'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />
}

const variants = cva('pointer-events-none rounded-full px-2.5 text-sm font-medium shadow-none', {
  variants: {
    variant: {
      default: 'border-gray-300 bg-gray-50 text-gray-700',
      brand: 'border-brand-200 bg-brand-50 text-brand-700',
      error: 'bg-error-50 text-error-700 border-error-200',
      green: 'border-success-200 bg-success-50 text-success-700',
      orange: 'border-orange-200 bg-orange-50 text-orange-700',
      pink: 'border-pink-200 bg-pink-50 text-pink-700',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export function Pill({
  className,
  children,
  variant,
}: { className?: string; children: React.ReactNode } & VariantProps<typeof variants>) {
  return <Badge className={cn(variants({ className, variant }))}>{children}</Badge>
}
