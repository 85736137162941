import React from 'react'

import { useTitle } from '../hooks'
import { twTextStyles } from '../styles'
import LayoutContainer from './LayoutContainer'
import PageContainer from './PageContainer'

const styles = {
  header: `${twTextStyles.montserratDarkNormal36} h-11 mt-[42px] mr-0 mb-6 ml-0`,
  list: 'list-disc mb-4 pl-8',
  subheader: twTextStyles.darkNormal24,
}

export default function TermsOfUse() {
  const lastUpdated = 'January 30, 2024'

  useTitle('Terms of Use')

  return (
    <PageContainer>
      <LayoutContainer>
        <h1 className={styles.header}>Terms of Use</h1>
        <p>Last Updated: {lastUpdated}</p>
        <p>
          THESE TERMS OF USE BETWEEN YOU AND JURISTAT, INC. GOVERN YOUR USE AND ACCESS OF JURISTAT'S
          PRODUCTS, SOFTWARE, SERVICES, AND WEBSITE (COLLECTIVELY, THE "SERVICES"). THESE TERMS
          CONSTITUTE A BINDING AND ENFORCEABLE LEGAL AGREEMENT BETWEEN YOU AND JURISTAT. IT IS
          IMPORTANT THAT YOU READ AND UNDERSTAND THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO
          NOT USE THE SERVICES. BY ACCESSING OR OTHERWISE USING THE SERVICES, YOU ACKNOWLEDGE THAT
          YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS.
        </p>
        <h2 className={styles.subheader}>Intellectual Property</h2>
        <p>
          All technology, content, patents, trademarks, trade dress, copyrights, trade secrets, and
          other intellectual property is owned by or licensed to Juristat and protected by United
          States and international intellectual property laws and treaties. Except as expressly
          otherwise provided, Juristat and its licensors retain all proprietary rights, title, and
          interest in all such technology, content, and intellectual property.
        </p>
        <h2 className={styles.subheader}>Limited License</h2>
        <p>
          As a user of the Services, you are granted a non-exclusive, non-transferable,
          fully-revocable, limited license to access and use the Services. All use is expressly
          subject to and limited by these Terms of Use. Juristat reserves the right to terminate
          this license at any time and for any reason, without liability.
        </p>
        <h2 className={styles.subheader}>Usage Restrictions</h2>
        <p>By accessing or using the Services, you agree to the following restrictions:</p>
        <ul className={styles.list}>
          <li>You will not provide access to the Services to anyone under the age of 13.</li>
          <li>
            You will not use the Services in a manner contrary to or in violation of any applicable
            law, rule, or regulation having the force of law.
          </li>
          <li>
            You will not use the Services in a manner that impermissibly or tortiously violates, or
            interferes with, any other party's legal right or legal relationship.
          </li>
          <li>
            You acknowledge that Juristat is not a consumer reporting agency and that you will not
            use the Services as a factor in establishing a consumer's eligibility for credit or
            insurance to be used primarily for personal, family, or household purposes, for
            employment purposes, or for any other purpose authorized under section 1681b of the Fair
            Credit Reporting Act (15 U.S.C.A. § 1681b).
          </li>
          <li>
            You will not use the Services in any manner that could harm, infect, take over, disable,
            overburden, or otherwise impair any of the Services or any of Juristat's computer
            systems, including, but not limited to, the servers, networks, and other components
            connected to or used for the Services.
          </li>
          <li>You will not interfere with any other party's use or enjoyment of the Services.</li>
          <li>
            To the extent that you are able to upload, post, share, or otherwise transmit content or
            information using the Services, you will not upload, post, share, or otherwise transmit
            any content or information that you do not have a right to upload, post, share, or
            otherwise transmit under any applicable law or legal relationship.
          </li>
          <li>
            You will not upload, post, share, or otherwise transmit any material that contains
            software viruses or any other computer code, files, or programs designed to interrupt,
            destroy, or limit the functionality of any computer software or hardware or
            telecommunications equipment.
          </li>
          <li>
            You will not attempt to gain unauthorized access to any services, accounts, computer
            systems, or networks connected to any server used for the Services, through password
            mining, hacking, or any other means.
          </li>
          <li>
            You will not attempt to gain access to any materials or information owned or stored by
            Juristat through any means not intentionally made available by Juristat or attempt to
            circumvent any access or use restrictions put into place to prevent certain uses of the
            Services.
          </li>
          <li>
            You will not use the Services in a manner that constitutes an infringement of Juristat's
            rights or that has not been authorized by Juristat.
          </li>
          <li>
            Unless explicitly authorized by Juristat and any other owners of the materials, you will
            not modify, copy, reproduce, republish, upload, post, share, transmit, translate, sell,
            create derivative works, exploit, or distribute, in any manner or medium, any of the
            Services or any part thereof. You may, however, from time to time, download, print,
            and/or distribute (via email or other means) copies of individual pages of Juristat's
            Services for personal use, provided that you keep intact all proprietary notices.
          </li>
          <li>
            You will not collect content or information from, or otherwise access, the Services
            using any automated means (such as harvesting bots, robots, spiders, or scrapers)
            without Juristat's prior written permission.
          </li>
          <li>
            You will not use any framing techniques to enclose any portion of the Services
            (including, but not limited to, images, text, page layout, and form) without Juristat's
            prior written consent.
          </li>
          <li>
            You will not make any use of the trademarks, service marks, trade names, logos, or
            graphics on the Services without Juristat's prior written consent.
          </li>
          <li>
            You will not use any network monitoring or discovery software to determine the site
            architecture or extract information about usage, individual identities, or users.
          </li>
          <li>
            You will not use any robot, spider, other automatic software or device, or manual
            process to monitor or copy the Services without Juristat's prior written consent.
          </li>
          <li>
            You will not collect any email addresses made available on this site for purposes of
            promotions or marketing campaigns.
          </li>
          <li>
            You will not distribute, on or through the Services, any advertising; promotion;
            solicitation for goods, services, or funds; or solicitation for others to become members
            of any enterprise or organization without Juristat's prior written permission.
          </li>
          <li>
            You will not use or otherwise export or re-export the Services in violation of any
            applicable export control laws or regulations.
          </li>
        </ul>
        <h2 className={styles.subheader}>User Content</h2>
        <p>
          Certain sections of the Services allow you to input and store information regarding
          published patent applications, including but not limited to the numbers of applications
          and the identity of attorney(s), agent(s), assignee(s) and/or other parties associated
          with such applications ("Published Application Content"). You grant and assign to
          Juristat, a perpetual, irrevocable, unlimited, fully paid, fully sub-licensable, worldwide
          license to copy, perform, display, distribute, prepare derivative works from (including,
          without limitation, incorporating into other works), and otherwise use, any Published
          Application Content that you input and store, for any purpose whatsoever (including,
          without limitation, advertising, commercial, promotional, and publicity purposes) in
          Juristat's discretion without notice, approval, attribution, or consideration to you or to
          any other person or entity. You represent and warrant that you have all rights necessary
          to grant and assign the foregoing license, that neither the permission nor consent of any
          other person or entity is required to enable Juristat to legally use the Published
          Application Content in accordance with this license, that all Published Application
          Content provided to Juristat by you or anyone else using your account complies with all
          applicable laws, rules, and regulations, and that Juristat's use of the Published
          Application Content in accordance with this license will not violate or infringe upon the
          rights of any person or entity.
        </p>
        <h2 className={styles.subheader}>DISCLAIMER OF WARRANTIES</h2>
        <p>
          JURISTAT PROVIDES THE SERVICES "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTY OF ANY
          KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO: WARRANTIES OF PERFORMANCE,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, OMISSIONS, AND/OR
          COMPLETENESS; WARRANTIES THAT ANY PRODUCT, OR ACCESS THERETO, WILL BE UNINTERRUPTED,
          SECURE, COMPLETE, OR ERROR FREE; WARRANTIES AS TO THE LIFE OF ANY URL OR THIRD-PARTY WEB
          SERVICE; AND/OR WARRANTIES WITH REGARD TO ANY CONTENT OR SOFTWARE THAT HAS BEEN MODIFIED
          IN ANY WAY BY ANYONE OTHER THAN JURISTAT WITHOUT THE EXPRESS APPROVAL OF JURISTAT. YOU
          ACKNOWLEDGE THAT PROVISION OF CONTENT AND SOFTWARE ENTAILS THE LIKELIHOOD OF SOME HUMAN
          AND MACHINE ERRORS, DELAYS, INTERRUPTIONS, AND LOSSES, INCLUDING THE INADVERTENT LOSS OF
          DATA OR DAMAGE TO MEDIA; AND THE SERVICES REFERENCED HEREIN MAY NOT SATISFY REQUIREMENTS
          NOT EXPRESSLY STATED IN THIS AGREEMENT.
        </p>
        <h2 className={styles.subheader}>LIMITATION OF LIABILITY</h2>
        <p>
          IN NO EVENT WILL JURISTAT OR ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, AGENTS,
          LICENSORS, OR SUPPLIERS BE LIABLE, IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, FOR
          ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, INDIRECT, INCIDENTAL, SPECIAL,
          PUNITIVE, OR CONSEQUENTIAL, DAMAGES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH USE
          OF THE SERVICES, WHETHER OR NOT JURISTAT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGE; ANY CLAIM(S) RELATING IN ANY WAY TO YOUR INABILITY OR FAILURE TO PERFORM RESEARCH
          OR OTHER WORK, WHETHER PARTIALLY OR COMPLETELY, EVEN IF ASSISTED BY JURISTAT; OR ANY
          DECISION MADE OR ACTION TAKEN IN RELIANCE UPON THE AVAILABILITY OF OR CONTENT MADE
          AVAILABLE THROUGH THE SERVICES.
        </p>
        <h2 className={styles.subheader}>INDEMNIFICATION</h2>
        <p>
          YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS JURISTAT AND ITS OFFICERS, DIRECTORS,
          EMPLOYEES, AFFILIATES, AGENTS, LICENSORS, AND SUPPLIERS FROM AND AGAINST ALL CLAIMS,
          LOSSES, EXPENSES, DAMAGES, AND COSTS, INCLUDING REASONABLE ATTORNEYS' FEES, RESULTING FROM
          ANY VIOLATION OF THESE TERMS OF USE OR YOUR USE OF THE SERVICES.
        </p>
        <h2 className={styles.subheader}>No Legal Advice</h2>
        <p>
          You understand and agree that Juristat is not providing legal services, legal advice, or
          law-related services. No attorney-client relationship is formed by these Terms of Use or
          the Services. The accuracy, completeness, adequacy, or currency of the Services is not
          warranted or guaranteed. You use the Services at your own risk and based on your own
          judgment.
        </p>
        <h2 className={styles.subheader}>Links to Third Party Websites</h2>
        <p>
          The Services may provide links to other third-party websites or resources. Juristat makes
          no representations whatsoever about any other website which you may access through the
          Services. Because Juristat has no control over such sites and resources, you acknowledge
          and agree that Juristat is not responsible for the availability of such external sites or
          resources and is not responsible or liable for any content, advertising, products,
          services, or other materials on or available from such sites or resources.
        </p>
        <h2 className={styles.subheader}>Accounts and Registration</h2>
        <p>
          Certain sections of the Services may require you to register as a user or create an
          account. By registering or creating an account, you agree to provide accurate and complete
          information and to inform Juristat of any changes to that information. Each registration
          is for a single user only, unless otherwise expressly provided on the registration page.
          You are responsible for all use of your account and the Services accessed via your
          account, including but not limited to: (i) maintaining the confidentiality of your
          password and account; (ii) managing access to, and use of, your account; and (iii)
          ensuring that all use of your account and the Services complies with these Terms of Use.
          You will immediately notify Juristat if you become aware of any unauthorized use of your
          account.
        </p>
        <h2 className={styles.subheader}>Termination</h2>
        <p>
          You acknowledge and agree that Juristat, in its sole discretion, may terminate your access
          to the Services if your conduct is found to be unlawful, inconsistent with, or in
          violation of, the letter or spirit of these Terms of Use, or for any other reason, and
          that Juristat shall not be liable to you or any third party for termination of access.
          Should you object to any terms and conditions of these Terms of Use, or to any subsequent
          modifications thereto, your only recourse is to immediately discontinue use of the
          Services.
        </p>
        <h2 className={styles.subheader}>Governing Law and Jurisdiction</h2>
        <p>
          This Agreement and the terms herein are governed by, and construed in accordance with, the
          laws of Delaware without regard to choice of law principles.
        </p>
        <h2 className={styles.subheader}>Severability of Provisions</h2>
        <p>
          Should any portion of these Terms of Use be found or declared to be unenforceable or void
          for any reason, the remaining portions shall be severable, and fully enforceable as if no
          such finding of unenforceability had issued.
        </p>
        <h2 className={styles.subheader}>Modifications</h2>
        <p>
          Juristat may update these Terms of Use at any time, without notice to you. Juristat will
          post modifications to these terms on this page. You should check the terms regularly. By
          continue to use or access the Services, you agree to be bound by the terms in effect at
          the time of access.
        </p>
        <h2 className={styles.subheader}>Contact Information</h2>
        <p>
          If you have any questions about these Terms of Use, you may contact Juristat using any of
          the methods described on our contact page.
        </p>
      </LayoutContainer>
    </PageContainer>
  )
}
