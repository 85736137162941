import config from '@juristat/config'
import { stringify } from 'qs'
import { call, put, takeEvery } from 'redux-saga/effects'

import { api } from '../../../../api'
import routerActions from '../../../../router/actions'
import actions from '../actions'
import { AccountsUserResponse, UsersSearchAction } from '../types'
import mapAccountUserResponse from './utils/mapAccountUserResponse'

export function* searchUsers({ payload }: UsersSearchAction) {
  const { name } = payload!
  yield put(routerActions.push('/settings/account-tools/users'))

  const url = `${config.accountsUrl}/api/search/user?${stringify({ q: name })}`
  const options = {
    credentials: 'include',
    url,
  }
  try {
    const response = yield call(api, url, options)
    if (response.ok) {
      const result: AccountsUserResponse[] = yield call([response, 'json'])
      const usersSearchResultsPayload = result.map(mapAccountUserResponse)
      yield put(actions.set(usersSearchResultsPayload))
    } else {
      yield put(actions.error('Error fetching users'))
    }
  } catch {
    yield put(actions.error('Error fetching users'))
  }
}

function* watchSearchUsers() {
  yield takeEvery([actions.search().type], searchUsers)
}

export default watchSearchUsers
