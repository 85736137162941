import * as React from 'react'

import { JuristatLogo } from '../../modules/icons'

export const FooterItem = ({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) => <li className={`ml-7 inline text-gray-700 ${className}`}>{children}</li>

function FooterLink({ text, to }: { text: string | React.ReactNode; to: string }) {
  return (
    <FooterItem>
      <a
        className="transition-color text-sm font-semibold text-gray-600 underline duration-100 visited:text-gray-600 hover:text-gray-600"
        href={to}
        rel="noreferrer"
        target="_blank"
      >
        {text}
      </a>
    </FooterItem>
  )
}

const Footer = () => {
  return (
    <footer className="mb-10 mt-5 flex items-center justify-between pr-10 pt-8">
      <a className="ml-8 p-0" href="https://juristat.com/home" rel="noreferrer" target="_blank">
        <JuristatLogo color="#667085" color2="#059669" height={24} width={112} />
      </a>
      <ul className="!-ml-7 flex items-center">
        <FooterLink text="Privacy Policy" to="/privacy" />
        <FooterLink text="Terms of Use" to="/tou" />
        <FooterLink text="Terms of Sale" to="/tos" />
        <FooterLink text="Contact" to="https://juristat.com/contact" />
      </ul>
      <div className="mr-4 text-base font-normal text-gray-500">
        © 2014–
        {new Date().getFullYear()} Juristat, Inc.
      </div>
    </footer>
  )
}

export default Footer
