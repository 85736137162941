import * as React from 'react'

import { IconProps } from '../../modules/icons'
import Checkbox from '../Checkbox'

type ItemSelectorProps = {
  description?: string
  icons?: Array<(props: IconProps & { key?: string }) => JSX.Element>
  key?: string
  label: string
  renderLabel?: (text: string) => JSX.Element
  selected: boolean
  handleClick: () => void
}

class ItemsSelector extends React.Component<ItemSelectorProps> {
  shouldComponentUpdate(nextProps: ItemSelectorProps) {
    return nextProps.selected !== this.props.selected
  }

  render() {
    const {
      description,
      handleClick,
      icons = [],
      selected,
      label,
      renderLabel = (text: string) => <div className="text-sm text-gray-700">{text}</div>,
    } = this.props

    return (
      <div
        className="cursor-pointer [&_p]:p-0"
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          handleClick()
        }}
      >
        <div className="mb-1 flex flex-[1px] items-center justify-between">
          <Checkbox accent="blue" checked={selected} label={label} renderLabel={renderLabel} />
          <div className="fill-charcoal ml-1 flex shrink-0 items-center">
            {icons.map((Icon, index) => (
              <Icon key={String(index)} width={12} height={12} />
            ))}
          </div>
        </div>
        {description && (
          <p className="ml-6 pl-[30px] text-sm font-light text-gray-600">{description}</p>
        )}
      </div>
    )
  }
}

export default ItemsSelector
