import * as React from 'react'

import SVG from './SVG'
import { IconProps } from './types'

const Spy = (props: IconProps) => (
  <SVG title="spy" fill="currentColor" viewBox="0 0 512 512" {...props}>
    <g>
      <path
        d="M442.619,244.5c-3.803-18.517-12.774-59.068-26.094-100.166c-10.629-32.801-21.885-59.178-33.452-78.397
          C365.727,37.114,346.188,22.5,325,22.5c-17.027,0-38.038,10.19-64.178,31.124C245.934,41.974,216.751,22.5,188,22.5
          c-35.477,0-64.713,38.855-92.009,122.281C82.695,185.418,73.616,225.52,69.627,244.5H0v40h512v-40H442.619z M110.557,244.5
          c4.439-20.155,12.512-53.847,23.452-87.281C158.522,82.296,180.045,62.5,188,62.5c12.546,0,29.388,9.078,42.391,18.304
          c-2.267,2.24-3.614,3.633-3.787,3.813l-13.883,14.397l28.793,27.766l13.883-14.397c0.127-0.131,12.84-13.273,28.957-26.354
          C312.531,63.16,323.807,62.5,325,62.5c7.922,0,29.271,19.613,53.369,93.844c10.996,33.87,19.04,68.141,23.356,88.156H110.557z"
      />
      <path
        d="M359,313.5c-30.595,0-57.588,15.698-73.362,39.458l-33.528-16.764l-35.411,17.274
          C200.974,329.422,173.813,313.5,143,313.5c-48.523,0-88,39.477-88,88s39.477,88,88,88s88-39.477,88-88
          c0-3.454-0.207-6.861-0.596-10.213l21.486-10.481l19.773,9.887c-0.436,3.543-0.664,7.149-0.664,10.807c0,48.523,39.477,88,88,88
          s88-39.477,88-88S407.523,313.5,359,313.5z M143,449.5c-26.467,0-48-21.533-48-48c0-26.467,21.533-48,48-48s48,21.533,48,48
          C191,427.967,169.467,449.5,143,449.5z M359,449.5c-26.467,0-48-21.533-48-48c0-26.467,21.533-48,48-48c26.467,0,48,21.533,48,48
          C407,427.967,385.467,449.5,359,449.5z"
      />
    </g>
  </SVG>
)

export default Spy
