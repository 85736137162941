import Downshift, { ControllerStateAndHelpers, DownshiftProps } from 'downshift'
import { __, contains, flatten, values } from 'ramda'
import React, { useCallback, useState } from 'react'
import { animated, config, useSpring, useTransition } from 'react-spring'

import { useFuzzySort, usePortal } from '../hooks'
import { Chevron, Direction } from '../modules/icons/v5/chevron'
import { buttonStyles, twTextStyles } from '../styles'
import { cn } from '../utils/cn'
import Button from './Button'
import ConditionalWrapper from './ConditionalWrapper'
import SearchTextInput from './SearchTextInput'

type MenuProps<T> = {
  align?: 'left' | 'right'
  buttonCss?: string
  children?: (
    props: Pick<
      ControllerStateAndHelpers<T>,
      'getItemProps' | 'highlightedIndex' | 'isOpen' | 'toggleMenu'
    > &
      Option<T> & {
        className: string
        index: number
      }
  ) => JSX.Element
  direction?: 'down' | 'up'
  disabled?: boolean
  dropdownCss?: string
  startsWith?: boolean
  handleClick: (value: T) => void
  heightAdjustment?: number
  maxHeight?: number
  nested?: boolean
  optionCss?: string
  options: Options<T> | Array<Option<T>>
  portal?: { id: string; x: number; y: number }
  searchable?: boolean | string
  title: React.ReactNode | RenderTitle
  variant?: 'normal' | 'outline' | 'solid'
  width?: string
} & Omit<DownshiftProps<T>, 'children'>

type Option<T> = {
  disabled?: boolean
  hidden?: boolean
  label: string
  value: T
}

type Options<T> = {
  [key: string]: Array<Option<T>>
}

type RenderTitle = (open: boolean) => React.ReactNode

type Render<T> = (
  props: Pick<
    ControllerStateAndHelpers<T>,
    'getItemProps' | 'highlightedIndex' | 'isOpen' | 'toggleMenu'
  > & {
    className: string
    options: Array<Option<T>>
    parentIndex?: number
  }
) => JSX.Element[]

const styles = {
  button: `font-normal text-s text-gray-700 disabled:opacity-60 flex items-center rounded`,
  container: 'relative',
  menu: ({
    align,
    direction,
    nested,
    variant,
    width,
  }: Required<Pick<MenuProps<unknown>, 'align' | 'direction' | 'nested' | 'variant' | 'width'>>) =>
    `bg-white rounded-lg shadow-[0_4px_10px_0_rgba(48,_55,_65,_0.3)] ${
      direction === 'up' ? 'bottom-0' : 'bottom-[inherit]'
    } ${align === 'left' ? (nested ? 'left-[40px]' : 'left-0') : ''} py-[10px] px-0 absolute ${
      align === 'right' ? (nested ? 'right-[40px]' : 'right-0') : ''
    } ${
      nested
        ? direction === 'up'
          ? 'top-[inherit]'
          : 'top-0'
        : variant !== 'normal'
        ? 'top-[40px]'
        : 'top-[20px]'
    } ${width} z-[1000]`,
  option: `${twTextStyles.darkNormal13} block [&:hover:not(:disabled)]:bg-cloudy-blue-alpha-20 disabled:!text-silver cursor-pointer text-left justify-start py-[6px] px-[14px] transition-[background-color] duration-300 ease-in-out w-full`,
  optionHeader: twTextStyles.charcoalGrayBold12,
  outline: 'h-[inherit] py-[7px] px-[10px]',
  row: 'py-[4px] px-[14px]',
  search: 'mt-0 mb-[10px] mx-[10px]',
  section: '[&:not(:last-child)]:mb-5',
  solid: buttonStyles.tailWindPrimaryBlue,
}

const hasTitles = (options: unknown): options is Options<unknown> => !Array.isArray(options)

const Menu = <T,>({
  align = 'right',
  buttonCss = '',
  children,
  direction = 'down',
  disabled = false,
  dropdownCss = '',
  handleClick,
  heightAdjustment = 0,
  maxHeight = Infinity,
  nested = false,
  optionCss = '',
  options,
  portal,
  searchable = false,
  startsWith = false,
  title,
  variant = 'normal',
  width = 'w-[250px]',
  ...props
}: MenuProps<T>) => {
  const [downshiftOpen, setDownshiftOpen] = useState(props.defaultIsOpen ?? false)
  const [needle, setNeedle] = useState('')
  const [portalY, setPortalY] = useState(0)
  const transition = useTransition(downshiftOpen, {
    config: config.stiff,
    enter: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: `translateY(${direction === 'down' ? '-16px' : '16px'})` },
    leave: { opacity: 0, transform: `translateY(${direction === 'down' ? '-16px' : '16px'})` },
  })

  const target = usePortal(portal?.id ?? 'no-menu-portal')

  const haystack = (
    hasTitles(options) ? (flatten(values(options)) as Array<Option<T>>) : options
  ).filter((option) => !option.hidden)
  const results = useFuzzySort({
    startsWith,
    haystack,
    keys: ['label'],
    needle,
  })
  const sections = hasTitles(options) ? Object.keys(options).length : 0
  const height =
    results.length > 10
      ? 350
      : Math.min(
          maxHeight,
          [
            results.length * 30, // available options
            20, // menu padding
            searchable ? 46 : 0, // if we have search input, include it
            sections * 25, // include height of each section title
            sections !== 0 ? (sections - 1) * 20 : 0, // include margin between each section
          ].reduce((acc, value) => acc + value, 0)
        )

  const heightProps = useSpring({
    to: [{ overflowY: 'hidden' }, { height: height + heightAdjustment }, { overflowY: 'auto' }],
  })

  const render: Render<T> = useCallback(
    ({ className, getItemProps, highlightedIndex, isOpen, options, parentIndex = 0, toggleMenu }) =>
      options.filter(contains(__, results)).map(
        ({ disabled, label, value }, index) =>
          children?.({
            className,
            disabled,
            getItemProps,
            highlightedIndex,
            index: parseInt(`${parentIndex}${index}`),
            isOpen,
            label,
            toggleMenu,
            value,
          }) ?? (
            <Button
              {...getItemProps({
                disabled,
                key: JSON.stringify(value),
                item: value,
                index,
                onClick: () => {
                  handleClick(value)
                  toggleMenu()
                },
              })}
              tw={className}
            >
              {label}
            </Button>
          )
      ),
    [children, handleClick, results]
  )

  return (
    <Downshift
      stateReducer={(_, changes) => {
        if (changes.isOpen !== undefined) {
          setDownshiftOpen(changes.isOpen)
        }

        return changes
      }}
      {...props}
    >
      {({
        getItemProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        toggleMenu,
      }: ControllerStateAndHelpers<T>) => (
        <div className={cn(styles.container, dropdownCss)}>
          <Button
            disabled={disabled}
            onClick={(event) => {
              if (portal) {
                setPortalY(event.clientY)
              }

              toggleMenu()
            }}
            tw={`${styles.button} ${
              variant === 'outline' ? styles.outline : variant === 'solid' ? styles.solid : ''
            } ${buttonCss}`}
          >
            {typeof title === 'function' ? title(downshiftOpen) : title}
            {typeof title === 'string' && (
              <Chevron
                className="stroke-charcoal-gray ml-[11px] h-[12px] w-[12px] !fill-none"
                direction={isOpen ? Direction.Up : Direction.Down}
                title={`${downshiftOpen ? 'Close' : 'Open'} menu`}
              />
            )}
          </Button>
          {transition(
            (props, item, { key }) =>
              item && (
                <ConditionalWrapper condition={Boolean(portal)} portal={target}>
                  <animated.div
                    {...getMenuProps({
                      className: styles.menu({ align, direction, nested, variant, width }),
                    })}
                    key={key}
                    style={{
                      ...props,
                      ...heightProps,
                      ...(portal ? { x: portal.x, y: portalY } : {}),
                    }}
                  >
                    {searchable ? (
                      <SearchTextInput
                        accent="blue"
                        handleOnTextChange={setNeedle}
                        placeholder={[
                          'Search',
                          typeof searchable === 'string' ? searchable : 'Dashboards',
                        ].join(' ')}
                        onClear={needle ? () => setNeedle('') : undefined}
                        text={needle}
                        tw={styles.search}
                      />
                    ) : null}
                    {hasTitles(options)
                      ? Object.keys(options).map((section, parentIndex) =>
                          options[section].length > 0 ? (
                            <div className={styles.section} key={section}>
                              <h3 className={`section ${styles.row} ${styles.optionHeader}`}>
                                {section}
                              </h3>
                              {render({
                                className: cn(styles.option, optionCss),
                                getItemProps,
                                highlightedIndex,
                                isOpen,
                                options: options[section],
                                parentIndex,
                                toggleMenu,
                              })}
                            </div>
                          ) : null
                        )
                      : render({
                          className: `${styles.option} ${optionCss}`,
                          getItemProps,
                          highlightedIndex,
                          isOpen,
                          options,
                          toggleMenu,
                        })}
                  </animated.div>
                </ConditionalWrapper>
              )
          )}
        </div>
      )}
    </Downshift>
  )
}

export default Menu
