import {
  Column,
  DataSource,
  OmnisearchType,
  OrderDirection,
  SearchView,
} from '@juristat/common/types'
import { formatDate } from '@juristat/common/utils'
import { endOfYear, startOfYear, subYears } from 'date-fns'
import { getResponsiveStyle, useGrid } from 'muuri-react'
import { equals, isEmpty } from 'ramda'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useQueryStringParam } from '../../hooks/useQueryStringParam'
import { useSyncToQueryString } from '../../hooks/useSyncToQueryString'
import { useUpdateEffect } from '../../hooks/useUpdateEffect'
import { colors } from '../../styles'
import { FetchTypeState, useApp, useAppMutation, useMergedQueries, useQuery } from '../api'
import filterActions from '../filter/actions'
import FilterContext from '../filter/context/filterContext'
import { ActiveReducer, ApplicationStatusEnum } from '../filter/types'
import { useEntityName } from '../intelligence/hooks'
import { getIntrinsicFilters } from '../intelligence/utils'
import { useNotification } from '../notification/hooks'
import searchActions from '../search/actions'
import { useSearchVariables } from '../search/hooks'
import * as getSearchUid from '../search/queries/getSearchUid.graphql'
import * as getUidDefinition from '../search/queries/getUidDefinition.graphql'
import { useHasAccess } from '../session/hooks'
import { getGroupId } from '../session/selectors'
import { Access } from '../session/types'
import {
  DashboardDataSourceContext,
  DashboardItemsContext,
  DashboardUpdateItemsContext,
  IsChartsLibraryContext,
} from './contexts'
import * as getNormalizedAllowanceRate from './queries/getNormalizedAllowanceRate.graphql'
import * as getNormalizedOfficeActions from './queries/getNormalizedOfficeActions.graphql'
import * as getPendingApplicationDistributionQuery from './queries/getPendingApplicationDistribution.graphql'
import * as getProsecutionVolume from './queries/getProsecutionVolume.graphql'
import * as getRoiMetrics from './queries/getRoiMetrics.graphql'
import { GroupMetaResponse, LegacyDashboard, RoiMetrics, RoiMetricsResponse } from './types'
import { getEntityFromType, mergeFilters } from './utils'

type UseDashboardActionOptions<T> = {
  failure: string
  onSuccess?: (data: T) => void
  refetchQueries: string[]
  success: string
}

type UseSetReportOptions = Partial<{
  pending: boolean
  view: SearchView
}>

export function useDashboardAction<T>(
  dashboard: string | undefined,
  method: 'delete' | 'patch' | 'post' | 'put',
  { failure, onSuccess, refetchQueries, success }: UseDashboardActionOptions<T>
) {
  const { addSuccessNotification, addErrorNotification } = useNotification()
  const [action, state] = useAppMutation<T>(
    `/dashboards${dashboard ? `/${dashboard}` : ''}`,
    method,
    { refetchQueries }
  )

  useEffect(() => {
    if (state.matches('success')) {
      addSuccessNotification(`Dashboard ${success} successfully`)

      return onSuccess?.(state.context.data)
    }

    if (state.matches('failure')) {
      addErrorNotification(`Failed to ${failure} dashboard`)
    }
  }, [state.value])

  return [action] as const
}

export function useDashboardDataSource() {
  const context = useContext(DashboardDataSourceContext)

  return context
}

export function useDashboardItemsItems() {
  const context = useContext(DashboardItemsContext)

  if (context === undefined) {
    throw new Error('useDashboardItemsItems must be used within a DashboardItemsContextProvider')
  }

  return context
}

export function useDashboardUpdateItems() {
  const context = useContext(DashboardUpdateItemsContext)

  if (context === undefined) {
    throw new Error('useDashboardUpdateItems must be used within a DashboardItemsContextProvider')
  }

  return context
}

export function useDashboardItems() {
  return [useDashboardItemsItems(), useDashboardUpdateItems()] as const
}

export function useDashboardPpair() {
  return useDashboardDataSource() === DataSource.PrivatePair
}

export function useIsChartsLibrary() {
  const context = useContext(IsChartsLibraryContext)

  return context !== undefined
}

export function useIsFreeHealthDashboardUser() {
  return useHasAccess(Access.FreeHealthDashboard)
}

export function useCanExportAnalytics() {
  return useHasAccess(Access.ExportAnalytics)
}

export function useIsProsecutionHealthDashboard() {
  const { dashboard } = useParams<{ dashboard: string }>()

  return dashboard === 'prosecution-health'
}

export function useEntityFilter() {
  const entityId = useEntityId()

  const { entity, filter } = useMemo(() => {
    const [id, type] = entityId.context.data ?? [0, null]
    const entity = getEntityFromType(type)
    const filter = getIntrinsicFilters({ entity, id })

    return { entity, filter }
  }, [entityId])

  return [entityId, filter, entity] as const
}

export function useEntityId() {
  const groupId = useSelector(getGroupId)
  const [entityId] = useApp<[number, OmnisearchType | null], GroupMetaResponse>(
    'entity-id',
    `/group-meta/${groupId}`,
    { transform: (data) => [Number(data.entityId), data.entityType] }
  )

  return entityId
}

type UseResponsiveStyleProps = {
  columns:
    | {
        smaller?: number
        standard: number
      }
    | number
  height: number
  margin?: string
}

export function useResponsiveStyle({ height, margin = '10px', ...props }: UseResponsiveStyleProps) {
  const { grid } = useGrid()
  const { smaller, standard } =
    typeof props.columns === 'number'
      ? { smaller: undefined, standard: props.columns }
      : props.columns
  const columns = (grid as any)._width > 768 ? standard : smaller ?? standard

  return getResponsiveStyle({
    // The Muuri component is virtually divided into 8 columns,
    // the width of the item will be 3 columns (minus the margin).
    columns,
    // The margin of the item, it can be any CSS values
    // valid for the margin expressed in "px" or "%".
    margin,
    // The width/height ratio. If you want to set a static
    // height just set the "height" option in px and remove the "ratio".
    height,
  })
}

type AllowanceRateMetric = {
  metrics: Array<{
    allowanceRate: number | null
    examinerAllowanceRate: {
      average: number | null
    } | null
    buckets: [{ name: 'DISPOSITION_DATE'; value: string }]
  }>
}

type NormalizedAllowanceRateResponse = {
  entity: AllowanceRateMetric
  uspto: AllowanceRateMetric
}

type LineChartProps = {
  data: Array<{ x: number; y: number }>
  id: string
}

type BarChartProps = {
  id: string
  value: number
}

type NormalizedValue<ChartProps extends BarChartProps | LineChartProps = LineChartProps> = {
  chart: ChartProps[]
  normalized: number
}

export function useNormalizedAllowanceRate() {
  const dateFilter = {
    dispositionDate: { start: formatDate(subYears(new Date(), 5)), end: formatDate(new Date()) },
  }
  const [entityId, filter, entity] = useEntityFilter()
  const [entityName] = useEntityName({ entity, id: entityId.context.data?.[0] ?? 0 })
  const [rate] = useQuery<NormalizedValue, NormalizedAllowanceRateResponse>(
    ['normalized-allowance-rates', entityName.context.data],
    getNormalizedAllowanceRate,
    {
      enabled: entityId.matches('success') && entityName.context.data !== undefined,
      transform: (data) => {
        const years = data.entity.metrics.map((metric, index) => {
          const entity = metric.allowanceRate ?? 0
          const [{ value: year }] = metric.buckets
          const examiner = metric.examinerAllowanceRate?.average ?? 0
          const uspto = data.uspto.metrics[index].examinerAllowanceRate?.average ?? 0

          const normalized = entity + (entity - examiner) + (uspto - examiner)

          return { year, normalized, uspto, examiner, entity }
        })

        return years.reduce<NormalizedValue>(
          (acc, value, _, arr) => {
            const x = Number(value.year)
            const normalized =
              (arr.reduce((acc, item) => acc + item.normalized, 0) / arr.length) * 100

            const entity = {
              id: entityName.context.data ?? 'ENTITY',
              data: [...(acc.chart[0]?.data ?? []), { x, y: value.entity }].sort(
                (left, right) => left.x - right.x
              ),
            }

            const examiner = {
              id: 'EXAMINER AVERAGE',
              data: [...(acc.chart[1]?.data ?? []), { x, y: value.examiner }].sort(
                (left, right) => left.x - right.x
              ),
            }

            const uspto = {
              id: 'USPTO',
              data: [...(acc.chart[2]?.data ?? []), { x, y: value.uspto }].sort(
                (left, right) => left.x - right.x
              ),
            }

            return { chart: [entity, examiner, uspto], normalized }
          },
          { chart: [], normalized: 0 }
        )
      },
      variables: { dateFilter, entityFilter: { ...dateFilter, ...filter } },
    }
  )

  return rate
}

type OfficeActionMetric = {
  metrics: Array<{
    officeActions: {
      toAllowance: {
        average: number | null
      } | null
    } | null
    examinerOasToNoa: {
      average: number | null
    } | null
    buckets: [{ name: 'DISPOSITION_DATE'; value: string }]
  }>
}

type NormalizedOfficeActionResponse = {
  entity: OfficeActionMetric
  uspto: OfficeActionMetric
}

export function useNormalizedOfficeActions() {
  const dateFilter = {
    dispositionDate: { start: formatDate(subYears(new Date(), 5)), end: formatDate(new Date()) },
  }
  const [entityId, filter, entity] = useEntityFilter()
  const [entityName] = useEntityName({ entity, id: entityId.context.data?.[0] ?? 0 })
  const [rate] = useQuery<NormalizedValue, NormalizedOfficeActionResponse>(
    ['normalized-office-actions', entityName.context.data],
    getNormalizedOfficeActions,
    {
      enabled: entityId.matches('success') && entityName.context.data !== undefined,
      transform: (data) => {
        const years = data.entity.metrics.map((metric, index) => {
          const entity = metric.officeActions?.toAllowance?.average ?? 0
          const [{ value: year }] = metric.buckets
          const examiner = metric.examinerOasToNoa?.average ?? 0
          const uspto = data.uspto.metrics[index].examinerOasToNoa?.average ?? 0

          const normalized = -50 * (entity - examiner) + 50

          return { year, normalized, uspto, examiner, entity }
        })

        return years.reduce<NormalizedValue>(
          (acc, value, _, arr) => {
            const x = Number(value.year)
            const normalized = arr.reduce((acc, item) => acc + item.normalized, 0) / arr.length

            const entity = {
              id: entityName.context.data ?? 'ENTITY',
              data: [...(acc.chart[0]?.data ?? []), { x, y: value.entity }].sort(
                (left, right) => left.x - right.x
              ),
            }

            const examiner = {
              id: 'EXAMINER AVERAGE',
              data: [...(acc.chart[1]?.data ?? []), { x, y: value.examiner }].sort(
                (left, right) => left.x - right.x
              ),
            }

            const uspto = {
              id: 'USPTO',
              data: [...(acc.chart[2]?.data ?? []), { x, y: value.uspto }].sort(
                (left, right) => left.x - right.x
              ),
            }

            return { chart: [entity, examiner, uspto], normalized }
          },
          { chart: [], normalized: 0 }
        )
      },
      variables: { dateFilter, entityFilter: { ...dateFilter, ...filter } },
    }
  )

  return rate
}

type ProsecutionVolumeMetric = {
  metrics: Array<{
    applicationCounts: {
      total: number | null
    } | null
    buckets: [{ name: 'FILING_DATE'; value: string }]
  }>
}

type ProsecutionVolumeResponse = {
  applicationSet: ProsecutionVolumeMetric
}

export function useProsecutionVolume() {
  const dateFilter = {
    filingDate: {
      start: formatDate(startOfYear(subYears(new Date(), 7))),
      end: formatDate(endOfYear(subYears(new Date(), 2))),
    },
  }
  const [entityId, filter, entity] = useEntityFilter()
  const [entityName] = useEntityName({ entity, id: entityId.context.data?.[0] ?? 0 })
  const [rate] = useQuery<NormalizedValue<BarChartProps>, ProsecutionVolumeResponse>(
    ['prosecution-volume', entityName.context.data],
    getProsecutionVolume,
    {
      enabled: entityId.matches('success') && entityName.context.data !== undefined,
      transform: (data) => {
        const years = data.applicationSet.metrics
          .sort((left, right) => Number(left.buckets[0].value) - Number(right.buckets[0].value))
          .map((metric, index, array) => {
            // Skip first entry since we are doing a year over year calculation.
            if (index === 0) {
              return null
            }

            const previous = array[index - 1].applicationCounts?.total ?? 0
            const entity = metric.applicationCounts?.total ?? 0
            const change = (entity - previous) / previous
            const [{ value: year }] = metric.buckets

            const normalized = 250 * change + 50

            return { year, normalized, entity, change }
          })
          .filter((item): item is NonNullable<typeof item> => Boolean(item))

        return years.reduce<NormalizedValue<BarChartProps>>(
          (acc, value, _, arr) => {
            const normalized = arr.reduce((acc, item) => acc + item.normalized, 0) / arr.length

            return { chart: [...acc.chart, { id: value.year, value: value.entity }], normalized }
          },
          { chart: [], normalized: 0 }
        )
      },
      variables: { dateFilter, entityFilter: { ...dateFilter, ...filter } },
    }
  )

  return rate
}

type PendingApplicationDistributionMetric = {
  applicationCounts: {
    pending: number | null
  } | null
  buckets: Array<{ name: 'CURRENT_ASSIGNEE' | 'FILING_DATE'; value: string }>
}

type PendingApplicationDistribution = {
  assignees: Record<
    string,
    Array<{
      pending: number
      year: number
    }>
  >
  entity: Array<{ pending: number; year: number }>
}

type PendingApplicationDistributionResponse = {
  applicationSet: {
    entity: PendingApplicationDistributionMetric[]
    metrics: PendingApplicationDistributionMetric[]
  }
}

export function getPendingApplicationDistribution(
  rate: FetchTypeState<PendingApplicationDistribution[], PendingApplicationDistributionResponse[]>,
  entityName: string
) {
  if (!rate.matches('success')) {
    return { chart: [], normalized: 0 }
  }

  const { assignees, total, totals } = rate.context.data.reduce<{
    assignees: Record<string, { total: number } & Record<string, number>>
    total: number
    totals: Record<string, number>
  }>(
    (acc, { assignees }) => {
      Object.entries(assignees).forEach(([assignee, values]) => {
        if (!acc.assignees[assignee]) {
          acc.assignees[assignee] = { total: 0 }
        }

        values.forEach(({ year, pending }) => {
          if (!acc.assignees[assignee][year]) {
            acc.assignees[assignee][year] = 0
          }

          if (!acc.totals[year]) {
            acc.totals[year] = 0
          }

          acc.assignees[assignee][year] += pending
          acc.assignees[assignee].total += pending
          acc.totals[year] += pending
          acc.total += pending
        })
      })

      return acc
    },
    { assignees: {}, total: 0, totals: {} }
  )

  const cutoff = Object.keys(assignees).length >= 20 ? 0.1 : 0.2
  const threshold = Object.entries(assignees).filter(([, value]) => value.total / total >= cutoff)

  const entity = {
    id: entityName,
    data: Object.entries(totals).map(([year, y]) => ({ x: Number(year), y })),
  }

  const data = threshold.map(([id, values], index) => {
    const map = new Map(Object.entries(values).filter(([key]) => key !== 'total'))

    return {
      id,
      color: colors.chartColors[index + 1],
      data: entity.data.map(({ x }) => ({ x, y: map.get(`${x}`) ?? 0 })),
    }
  })

  const chart = [
    ...data,
    {
      id: entity.id,
      color: colors.chartColors[0],
      data: entity.data.map(({ x, y }) => ({
        x,
        y: y - data.reduce((acc, { id }) => acc + (assignees[id][x] ?? 0), 0),
      })),
    },
  ]

  const subtractions =
    threshold.length > 0
      ? threshold
      : Object.entries(assignees)
          .sort(([, left], [, right]) => right.total - left.total)
          .slice(0, 1)

  const normalized =
    100 -
    subtractions.reduce((acc, [, values]) => acc + Math.round((values.total / total) * 100), 0)

  return { chart, normalized }
}

export function usePendingApplicationDistribution() {
  const dateFilters = useMemo(() => {
    const now = new Date()

    return [0, 1, 2, 3, 4].map((value) => ({
      filingDate: {
        start: formatDate(subYears(now, value + 1)),
        end: formatDate(subYears(now, value)),
      },
    }))
  }, [])

  const [entityId, , entity] = useEntityFilter()
  const [entityName] = useEntityName({ entity, id: entityId.context.data?.[0] ?? 0 })
  const [rate] = useMergedQueries<
    PendingApplicationDistribution,
    PendingApplicationDistributionResponse
  >(
    ['pending-application-distribution', entityId.context?.data?.[0]],
    getPendingApplicationDistributionQuery,
    dateFilters.map((dateFilter) => ({
      transform: (data) => {
        const entity = data.applicationSet.entity
          .sort((left, right) => Number(left.buckets[0].value) - Number(right.buckets[0].value))
          .map(({ applicationCounts, buckets: [bucket] }) => ({
            year: Number(bucket.value),
            pending: applicationCounts?.pending ?? 0,
          }))

        const assignees = data.applicationSet.metrics
          .filter(({ applicationCounts }) => applicationCounts?.pending ?? 0 > 0)
          .reduce<Record<string, Array<{ year: number; pending: number }>>>(
            (acc, { applicationCounts, buckets }) => {
              const assignee =
                buckets.find((bucket) => bucket.name === 'CURRENT_ASSIGNEE')?.value ?? ''
              const date = buckets.find((bucket) => bucket.name === 'FILING_DATE')?.value ?? ''

              if (!assignee || !date) {
                return acc
              }

              if (!acc[assignee]) {
                acc[assignee] = []
              }

              acc[assignee].push({ year: Number(date), pending: applicationCounts?.pending ?? 0 })
              acc[assignee].sort((left, right) => left.year - right.year)

              return acc
            },
            {}
          )

        return { assignees, entity }
      },
      variables: { filters: { ...dateFilter, firmAtDisposition: entityId.context.data?.[0] } },
    })),
    { enabled: entityId.matches('success') }
  )

  const result = useMemo(
    () => getPendingApplicationDistribution(rate, entityName.context.data ?? 'Unknown'),
    [entityName.context.data, rate]
  )

  return [rate, result] as const
}

export function useRoiMetrics() {
  const { filters } = useSearchVariables()
  const [entityId, filter] = useEntityFilter()
  const ppair = useDashboardPpair()
  const [metrics] = useQuery<RoiMetrics, RoiMetricsResponse>('roi-metrics', getRoiMetrics, {
    enabled: entityId.matches('success') || !ppair,
    ppair,
    transform: ({
      applicationSet: {
        metrics: [
          {
            appeals = { unnecessary: 0 },
            applicationCounts: { missedContinuation = 0, missedInterview = 0 } = {},
            officeActions = { unnecessary: 0 },
          } = {},
        ],
      },
    }) => ({
      missedContinuation,
      missedInterview,
      unnecessaryAppeals: appeals.unnecessary,
      unnecessaryOfficeActions: officeActions.unnecessary,
    }),
    variables: {
      filters: ppair ? mergeFilters(filters, filter) : filters,
    },
  })

  return metrics
}

export function useSetReport({ pending = true, view }: UseSetReportOptions = {}) {
  const dispatch = useDispatch()
  const [, entityFilter] = useEntityFilter()
  const dataSource = useDashboardDataSource()
  const ppair = useDashboardPpair()
  const isProsecutionHealthDashboard = useIsProsecutionHealthDashboard()
  const { filters } = useSearchVariables()

  const setReport = useCallback(
    (filter: Partial<ActiveReducer>) => {
      dispatch(
        searchActions.setReport({
          dataSource,
          filters: mergeFilters(
            filters,
            {
              ...(pending ? { applicationStatus: [ApplicationStatusEnum.Pending] } : {}),
              ...(ppair || isProsecutionHealthDashboard ? entityFilter : {}),
              ...filter,
            },
            1
          ),
          view,
        })
      )
    },
    [dataSource, dispatch, entityFilter, filters, pending, ppair, view]
  )

  return setReport
}

const defaultSearchVariables = {
  dataSource: DataSource.PublicPair,
  filters: {},
  searches: { fullText: '' },
  similarTo: {},
  sortOrders: [
    {
      orderingDirection: OrderDirection.Descending,
      orderingType: Column.PublicationDate,
    },
  ],
}

export function useSyncFilters(dashboardItem: FetchTypeState<LegacyDashboard, LegacyDashboard>) {
  const { dashboard } = useParams<{ dashboard?: string }>()
  const { meta } = useContext(FilterContext)
  const isMounted = useRef(false)
  const [, filter] = useEntityFilter()

  const dispatch = useDispatch()
  const searchVariables = useSearchVariables()

  const [definition, definitionActions] = useQuery<
    { filters?: WeakObject; orderings?: WeakObject[] },
    { uid: { definition: string } }
  >('dashboard-uid-definition', getUidDefinition, {
    enabled: false,
    transform: ({ uid: { definition } }: { uid: { definition: string } }) =>
      definition === '{}' || !definition ? {} : JSON.parse(definition),
  })

  const initialUid = useQueryStringParam<string>('uid')
  const [uid, setUid] = useState<string | undefined>(() => {
    if (initialUid) {
      definitionActions.refetch({ variables: { uid: initialUid } })
    }

    return initialUid
  })

  useSyncToQueryString('uid', uid)

  const [searchUid, { refetch }] = useQuery<string, { applicationSet: { uid: string } }>(
    'dashboard-filters-uids',
    getSearchUid,
    {
      enabled: !equals(searchVariables as unknown, defaultSearchVariables as unknown),
      transform: ({ applicationSet: { uid } }) => uid,
      variables: searchVariables,
    }
  )

  useEffect(() => {
    if (searchUid.matches('success')) {
      setUid(searchUid.context.data)
    }
  }, [searchUid])

  const dataSource = dashboardItem.context.data?.dataSource ?? DataSource.PublicPair

  useUpdateEffect(() => {
    definitionActions.reset()
    dispatch(filterActions.hydrate({}, meta))
    setUid(undefined)
  }, [dashboard, definitionActions.reset, dispatch, meta])

  useEffect(() => {
    dispatch(filterActions.getAvailable({ dataSource, filters: searchVariables.filters }, meta))
  }, [dataSource, dispatch, meta, refetch, searchVariables])

  useEffect(() => {
    const { filters: dashboardFilters } = dashboardItem.context.data ?? {}
    const { filters: definitionFilters } = definition.context.data ?? {}
    const filters = definitionFilters ?? dashboardFilters

    if (!isEmpty(filters ?? {})) {
      dispatch(filterActions.hydrate(filters, meta))
    }
  }, [dashboardItem.context.data, definition, dispatch, meta])

  useEffect(() => {
    if (!isMounted.current && !isEmpty(searchVariables.filters)) {
      dispatch(filterActions.hydrate(searchVariables.filters, meta))
    }

    isMounted.current = true
  }, [dispatch, meta, searchVariables.filters])

  // Handle Firm Metrics
  useEffect(() => {
    if (dashboard !== '4e2de346-2d57-4468-83c3-fdff4f327550') {
      return
    }

    const [value] = filter.currentFirm ?? filter.currentAssignee ?? ['0']

    if (Number(value) > 0) {
      dispatch(filterActions.setSome(filter as { currentFirm: string[] }, meta))
    }
  }, [dashboard, dispatch, filter, meta])
}
