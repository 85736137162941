import * as React from 'react'
import * as ReactDOM from 'react-dom'

type ModalBackgroundProps = Partial<{
  children: React.ReactNode
  closeModal: () => void
  onClose: () => void
}>

class ModalBackground extends React.Component<ModalBackgroundProps> {
  closeOnEscape = (event: KeyboardEvent) => {
    const { closeModal } = this.props
    if (event.key === 'Escape' && typeof closeModal === 'function') {
      closeModal()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeOnEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeOnEscape, false)

    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const { children, closeModal } = this.props

    return ReactDOM.createPortal(
      <div
        className="fixed left-0 top-0 z-[101] flex h-full w-full items-start justify-center overflow-y-auto"
        data-testid="modal-background"
        onMouseDown={() => closeModal?.()}
      >
        <div
          className="z-[102] mb-[70px] mt-[200px] shadow-[0_8px_20px_0_rgba(48,_55,_65,_0.3)]"
          onMouseDown={(event) => event.stopPropagation()}
        >
          {children}
        </div>
      </div>,
      document.querySelector('body') as HTMLBodyElement
    )
  }
}

export default ModalBackground
