import { SearchScope, SearchView } from '@juristat/common/types'
import { all, contains, without } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import { AppState } from '../../../redux'
import { buttonStyles, twTextStyles } from '../../../styles'
import { getFilterMeta } from '../../filter/selectors'
import searchActions from '../../search/actions'
import { getSearchDataSource, getSearchScopes, getSearchType } from '../../search/selectors'
import getActiveFilters from '../../search/selectors/getActiveFilters'
import { SearchType } from '../../search/types'
import { RenderProps } from './Omnisearch'

type OmnisearchByKeywordProps = Omit<RenderProps, 'input'> & {
  setDisplayType?: React.Dispatch<React.SetStateAction<'modal' | 'page'>>
}

const styles = {
  button: `${buttonStyles.tailWindGray} font-semibold !border !border-solid !border-gray-200`,
  container: 'items-center flex flex-col justify-start pb-[30px] w-full',
  header: `${twTextStyles.darkBold16} pb-5`,
  helpLink: `${twTextStyles.linkBlueBold12} self-start`,
  noBorder: 'border-none',
}

const searchScopes = [
  { label: 'Full Text', value: SearchScope.FullText },
  { label: 'All Fields', value: SearchScope.AllFields },
  { label: 'Abstract', value: SearchScope.Abstract },
  { label: 'Appeals', value: SearchScope.Appeals },
  { label: 'Petitions', value: SearchScope.Petitions },
  { label: 'Claims', value: SearchScope.Claims },
  { label: 'Description', value: SearchScope.Description },
  { label: 'Rejections', value: SearchScope.Rejections },
  { label: 'Title', value: SearchScope.Title },
]

const scopesSupportingSimilarTo = [
  SearchScope.Abstract,
  SearchScope.Claims,
  SearchScope.Description,
]

export default function OmnisearchByKeyword({
  displayType,
  searchTerm: phrase,
  setDisplayType,
  setSearchOpen,
}: OmnisearchByKeywordProps) {
  const dispatch = useDispatch()
  const stateScopes = useSelector(getSearchScopes)
  const stateType = useSelector(getSearchType)
  const dataSource = useSelector(getSearchDataSource)

  const [scopes, setScopes] = useState(stateScopes)
  const [type, setType] = useState(stateType)

  const canSearchSimilar: boolean =
    scopes.length > 0
      ? all((scope: SearchScope) => contains(scope, scopesSupportingSimilarTo), scopes)
      : false

  useEffect(() => {
    if (!canSearchSimilar) {
      setType(SearchType.Keyword)
    }
  }, [canSearchSimilar, scopes])

  const filterMeta = useSelector(getFilterMeta)
  const filters = useSelector((state: AppState) => getActiveFilters(state, filterMeta))

  const setReport = useCallback(() => {
    setSearchOpen && setSearchOpen(false)
    setDisplayType && setDisplayType('modal')
    dispatch(
      searchActions.setReport({
        dataSource,
        filters,
        phrase,
        scopes,
        type,
        view: SearchView.Table,
      })
    )
  }, [dataSource, dispatch, filters, phrase, scopes, setDisplayType, setSearchOpen, type])

  return (
    <div className={styles.container}>
      <div className="mb-2 flex w-[192px] items-center gap-1 rounded-xl border border-gray-200 bg-white">
        <Button
          onClick={() => {
            setType(SearchType.Keyword)
          }}
          tw={`text-sm font-semibold w-[94px] h-8 m-1 px-2 justify-center items-center gap-2 rounded-[6px] ${
            type === SearchType.Keyword ? 'bg-[#F2F4F7]' : 'bg-none'
          }`}
        >
          Keyword
        </Button>
        <Button
          disabled={!canSearchSimilar}
          onClick={() => {
            setType(SearchType.SimilarTo)
          }}
          title={
            'Select a scope of Abstract, Claims, or Description to perform a similarity search'
          }
          tw={`disabled:cursor-not-allowed text-sm font-semibold w-[94px] h-8 m-1 px-2 justify-center items-center gap-2 rounded-[6px] ${
            type === SearchType.SimilarTo ? 'bg-[#F2F4F7]' : 'bg-none'
          }`}
        >
          Similarity
        </Button>
      </div>
      <div>
        <div className={`${twTextStyles.darkNormal16} flex max-h-[150px] flex-wrap justify-center`}>
          {searchScopes.map(({ label, value }) => (
            <Checkbox
              key={value}
              accent="blue"
              checked={contains(value, scopes)}
              className="mb-2 mr-2 rounded-lg border border-gray-200 p-2 [&_>_div]:!border-gray-300"
              handleClick={() =>
                setScopes((state) =>
                  contains(value, state) ? without([value], state) : [...state, value]
                )
              }
              label={label}
            />
          ))}
        </div>
      </div>
      <div
        className={`my-7 flex w-full ${
          displayType === 'modal' ? '-mr-12 justify-end' : 'justify-center'
        }`}
      >
        {setSearchOpen && displayType === 'modal' ? (
          <Button onClick={() => setSearchOpen(false)} tw={`${styles.button} rounded-lg`}>
            Cancel
          </Button>
        ) : null}
        <Button
          handleClick={setReport}
          tw={`rounded-lg w-[100px] ml-4 ${
            displayType === 'modal' ? buttonStyles.tailWindBlueWithIcon : styles.button
          } ${phrase ? '!bg-brand-600 !text-white' : ''}`}
        >
          Search
        </Button>
      </div>
    </div>
  )
}
