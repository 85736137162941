import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import Downshift, { DownshiftInterface } from 'downshift'
import { any, filter, splitEvery } from 'ramda'
import React, { useState } from 'react'

import { Chevron, Direction } from '../modules/icons/v5/chevron'
import Button from './Button'

type DatePickerCalendarProps = {
  date: Date | null
  dateFormat?: string
  isDayDisabled?: (value: Date) => boolean
  isOpen: boolean
  onChange: (value: Date | null) => void
}

const styles = {
  container: 'relative',
  day: `font-inter !text-gray-700 disabled:opacity-50 [&:hover:enabled]:bg-charcoal-gray2-alpha-10 rounded-[50%] h-7 m-[2px] relative w-7`,
  dayCurrent: '[&:hover:enabled]:bg-gray-200 bg-gray-200 border-none rounded-[50%]',
  dayNotInMonth: 'invisible',
  dayToday: 'hover:bg-blue-600 bg-blue-600 font-semibold rounded-[50%] !text-white',
  dropdown:
    'bg-white rounded shadow-[0_4px_20px_0_rgba(48,_55,_65,_0.3)] flex-[1] overflow-hidden p-5 absolute z-[200]',
  leftRightButton:
    'disabled:opacity-50 [&:hover:enabled]:border [&:hover:enabled]:border-gray-300 [&:hover:enabled]:shadow-[0_2px_8px_0_rgba(48,_55,_65,_0.1)] bg-white rounded fill-charcoal h-7 w-7',
  title: 'font-inter font-semibold !text-gray-700 items-center flex font-bold justify-between',
  weekday: `font-inter font-semibold text-gray-700 items-center flex h-7 justify-center m-[2px] w-7`,
  weeks: 'flex text-[80%]',
}

const getWeeks = (date: Date): Date[][] => {
  const start = startOfWeek(startOfMonth(date))

  return filter(
    (week: Date[]) => any((day: Date) => isSameMonth(date, day), week),
    splitEvery(
      7,
      Array.from({ length: 42 }).map((_, i) => addDays(start, i))
    )
  )
}

const getWeekdays = (date: Date) =>
  Array.from({ length: 7 }).map((_, i) => format(addDays(startOfWeek(date), i), 'eeeeee'))

const TypedDownshift: DownshiftInterface<Date | null> = Downshift

const DatePickerCalendar = ({
  date,
  dateFormat = 'MM/dd/yyyy',
  isDayDisabled,
  isOpen,
  onChange,
}: DatePickerCalendarProps) => {
  const [baseDate, setBaseDate] = useState(date ?? new Date())
  const weeks = getWeeks(baseDate)
  const today = new Date()
  const weekdays = getWeekdays(baseDate)

  return (
    <TypedDownshift
      isOpen={isOpen}
      itemToString={(item) => (item ? format(item, dateFormat) : '')}
      onChange={onChange}
      selectedItem={date}
    >
      {({ getItemProps, getMenuProps, selectedItem }) => (
        <div>
          <div {...getMenuProps({ className: styles.dropdown })}>
            <h2 className={styles.title}>
              <Button
                active={!isDayDisabled?.(startOfMonth(baseDate))}
                handleClick={() => setBaseDate((value) => addMonths(value, -1))}
                tw={styles.leftRightButton}
              >
                <Chevron direction={Direction.Left} title="Previous" />
              </Button>
              {format(baseDate, 'MMMM yyyy')}
              <Button
                active={!isDayDisabled?.(endOfMonth(baseDate))}
                handleClick={() => setBaseDate((value) => addMonths(value, 1))}
                tw={styles.leftRightButton}
              >
                <Chevron direction={Direction.Right} title="Next" />
              </Button>
            </h2>
            <div className={styles.weeks}>
              {weekdays.map((weekday) => (
                <div key={weekday} className={styles.weekday}>
                  {weekday}
                </div>
              ))}
            </div>
            {weeks.map((week, index) => (
              <div key={index} className={styles.weeks}>
                {week.map((day) => {
                  const isToday = isSameDay(day, today)
                  const isInMonth = isSameMonth(baseDate, day)
                  const isCurrent = selectedItem ? isSameDay(day, selectedItem) : false
                  const isDisabled = isDayDisabled?.(day) ?? false

                  return (
                    <Button
                      key={day.toString()}
                      {...getItemProps({
                        className: `${styles.day} ${isToday ? styles.dayToday : ''} ${
                          !isInMonth ? styles.dayNotInMonth : ''
                        } ${isCurrent ? styles.dayCurrent : ''}`,
                        item: day,
                      })}
                      active={!isDisabled}
                      tw={`${styles.day} ${isToday ? styles.dayToday : ''} ${
                        !isInMonth ? styles.dayNotInMonth : ''
                      } ${isCurrent ? styles.dayCurrent : ''}`}
                    >
                      {isInMonth ? format(day, 'dd') : null}
                    </Button>
                  )
                })}
              </div>
            ))}
          </div>
        </div>
      )}
    </TypedDownshift>
  )
}

export default DatePickerCalendar
