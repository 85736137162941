import { CSSObject } from 'create-emotion'

import { azure } from './colors'
import { azureBold13, whiteBold13 } from './text'

const blueOutlineWithIcon: CSSObject = {
  ...azureBold13,
  '& > svg': {
    fill: 'currentColor',
    marginLeft: 12,
  },
  '&:disabled': {
    opacity: 0.6,
  },
  alignItems: 'center',
  border: `1px solid ${azure}`,
  borderRadius: 4,
  color: azure,
  display: 'flex',
  height: 36,
  justifyContent: 'space-between',
  padding: '0 20px',
  textTransform: 'uppercase',
}

const primaryBlue: CSSObject = {
  ...whiteBold13,
  '& > svg': {
    fill: 'currentColor',
    marginLeft: 12,
  },
  '&:disabled': {
    opacity: 0.6,
  },
  backgroundColor: azure,
  borderRadius: 4,
  height: 32,
  padding: '0 20px',
  textTransform: 'uppercase',
}

const tailWindButton: string =
  '[&::-moz-focus-inner]:border-0 disabled:cursor-not-allowed items-center bg-none cursor-pointer flex justify-center outline-none padding-0 select-none text-s font-inter font-bold'

const tailWindBlueOutlineWithIcon: string = `${tailWindButton} [&_>_svg]:fill-current [&_>_svg]:ml-3 disabled:opacity-60 border border-brand-600 text-brand-600 flex justify-between rounded-lg h-8 py-0 px-5 uppercase`
const tailWindPrimaryBlue: string = `${tailWindButton} [&_>_svg]:fill-current disabled:opacity-60 bg-brand-600 text-white rounded-lg h-8 py-0 px-5 uppercase`
const tailWindGray = `${tailWindButton} items-center bg-pale-gray border border-gray-200 flex justify-center text-center font-inter text-s rounded min-w-[95px] py-[7px] px-5 !text-[#303741]`
const tailWindGreen = `${tailWindButton} items-center bg-apple-green border-none flex justify-center text-center font-inter text-s font-bold rounded min-w-[95px] py-[7px] px-5 text-pale-gray2`
const tailWindBlueWithIcon: string = `disabled:cursor-not-allowed disabled:opacity-60 hover:opacity-70 [&_>_svg]:fill-current gap-[6px] rounded-lg flex justify-center items-center font-inter font-semibold bg-brand-600 text-white text-sm !h-[38px] transition-opacity ease-in-out duration-300 whitespace-nowrap`
const tailWindBlackOutlineWithIcon: string = `hover:opacity-70 [&_>_svg]:fill-current gap-[6px] bg-white border border-gray-300 rounded-lg flex justify-center items-center font-inter font-semibold text-gray-700 text-sm py-0 px-4 h-[38px] transition-opacity ease-in-out duration-300 whitespace-nowrap`

export {
  blueOutlineWithIcon,
  primaryBlue,
  tailWindBlueWithIcon,
  tailWindButton,
  tailWindBlackOutlineWithIcon,
  tailWindBlueOutlineWithIcon,
  tailWindGray,
  tailWindGreen,
  tailWindPrimaryBlue,
}
