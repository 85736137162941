import * as React from 'react'

type TextAreaProps = Partial<{
  className: string
  handleTextChange: (text: string) => void
  name: string
  placeholder: string
  text: string
  tw?: boolean
}> &
  Pick<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onKeyDown' | 'onPaste'>

const twstyles = {
  main: 'placeholder:text-gray-500 placeholder:text-xs placeholder:font-stretch-normal placeholder:not-italic placeholder:font-normal placeholder:tracking-normal focus:text-gray-900 focus:text-xs focus:font-stretch-normal focus:not-italic focus:font-normal focus:tracking-normal disabled:cursor-not-allowed disabled:opacity-50 focus:bg-white bg-gray-50 border border-gray-300 rounded-lg h-[144px] outline-[0] py-[6px] px-[11px] resize-none w-full',
}

const TextArea = ({ className, handleTextChange, text, ...props }: TextAreaProps) => (
  <textarea
    {...props}
    className={`${twstyles.main} ${className ?? ''}`}
    onChange={(event) => handleTextChange?.(event.target.value)}
    value={text}
  />
)

export default TextArea
