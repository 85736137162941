import { startCase } from '@juristat/common/utils'
import { css } from 'emotion'
import { contains, find, isEmpty, pipe, propEq, symmetricDifference, without } from 'ramda'
import React, { useEffect, useState } from 'react'

import Button from '../../../components/Button'
import Checkbox from '../../../components/Checkbox'
import ConnectedLink from '../../../components/ConnectedLink'
import InfoText from '../../../components/InfoText'
import ItemSelector from '../../../components/ItemPicker/ItemsSelector'
import Modal, { ModalSize, ModalWithTrigger } from '../../../components/Modal'
import ScrollableOnHover from '../../../components/ScrollableOnHover'
import SearchTextInput from '../../../components/SearchTextInput'
import { TableFetchingLoader } from '../../../components/StaticTable'
import { pills } from '../../../styles'
import { buttonStyles, colors, textStyles, zIndex } from '../../../styles'
import { HttpStatus } from '../../http/types'
import { PopOut } from '../../icons'
import { Access } from '../../session/types'
import { useFilterState, useFilterTypeahead } from '../hooks'
import { Filter, FilterCount } from '../types'

type RawNameFilter =
  | Filter.AssigneeAtDisposition
  | Filter.AssigneeAtDispositionName
  | Filter.CurrentAssignee
  | Filter.CurrentAssigneeName
  | Filter.CurrentFirm
  | Filter.CurrentFirmName
  | Filter.FirmAtDisposition
  | Filter.FirmAtDispositionName

type RawNamesModalProps = {
  classNames?: Partial<{ modal: string; trigger: string }>
  triggerButtonLabel?: string
  filter: Filter
  type: 'assignee' | 'firm'
}

type ItemProps = FilterCount & {
  action: (value: number) => void
  externalUrl: string
  selected: number[]
}

const styles = {
  fetching: (width: number) =>
    css({
      marginBottom: 10,
      width: `${width}%`,
    }),
  info: (offset = 0) =>
    css({
      color: colors.silver2,
      marginTop: 140 - offset,
    }),
  link: css({
    fill: colors.azure,
    marginLeft: 'auto',
  }),
  message: css(textStyles.darkNormal13, {
    '& > a': textStyles.linkBlueNormal13,
  }),
  modalContainer: css({
    borderRadius: 12,
    boxShadow: `0 4px 20px 0 ${colors.charcoalGray2alpha30}`,
    height: 580,
    left: 350,
    position: 'fixed',
    top: 70,
    zIndex: zIndex.modal,
  }),
  remainingSpace: 'border-t border-gray-300 basis-1 grow mt-2 relative',
  result: css({
    '& > :not(:last-child)': {
      marginBottom: 12,
    },
  }),
  scrollContainer: 'h-full p-[10px] absolute w-full',
}

const formatNumber = Intl.NumberFormat('en-US', { maximumFractionDigits: 2 })

function Item({ action, apps, externalUrl, id, name, selected }: ItemProps) {
  return (
    <div className="flex [&_>_div_>_div_>_label]:mb-1.5 [&_>_div_>_div_>_label]:items-start">
      <ItemSelector
        handleClick={() => action(id)}
        label={name}
        renderLabel={() => (
          <div>
            <b>({formatNumber.format(apps)})</b> {name}
          </div>
        )}
        selected={contains(id, selected)}
      />
      <ConnectedLink
        appendQueryString={false}
        canAccess={Access.Platform}
        className="ml-auto fill-gray-500"
        to={`${externalUrl}/${id}`}
        tw={true}
      >
        <PopOut title="View entity page" />
      </ConnectedLink>
    </div>
  )
}

export default function RawNamesModal({
  classNames,
  triggerButtonLabel,
  type,
  ...props
}: RawNamesModalProps) {
  const [checked, setChecked] = useState(false)
  const [entityType, setEntityType] = useState(type)
  const firmFilter = contains(props.filter, [Filter.CurrentFirmName, Filter.FirmAtDispositionName])
    ? props.filter
    : props.filter === Filter.FirmAtDisposition
    ? Filter.FirmAtDispositionName
    : Filter.CurrentFirmName
  const assigneeFilter = contains(props.filter, [
    Filter.AssigneeAtDispositionName,
    Filter.CurrentAssigneeName,
  ])
    ? props.filter
    : props.filter === Filter.AssigneeAtDisposition
    ? Filter.AssigneeAtDispositionName
    : Filter.CurrentAssigneeName
  const filter = entityType === 'assignee' ? assigneeFilter : firmFilter
  const { meta, ...state } = useFilterState(filter)
  const { debouncedSearchTerm, disabled, searchTerm, setSearchTerm, typeahead } =
    useFilterTypeahead(filter as RawNameFilter)

  const [selected, setSelected] = useState(state.selected as number[])
  const [selectedNotShown, setSelectedNotShown] = useState<FilterCount[]>([])

  const externalUrl = props.filter.includes('at')
    ? `/${entityType}-at-disp`
    : `/current-${entityType}`

  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      setSelectedNotShown(
        (
          (typeahead.matches('success')
            ? [...selectedNotShown, ...typeahead.context.data]
            : state.available) as FilterCount[]
        ).filter(({ id }) => contains(id, selected))
      )
    }
  }, [debouncedSearchTerm, filter])

  useEffect(() => {
    setSelected(state.selected as number[])
  }, [state.selected])

  useEffect(() => {
    if (state.content.type === HttpStatus.Success) {
      setSelectedNotShown((items) => [
        ...items,
        ...selected
          .filter((id) => !find(propEq('id', id), items))
          .map((id) => {
            const result = (state.available as FilterCount[]).find(propEq('id', id))

            if (typeahead.matches('success')) {
              return result ?? (typeahead.context.data as FilterCount[]).find(propEq('id', id))!
            }

            return result!
          }),
      ])
    }
  }, [selected])

  const action = (value: number) => {
    setSelected((items) => {
      const exists: boolean = contains(value, items)

      if (exists) {
        setSelectedNotShown((state) => state.filter(({ id }) => id !== value))
      }

      return exists ? without([value], items) : [...items, value]
    })
  }

  const reset = () => {
    setSelected(state.selected as number[])
    setSelectedNotShown([])
    setSearchTerm(null)
  }

  return (
    <ModalWithTrigger
      modal={(closeModal) => {
        const closeAndReset = pipe(closeModal, reset)

        return (
          <div className={css(styles.modalContainer, classNames?.modal)}>
            <Modal
              closeModal={closeAndReset}
              primaryButtonProps={{
                action: () => {
                  const search = new URLSearchParams([
                    ['f', filter],
                    ['v', selected.join(',')],
                  ]).toString()

                  window.location.href = `/search?${search}`

                  return
                },
                className: buttonStyles.tailWindBlueWithIcon,
                disabled: isEmpty(symmetricDifference(selected, state.selected as number[])),
                text: 'Apply',
              }}
              secondaryButtonProps={{
                action: closeAndReset,
                className: `${buttonStyles.tailWindGray} !border !border-solid !border-gray-200`,
                text: 'Cancel',
              }}
              tertiaryButtonProps={{
                action: () => {
                  setSelected([])
                },
                className: 'text-brand-blue-600 !font-bold',
                disabled: isEmpty(selected),
                text: (
                  <div className="flex">
                    <span className="text-brand-blue-600 font-bold">Clear All</span>
                    <span className={`${pills.getPillClassesByColor('blue')} ml-3 w-auto min-w-3`}>
                      {selected.length}
                    </span>
                  </div>
                ),
              }}
              size={ModalSize.Long}
              title={`Search Raw Names`}
            >
              <div className="mb-4 flex text-sm">
                <div
                  className={`text-s mr-3 h-7 w-9 cursor-pointer ${
                    entityType === 'firm'
                      ? 'border-b-2 border-blue-600 font-bold text-gray-700'
                      : 'text-gray-600'
                  }`}
                  onClick={() => setEntityType('firm')}
                >
                  Firms
                </div>
                <div
                  className={`w-29 text-s h-7 cursor-pointer ${
                    entityType === 'assignee'
                      ? 'border-b-2 border-blue-600 font-bold text-gray-700'
                      : 'text-gray-600'
                  }`}
                  onClick={() => setEntityType('assignee')}
                >
                  Assignees
                </div>
              </div>
              <SearchTextInput
                disabled={disabled}
                handleOnTextChange={setSearchTerm}
                placeholder={`Search for ${startCase(entityType)} Names...`}
                text={searchTerm ?? ''}
                type="search"
                tw="mb-2 bg-white"
              />
              <p className={styles.message}>
                Search unedited {entityType} names as they are found in the USPTO. For more
                information on this feature,{' '}
                <a
                  className="!font-bold"
                  href="https://help.juristat.com/en/articles/3291161-raw-names-filter-tutorial"
                  rel="noreferrer"
                  target="_blank"
                >
                  visit the raw names filter tutorial
                </a>
                .
              </p>
              <Checkbox
                accent="blue"
                checked={checked}
                className="!text-s ml-2 mt-2"
                classNameChecked=""
                handleClick={() => {
                  const select = !checked
                  setChecked(select)
                  if (select) {
                    const values = (
                      typeahead.matches('idle')
                        ? state.available
                        : typeahead.matches('success')
                        ? [...selectedNotShown, ...typeahead.context.data]
                        : []
                    ) as FilterCount[]

                    setSelected(values.map(({ id }) => id))
                  } else {
                    setSelected([])
                  }
                }}
                label="Select All"
              />
              <div className={styles.remainingSpace}>
                <ScrollableOnHover className={styles.scrollContainer}>
                  {typeahead.matches('idle') ? (
                    (state.available as FilterCount[]).map(({ apps, id, name }) => (
                      <Item
                        key={`${id}-${apps}-${name}`}
                        {...{ action, apps, externalUrl, id, name, selected }}
                      />
                    ))
                  ) : typeahead.matches('loading') ? (
                    Array(Math.floor(Math.random() * 5) + 5)
                      .fill(0)
                      .map((_, idx) => (
                        <TableFetchingLoader
                          className={styles.fetching(Math.floor(Math.random() * 50) + 41)}
                          key={String(idx)}
                        />
                      ))
                  ) : typeahead.matches('failure') ? (
                    <InfoText className={styles.info()}>{typeahead.context.error}</InfoText>
                  ) : typeahead.matches('success') ? (
                    ((data) => {
                      const filteredSelected = selectedNotShown.filter(
                        ({ id }) => !find(propEq('id', id), data)
                      )

                      return (
                        <>
                          {filteredSelected.map(({ apps, id, name }) => (
                            <Item
                              key={`${id}-${apps}-${name}`}
                              {...{ action, apps, externalUrl, id, name, selected }}
                            />
                          ))}
                          {isEmpty(data) && (
                            <InfoText className={styles.info(filteredSelected.length * 20)}>
                              No results found
                            </InfoText>
                          )}
                          {data.map(({ apps, id, name }) => (
                            <Item
                              key={`${id}-${apps}-${name}`}
                              {...{ action, apps, externalUrl, id, name, selected }}
                            />
                          ))}
                        </>
                      )
                    })(typeahead.context.data as FilterCount[])
                  ) : null}
                </ScrollableOnHover>
              </div>
            </Modal>
          </div>
        )
      }}
      trigger={(openModal) => (
        <Button
          onClick={openModal}
          tw="mt-2 flex items-center gap-1 fill-[#2E90FA] text-xs font-bold text-[#2E90FA]"
        >
          {triggerButtonLabel ? (
            triggerButtonLabel
          ) : (
            <>
              Search Raw Names <PopOut height={12} title="" width={12} />
            </>
          )}
        </Button>
      )}
    />
  )
}
