import { CSSObject } from 'create-emotion'
import { injectGlobal } from 'emotion'

import { colors, textStyles } from '.'

type Font = {
  fontFamily: string
  fontWeight: number
  filename: string
}

const formats = ['woff2', 'woff']

const fonts: Font[] = [
  { fontFamily: 'Inter', fontWeight: 400, filename: 'Inter-Regular' },
  { fontFamily: 'Inter', fontWeight: 600, filename: 'Inter-SemiBold' },
  { fontFamily: 'Inter', fontWeight: 700, filename: 'Inter-Bold' },
  { fontFamily: 'Open Sans', fontWeight: 400, filename: 'open-sans-latin-400' },
  { fontFamily: 'Open Sans', fontWeight: 600, filename: 'open-sans-latin-600' },
  { fontFamily: 'Open Sans', fontWeight: 700, filename: 'open-sans-latin-700' },
  { fontFamily: 'Montserrat', fontWeight: 400, filename: 'montserrat-latin-400' },
  { fontFamily: 'Montserrat', fontWeight: 600, filename: 'montserrat-latin-600' },
  { fontFamily: 'Montserrat', fontWeight: 700, filename: 'montserrat-latin-700' },
  { fontFamily: 'Roboto', fontWeight: 400, filename: 'roboto-latin-400' },
]

const fontFaceSrc = (filename: string) =>
  formats
    .map((format: string): string => `url('/fonts/${filename}.${format}') format('${format}')`)
    .join(',')

const createFontFace = ({ fontFamily, fontWeight, filename }: Font): CSSObject => ({
  '@font-face': {
    fontFamily,
    fontStyle: 'normal',
    fontWeight,
    src: fontFaceSrc(filename),
    textRendering: 'optimizeLegibility',
  },
})

const globalStyles: CSSObject[] = [
  ...fonts.map(createFontFace),
  {
    '*, *:after, *:before': {
      boxSizing: 'border-box',
    },
    a: {
      textDecoration: 'none',
    },
    body: {
      backgroundImage: `linear-gradient(297deg, ${colors.paleGray}, ${colors.white} 100%)`,
      color: colors.charcoalGray,
      fontFamily: 'Inter',
      width: '100%',
    },
    button: {
      margin: 0,
    },
    'h1, h2, h3, h4': {
      fontSize: '100%',
      fontWeight: 'normal',
    },
    html: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      WebkitOverflowScrolling: 'touch',
      boxSizing: 'inherit',
      lineHeight: 1.45,
      overflowX: 'hidden',
      textRendering: 'optimizeLegibility',
    },
    'html, body, h1, h2, h3, h4, ul, c': {
      margin: 0,
      padding: 0,
    },
    'input, textarea': {
      '&::-ms-clear': {
        display: 'none',
      },
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0,
      },
      '&::-webkit-search-cancel-button': {
        display: 'none',
      },
      '&::placeholder': {
        ...textStyles.placeholderNormal12,
        opacity: 1,
      },
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.6,
      },
      '&:focus': {
        outline: 'none',
      },
      MozAppearance: 'textfield',
      color: colors.charcoalGray,
      outline: 0,
    },
    'ol, ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
  },
]

injectGlobal(globalStyles)
