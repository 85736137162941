import * as React from 'react'

import SVG from './SVG'
import type { IconProps } from './types'

export const CollapseMenu = (props: IconProps) => (
  <SVG height="24" width="24" title="collapse-menu" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M21 9.92263L18.9231 11.9995L21 14.0765M3 11.9995H15.4615M3 7.8457H15.4615M3 16.1534H15.4615"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)
