import { isNilOrEmpty } from '@juristat/common/utils'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { useHasAccess } from '../../session/hooks'
import { Access } from '../../session/types'

type AttributeValueProps = {
  canAccess?: Access
  to?: string
  value: number | string | null
}

const AttributeValueText = ({ value }: AttributeValueProps) => (
  <b
    className="w-48 max-w-max overflow-hidden text-ellipsis whitespace-nowrap text-right text-xs font-semibold"
    title={String(value)}
  >
    {isNilOrEmpty(value) ? 'N/A' : value}
  </b>
)

const AttributeValue = ({ canAccess, to, value }: AttributeValueProps) => {
  const hasAccess = useHasAccess(canAccess)
  const shouldLink = useMemo(
    () =>
      to !== undefined &&
      !/\/(null|undefined)(\/?|$)/.test(to) &&
      value !== null &&
      (canAccess === undefined || hasAccess),
    [canAccess, hasAccess, to, value]
  )

  return shouldLink ? (
    <Link
      className="text-brand-700 visited:text-brand-700 hover:text-brand-700 max-w-[220px] cursor-pointer overflow-hidden text-ellipsis text-sm font-normal underline transition-[color] duration-100 ease-in-out xl:max-w-full"
      to={`/${to}`}
    >
      <AttributeValueText value={value} />
    </Link>
  ) : (
    <AttributeValueText value={value} />
  )
}

export default AttributeValue
